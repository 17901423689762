<div fxLayout="column" fxLayoutAlign="flex-start center">
    <div fxLayout="column" fxLayoutAlign="flex-start center" class="error-main-content" [ngClass]="{'error-main-content-desk': !mobile, 'error-main-content-mobile': mobile }">

        <img class="error-img" src="../../assets/img/RYN-logo.svg" [ngClass]="{ 'mobile': mobile }" />

        <p class="semi-bold-15 text-center">{{ 'error_page_sorry' | translate }}</p>

        <p *ngIf="errorType === 'user'" class="regular-15 text-center">{{ 'error_page_user' | translate }}</p>
        <p *ngIf="errorType === 'tenant'" class="regular-15 text-center">{{ 'error_page_tenant' | translate }}</p>

        <p class="regular-12" [innerHTML]="'kyc_error_contact_us' | translate"></p>
    </div>
</div>