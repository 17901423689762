<div *ngIf="!isLoading" fxLayout="row" fxLayoutAlign="center flex-start">

    <div fxLayout="column" fxLayoutAlign="flex-start center" class="unit-main-content" [ngClass]="{'unit-main-content-desk': !mobile, 'unit-main-content-mobile': mobile }">

        <div *ngIf="!mobile" fxLayout="row" fxLayoutAlign="flex-start center" class="top-row">
            <img class="img-back" src="../../assets/img/rm_icon_arrow_left.svg" (click)="backClick()" />
            <p class="regular-20 no-margin">{{ propertyName }}, <span class="semi-bold-20">{{ unitName }}</span></p>
        </div>
        <div *ngIf="mobile" fxLayout="row" fxLayoutAlign="flex-start center" class="top-row-mobile">
            <p class="regular-15">{{ propertyName }}, <span class="semi-bold-15">{{ unitName }}</span></p>
        </div>
    
        <div *ngIf="!mobile && pictures.length > 0" class="pics-container" fxLayout="row" fxLayoutAlign="flex-start flex-start" (click)="showPicsClick()">
            <img [src]="pictures[0]" [fxFlex]="pictures.length > 1 ? '60%' : '100%'" class="left-pic" [ngStyle]="{'margin-right': pictures.length > 1 ? '10px' : '0px' }" />
            <div *ngIf="pictures.length > 1" fxFlex="40%" fxLayout="column" fxLayoutAlign="flex-start flex-start" fxLayoutGap="10px">
                <img [src]="pictures[1]" class="right-pic" [ngStyle]="{'height': pictures.length > 2 ? '147px' : '304px' }" />
                <img *ngIf="pictures.length > 2" [src]="pictures[2]" class="right-pic" style="height: 147px;" />
            </div>

            <div *ngIf="pictures.length > 3" class="pics-show-more">
                <img class="pic-show-more" src="../../assets/img/rm_icon_show_more.svg" />
                <p class="semi-bold-12 no-margin color-white">Show all photos</p>
            </div>
        </div>

        <div [ngClass]="{'fake-slider': !mobile}" *ngIf="picsSliderObj.length > 0" fxLayout="row" fxLayoutAlign="flex-start flex-start" style="width: 100%;">
            <ng-image-slider style="width: 100%;" [images]="picsSliderObj" [imageSize]="{'width': '100%', 'height': '200px', 'space': 0}" [animationSpeed]="0.35" #slideshow></ng-image-slider>
        </div>
        
        <div [ngClass]="{'full-width': !mobile}">
            <app-roommate-tabs (clickedOption)="onTabClicked($event)" [options]="tabs"></app-roommate-tabs>
            <div class="sep-row"></div>
        </div>


        <div *ngIf="currentPageIndex === 0" [ngClass]="{'page-inner-content-desk': !mobile, 'page-inner-content-mobile': mobile}">
            <div fxLayout="row wrap" fxLayoutAlign="center flex-start" style="width: 100%;">
                <app-dash-label-simple [fxFlex]="mobile ? '100%' : '35%'" [label]="'com_name' | translate" [isBold]="true">{{ unitName }}</app-dash-label-simple>
                <app-dash-label-simple [fxFlex]="mobile ? '100%' : '50%'" [ngClass]="{'v-spacing-30': mobile}" [label]="'com_address' | translate">{{ address }}</app-dash-label-simple>
                <app-dash-label-simple [fxFlex]="mobile ? '100%' : '15%'" [ngClass]="{'v-spacing-30': mobile}" [label]="'com_rent_value' | translate">{{ rentValue > 0 ? ((rentValue / 100) | currency: rentCurrency) : '-' }}</app-dash-label-simple>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="center flex-start" style="width: 100%; margin-bottom: 30px;" class="v-spacing-30">
                <app-dash-label-simple fxFlex="100%" [label]="'com_description' | translate" [multiline]="true">{{ unitDescription }}</app-dash-label-simple>
            </div>

            <app-tag-list *ngIf="unitTags.length > 0" [tags]="unitTags"></app-tag-list>

            <div class="v-spacing-30"></div>
        </div>

        <div *ngIf="currentPageIndex === 1" [ngClass]="{'page-inner-content-desk': !mobile, 'page-inner-content-mobile': mobile}">
            <div fxLayout="row wrap" fxLayoutAlign="flex-start flex-start" style="width: 100%;">
                <app-dash-label-simple [fxFlex]="mobile ? '100%' : '25%'" [label]="'com_name' | translate">{{ propertyName }}</app-dash-label-simple>
                <app-dash-label-simple [fxFlex]="mobile ? '100%' : '25%'" [ngClass]="{'v-spacing-30': mobile}" [label]="'com_address' | translate">{{ address }}</app-dash-label-simple>
                <app-dash-label-simple [fxFlex]="mobile ? '100%' : '25%'" [ngClass]="{'v-spacing-30': mobile}" [label]="'com_city' | translate">{{ city }}</app-dash-label-simple>
                <app-dash-label-simple [fxFlex]="mobile ? '100%' : '25%'" [ngClass]="{'v-spacing-30': mobile}" [label]="'com_country' | translate">{{ country }}</app-dash-label-simple>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="flex-start flex-start" style="width: 100%;" class="v-spacing-30">
                <app-dash-label-simple [fxFlex]="mobile ? '100%' : '25%'" [label]="'com_zip' | translate">{{ zip }}</app-dash-label-simple>
                <app-dash-label-simple [fxFlex]="mobile ? '100%' : '25%'" [ngClass]="{'v-spacing-30': mobile}" [label]="'com_unit' | translate">{{ propertyUnit }}</app-dash-label-simple>
                <app-dash-label-simple [fxFlex]="mobile ? '100%' : '25%'" [ngClass]="{'v-spacing-30': mobile}" [label]="'com_stairwell' | translate">{{ stairwell }}</app-dash-label-simple>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="center flex-start" style="width: 100%; margin-bottom: 30px;" class="v-spacing-30">
                <app-dash-label-simple fxFlex="100%" [label]="'com_description' | translate" [multiline]="true">{{ propertyDescription }}</app-dash-label-simple>
            </div>

            <app-tag-list *ngIf="propertyTags.length > 0" [tags]="propertyTags"></app-tag-list>

            <div class="v-spacing-30"></div>
        </div>
        <div *ngIf="currentPageIndex === 2" [ngClass]="{'page-inner-content-desk': !mobile, 'page-inner-content-mobile': mobile}">
            <app-file-picker [files]="files"></app-file-picker>

            <div *ngIf="files.length === 0" fxLayout="column" fxLayoutAlign="center center">
                <img src="../../assets/img/RYN-logo.svg" />
                <p class="regular-15 text-center" style="margin-top: 25px; width: 90%;" [innerHTML]="'emptyScreens.empty_unit_files' | translate:{tenantNameSurname: tenantNameSurname}">
                    
                </p>
            </div>
        </div>
    </div>
</div>