<div *ngIf="!isLoading" fxLayout="column" fxLayoutAlign="flex-start center">
    <div fxLayout="column" fxLayoutAlign="flex-start flex-start" class="payments-setup-content" [ngClass]="{'payments-setup-content-desk': !mobile, 'payments-setup-content-mobile': mobile }">

        <div *ngIf="!mobile" fxLayout="row" fxLayoutAlign="flex-start center" class="top-row">
            <img class="img-back" src="../../assets/img/rm_icon_arrow_left.svg" (click)="backClick()" />
            <p class="regular-20 no-margin" [innerHTML]="'screenTitles.payments_title' | translate">
            </p>
        </div>
        <div *ngIf="mobile" fxLayout="row" fxLayoutAlign="flex-start center" class="top-row-mobile">
            <p class="regular-15 no-margin" [innerHTML]="'screenTitles.payments_title_2' | translate">
            </p>
        </div>

        <!-- STEP 1: personal data -->

        <div fxLayout="row" fxLayoutAlign="space-between center" class="v-spacing-20" style="width: 100%;">
            <p class="semi-bold-15 no-margin">{{ 'payments_settings_personal_data_title' | translate }}</p>
            
            <img class="img-edit" src="../../assets/img/rm_icon_edit.svg" (click)="editPeronalDataClick()" />
        </div>

        <p class="regular-12 no-margin" [innerHTML]="'payments_settings_change_desc' | translate"></p>

        <div class="sep-row-light"></div>

        <div [formGroup]="basicInfoForm" fxLayout="column" style="width: 100%">
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayotGap="20px" style="width: 100%">
                <div [fxFlex]="mobile ? '100%' : '32%'" class="field-margin">
                    <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                        <mat-label>{{ 'com_name' | translate }}</mat-label>
                        <input
                            type="text"
                            matInput
                            [placeholder]="'com_name' | translate"
                            formControlName="name"
                            autocomplete="off"
                        />
                    </mat-form-field>
                </div>
                <div [fxFlex]="mobile ? '100%' : '32%'" class="field-margin">
                    <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                        <mat-label>{{ 'com_surname' | translate }}</mat-label>
                        <input
                            type="text"
                            matInput
                            [placeholder]="'com_surname' | translate"
                            placeholder="Surname"
                            formControlName="surname"
                            autocomplete="off"
                        />
                    </mat-form-field>
                </div>
                <div [fxFlex]="mobile ? '100%' : '32%'" class="field-margin">
                    <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                        <mat-label>{{ 'com_date_of_birth' | translate }}</mat-label>
                        <input
                            matInput
                            [matDatepicker]="birthDate"
                            formControlName="dateOfBirth"
                            autocomplete="off"
                            [max]="today"
                            [placeholder]="'com_date_of_birth' | translate"
                            placeholder="Date of birth"
                            readonly
                            (click)="birthDate.open()"
                        />
                        <mat-datepicker #birthDate></mat-datepicker>
                        <img
                            class="roommate-datepicker-icon"
                            src="./assets/img/ic_datepicker.png"
                            (click)="birthDate.open()"
                        />
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" style="width: 100%">
                <div [fxFlex]="mobile ? '100%' : '32%'" class="field-margin">
                    <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                        <mat-label>{{ 'com_email' | translate }}</mat-label>
                        <input
                            type="text"
                            matInput
                            [placeholder]="'com_email' | translate"
                            placeholder="Email"
                            formControlName="email"
                            autocomplete="off"
                        />
                    </mat-form-field>
                </div>
                <div [fxFlex]="mobile ? '100%' : '32%'" style="position: relative" class="field-margin">
                    <mat-select-country
                        style="margin-bottom: 20px; width: 100%;"
                        appearance="outline"
                        [itemsLoadSize]="20"
                        [label]="'com_nationality' | translate"
                        formControlName="nationality"
                    >
                    </mat-select-country>
                </div>
                <div [fxFlex]="mobile ? '100%' : '32%'" style="position: relative" class="field-margin">
                    <mat-select-country
                        style="margin-bottom: 20px; width: 100%;"
                        appearance="outline"
                        [itemsLoadSize]="20"
                        [label]="'com_country_of_residence' | translate"
                        formControlName="countryOfResidence"
                    >
                    </mat-select-country>
                </div>
            </div>
        </div>

        <!-- STEP 2: bank account -->

        <div fxLayout="row" fxLayoutAlign="space-between center" class="v-spacing-20" style="width: 100%;">
            <p class="semi-bold-15 no-margin">{{ 'payments_settings_bank_account_title' | translate }}</p>
            
            <img class="img-edit" src="../../assets/img/rm_icon_edit.svg" (click)="editBankAccountClick()" />
        </div>

        <p class="regular-12 no-margin" [innerHTML]="'payments_settings_change_desc' | translate"></p>

        <div class="sep-row-light"></div>

        <div [formGroup]="bankInfoForm" fxLayout="column" style="width: 100%">
            <div fxLayout="row wrap" fxLayoutAlign="center center" [fxLayoutGap]="mobile ? '0px' : '16px'" style="width: 100%">
                <div [fxFlex]="mobile ? '100%' : '49%'" class="field-margin">
                    <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                        <mat-label>{{ 'com_ordering_name' | translate }}</mat-label>
                        <input
                            type="text"
                            matInput
                            [placeholder]="'com_ordering_name' | translate"
                            placeholder="Ordering name"
                            formControlName="beneficiary"
                            autocomplete="off"
                        />
                    </mat-form-field>
                </div>
                <div [fxFlex]="mobile ? '100%' : '49%'" class="field-margin">
                    <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                        <mat-label>{{ 'com_up_iban' | translate }}</mat-label>
                        <input
                            type="text"
                            matInput
                            [placeholder]="'com_up_iban' | translate"
                            placeholder="IBAN"
                            formControlName="iban"
                            autocomplete="off"
                        />
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="center center" style="width: 100%">
                <div style="margin-top: 3px; width: 100%;">
                    <app-address-form
                        style="max-width: inherit"
                        #address_selector
                        [required]="true"
                        [inputAddressData]="invoiceData"
                        [disabled]="!bankDataEnabled"
                        (addressDataChange)="onAddressUpdated($event)"
                    ></app-address-form>
                </div>
            </div>
        </div>

        <!-- CTA -->

        <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%; padding-bottom: 40px;" [ngStyle]="{'margin-top': mobile ? '20px' : '40px'}">
            <button
                [fxFlex]="mobile ? '120px' : '150px'"
                mat-button
                [ngClass]="{ 'button-positive': (somethingChanged1 || somethingChanged2 || somethingChanged3), 'button-neutral': (!somethingChanged1 && !somethingChanged2 && !somethingChanged3), 'button-small': mobile }"
                (click)="saveClick()">
                {{ 'com_save' | translate }}
            </button>
        </div>

        <!-- STEP 3: payment Methods -->

        <div fxLayout="row" fxLayoutAlign="space-between center" class="v-spacing-20" style="width: 100%;">
            <p class="semi-bold-15 no-margin">{{ 'payment_Methods' | translate }}</p>
        </div>

        <p class="regular-12 no-margin">{{ 'payment_Methods_desc' | translate }}</p>

        <div *ngIf="creditCards.length > 0" fxLayout="row" fxLayoutAlign="space-between center" class="v-spacing-20" style="width: 100%;">
            <div fxLayout="column" fxLayoutAlign="flex-start flex-start" fxLayoutGap="20px" style="width: 100%; margin-bottom: 20px;">
                <p class="semi-bold-15 no-margin" >{{ 'cardNumber' | translate }}</p>
                <p class="regular-12 no-margin">{{ creditCards[0].last4Digits }}</p>
            </div>
            <button mat-button class="button-red" style="background-color: #fa3c4c; color: white !important" (click)="deletePaymentMethod(creditCards[0].cardId)">{{ 'com_remove' | translate }}</button>
        </div>

        <div *ngIf="bankInfoForm.value.iban" fxLayout="row" fxLayoutAlign="space-between center" class="v-spacing-20" style="width: 100%; padding-bottom: 50px;">
            <div fxLayout="column" fxLayoutAlign="flex-start flex-start" fxLayoutGap="20px" style="width: 100%; margin-bottom: 20px;">
                <p class="semi-bold-15 no-margin" >{{ 'com_up_iban' | translate }}</p>
                <p class="regular-12 no-margin">{{ bankInfoForm.value.iban }}</p>
            </div>
        </div>

        <p *ngIf="creditCards.length === 0 && !bankInfoForm.value.iban" class="regular-12 no-margin">{{ 'add_Payment_Methods' | translate }}</p>

        <div *ngIf="recurringPayments.length > 0" fxLayout="column" class="v-spacing-20" style="width: 100%;">

            <div fxLayout="row" fxLayoutAlign="space-between center" class="v-spacing-20" style="width: 100%;">
                <p class="semi-bold-15 no-margin">{{ 'recurring_Payments' | translate }}</p>
            </div>
    
            <p class="regular-12 no-margin">{{ 'recurring_Payments_description' | translate }}</p>

            <div *ngFor="let recurring of recurringPayments" fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 10px; margin-bottom: 50px;"> 
                <div fxLayout="column" fxLayoutAlign="flex-start flex-start" fxLayoutGap="20px" style="width: 100%; margin-bottom: 20px;">
                    <p class="regular-12 no-margin" [innerHtml]="'active_Recurring_Payment' | translate" ></p>
                </div>
                <button mat-button class="button-red" style="background-color: #fa3c4c; color: white !important" (click)="cancelRecurringPayment(recurring.recurringPayment.id)">{{ 'com_cancel' | translate }}</button>
            </div>
        </div>
    </div>
</div>
