import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PropertyPayment } from 'src/app/models/payments';
import { AppUtils } from 'src/app/utils/app-utils';


@Component({
  selector: 'app-mobile-payment-card',
  templateUrl: './mobile-payment-card.component.html',
  styleUrls: ['./mobile-payment-card.component.scss']
})
export class MobilePaymentCardComponent {

    appUtils: AppUtils;

	@Input() payment : PropertyPayment | undefined;
    @Input() ctaIsViewAndNotPay : boolean = false;

	@Output() viewPayment = new EventEmitter<PropertyPayment>();

	constructor(
        private readonly translateService: TranslateService
    ) {
        this.appUtils = new AppUtils(translateService);
    }

	getStatus () {
		return !!this.payment ? PropertyPayment.getStatusFromPayment(this.payment) : "";
	}

	viewPaymentClick () {
		if (!!this.payment) {
			this.viewPayment.emit(this.payment);
		}
	}

    getPaymentTitle () : string {
        if (!!this.payment) {
            return this.appUtils.getPaymentTitle(this.payment);
        }

        return "";
    }
}
