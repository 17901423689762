import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { InvoiceData, UserData } from '../models/common';
import { BackendService } from '../services/backend.service';
import { take } from 'rxjs';
import { LoadingService } from '../services/loading.service';
import { SnotifyService } from 'ng-snotify';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from '../utils/app-constants';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-invoice-data-add-dialog',
  templateUrl: './invoice-data-add-dialog.component.html',
  styleUrls: ['./invoice-data-add-dialog.component.scss']
})
export class InvoiceDataAddDialogComponent implements OnInit {

	gottenInvoiceData: InvoiceData = {};
	pendingInvoiceData: InvoiceData = {};

	userData : UserData | undefined;

	dataChanged : boolean = false;

	constructor(
		private readonly backendService: BackendService,
		private readonly loadingService: LoadingService,
		private readonly toastService: SnotifyService,
		private readonly translateService: TranslateService,
		private readonly gaService: GoogleAnalyticsService,
		public dialogRef: MatDialogRef<InvoiceDataAddDialogComponent>
	) { }

	ngOnInit() {

		this.backendService.loadBasicUserData()
			.pipe(take(1))
			.subscribe(userData => {
				
				this.gottenInvoiceData = userData.invoiceData || {};
				this.pendingInvoiceData = userData.invoiceData || {};
				this.userData = userData;
			})
	}

	invoiceDataChanged(data : InvoiceData) {
		this.pendingInvoiceData = data;
		this.dataChanged = !this.shallowEqual(this.gottenInvoiceData, this.pendingInvoiceData);
	}

	cancelClick() {
		this.dialogRef.close();
	}

	async saveClick() {

		if (this.userData) {

            this.dataChanged = !this.shallowEqual(this.gottenInvoiceData, this.pendingInvoiceData);

            if (this.dataChanged) {
				
                this.loadingService.show(this.translateService.instant('loadings.loading_saving'));

				this.userData.invoiceData = this.pendingInvoiceData;

                const userData = await this.backendService.editBasicUserData(this.userData);

                this.gaService.gtag('event', 'invoice_data_changed', {
                    'event_label': 'Invoice data changed',
                    'event_category': 'popup'
                });

                this.gottenInvoiceData = this.pendingInvoiceData;

				this.loadingService.hide();

				this.toastService.success(
					this.translateService.instant('toasts.toast_success_billing_info_update_desc'),
					this.translateService.instant('toasts.toast_success_billing_info_update_title'),
					AppConstants.TOAST_STD_CONFIG
				);

				this.dialogRef.close({ "userData": userData });
            }
        }
	}

	private shallowEqual(object1 : any, object2 : any) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (let key of keys1) {
            if (object1[key] !== object2[key]) {
                return false;
            }
        }
        return true;
    }
}
