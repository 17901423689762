import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LoadingService {
	private _loadingTextSubject = new BehaviorSubject<string>('');

	constructor(private spinner: NgxSpinnerService) {}

	show(text: string = '') {
		this._loadingTextSubject.next(text);
		this.spinner.show('mainSpinner');
	}

	hide() {
		this.spinner.hide('mainSpinner');
	}

	getLoadingTextObservable() {
		return this._loadingTextSubject;
	}
}