<input
    type="file"
    accept=".jpg,.jpeg,.png"
    multiple
    (change)="picturesSelected($event)"
    #file
    style="display: none"
/>

<div *ngIf="!isLoading" fxLayout="row" fxLayoutAlign="center flex-start">

    <div fxLayout="column" fxLayoutAlign="flex-start center" class="settings-main-content" [ngClass]="{'settings-main-content-desk': !mobile, 'settings-main-content-mobile': mobile }">

        <img *ngIf="!mobile" class="img-back" src="../../assets/img/rm_icon_arrow_left.svg" (click)="backClick()" />
    
        <img *ngIf="tenantPhoto !== ''" [ngClass]="{'home-top-img-desk': !mobile, 'home-top-img-mobile': mobile }" (mouseenter) ="imgProfileHover = true"  (mouseleave) ="imgProfileHover = false" [src]="imgProfileHover ? '../../assets/img/choose_profile_pic.svg' : tenantPhoto" (click)="choosePicClick()" />
        <img *ngIf="tenantPhoto === ''" [ngClass]="{'home-top-img-desk': !mobile, 'home-top-img-mobile': mobile }" (mouseenter) ="imgProfileHover = true"  (mouseleave) ="imgProfileHover = false" [src]="imgProfileHover ? '../../assets/img/choose_profile_pic.svg' : '../../assets/img/landlord_logo_empty.svg'" (click)="choosePicClick()" />
    
        <p class="home-tenant-name-desk regular-20" *ngIf="!mobile" [innerHTML]="'com_hi_x' | translate:{tenantNameSurname: tenantNameSurname}">
        </p>
        <p class="home-tenant-name-mobile regular-15" *ngIf="mobile" [innerHTML]="'com_hi_x' | translate:{tenantNameSurname: tenantNameSurname}">
        </p>

        <div>
            <app-roommate-tabs (clickedOption)="onTabClicked($event)" [options]="tabs"></app-roommate-tabs>
            <div class="sep-row"></div>
        </div>

        <div fxFlex class="settings-center-card">
            <div *ngIf="currentPageIndex === 0">
                <div fxLayout="row wrap" fxLayoutAlign="flex-start center" style="width: 100%" class="info-row">
                    <div [fxFlex]="mobile ? '100%' : '50%'">
                        <p class="semi-bold-12 no-margin">{{ 'com_up_full_name' | translate }}</p>
                    </div>
                    <div [fxFlex]="mobile ? '100%' : '50%'">
                        <p class="regular-15 no-margin" [ngClass]="{'v-spacing': mobile}">{{ tenantNameSurname }}</p>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="flex-start center" style="width: 100%" class="info-row">
                    <div [fxFlex]="mobile ? '100%' : '50%'">
                        <p class="semi-bold-12 no-margin">{{ 'com_up_phone' | translate }}</p>
                    </div>
                    <div [fxFlex]="mobile ? '100%' : '50%'">
                        <p class="regular-15 no-margin" [ngClass]="{'v-spacing': mobile}">{{ tenantPhone }}</p>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="flex-start center" style="width: 100%" class="info-row">
                    <div [fxFlex]="mobile ? '100%' : '50%'">
                        <p class="semi-bold-12 no-margin">{{ 'com_up_email' | translate }}</p>
                    </div>
                    <div [fxFlex]="mobile ? '100%' : '50%'">
                        <p class="regular-15 no-margin" [ngClass]="{'v-spacing': mobile}">{{ tenantEmail }}</p>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="flex-start center" style="width: 100%" class="info-row">
                    <div [fxFlex]="mobile ? '100%' : '50%'">
                        <p class="semi-bold-12 no-margin">{{ 'com_up_your_status' | translate }}</p>
                    </div>
                    <div  [ngClass]="{'v-spacing': mobile}">
                        <div *ngIf="tenantStatus === 0" class="rm-tag-view tag-primary">
                            <p class="semi-bold-15 color-white">{{ 'com_pending' | translate }}</p>
                        </div>
                        <div *ngIf="tenantStatus === 1" class="rm-tag-view tag-green">
                            <p class="semi-bold-15 color-white">{{ 'com_registred' | translate }}</p>
                        </div>
                    </div>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="flex-start center" style="width: 100%" class="info-row">
                    <div [fxFlex]="mobile ? '100%' : '50%'">
                        <p class="semi-bold-12 no-margin">{{ 'com_up_language' | translate }}</p>
                    </div>
                    <div  [ngClass]="{'v-spacing': mobile}">
                        <mat-form-field class="field-full-width" appearance="outline" hideRequiredMarker>
                            <mat-select [formControl]="language" (selectionChange)="languageChanged($event)">
                                <mat-option *ngFor="let lang of availableLanguages" [value]="lang.code">
                                    {{ lang.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="advice-billing-settings">
                    <span>
                        {{ 'advice_billing_settings' | translate }}
                        <a style="cursor: pointer; color:#0081ff;" (click)="goToPaymentSettings()">{{ 'com_here' | translate }}.</a>
                    </span>
                </div>
            </div>
            <div *ngIf="currentPageIndex === 1">

                <div fxLayout="row wrap" fxLayoutAlign="flex-start center" style="width: 100%" class="info-row-pswd">
                    <div [fxFlex]="mobile ? '100%' : '40%'">
                        <p class="semi-bold-12 no-margin">{{ 'com_current_password' | translate }}</p>
                    </div>
                    <div [fxFlex]="mobile ? '100%' : '60%'">
                        <mat-form-field appearance="outline" class="field-full-width no-padding-bottom" hideRequiredMarker>
                            <mat-label>{{ 'com_current_password' | translate }}</mat-label>
                            <input
                                type="password"
                                matInput
                                [placeholder]="'com_type_here' | translate"
                                [formControl]="oldPassword"
                            />
                        </mat-form-field>
                    </div>
                </div>

                <div fxLayout="row wrap" fxLayoutAlign="flex-start center" style="width: 100%" class="info-row-pswd">
                    <div [fxFlex]="mobile ? '100%' : '40%'">
                        <p class="semi-bold-12 no-margin">{{ 'com_new_password' | translate }}</p>
                    </div>
                    <div [fxFlex]="mobile ? '100%' : '60%'">
                        <mat-form-field appearance="outline" class="field-full-width no-padding-bottom" hideRequiredMarker>
                            <mat-label>{{ 'com_new_password' | translate }}</mat-label>
                            <input
                                [type]="hidePassword ? 'password' : 'text'"
                                matInput
                                [placeholder]="'com_type_here' | translate"
                                [formControl]="newPassword"
                            />
                            <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </mat-form-field>
                    </div>
                </div>

                <div fxLayout="row wrap" fxLayoutAlign="flex-start center" style="width: 100%" class="info-row-pswd">
                    <div [fxFlex]="mobile ? '100%' : '40%'">
                        <p class="semi-bold-12 no-margin">{{ 'com_confirm_password' | translate }}</p>
                    </div>
                    <div [fxFlex]="mobile ? '100%' : '60%'">
                        <mat-form-field appearance="outline" class="field-full-width no-padding-bottom" hideRequiredMarker>
                            <mat-label>{{ 'com_confirm_password' | translate }}</mat-label>
                            <input
                                type="password"
                                matInput
                                [placeholder]="'com_type_here' | translate"
                                [formControl]="newPasswordConfirm"
                            />
                        </mat-form-field>
                    </div>
                </div>

                <div fxLayout="row wrap" fxLayoutAlign="flex-start center" style="width: 100%" class="info-row">
                    <div [fxFlex]="mobile ? '100%' : '40%'">
                    </div>
                    <div [fxFlex]="mobile ? '100%' : '60%'">
                        <p class="regular-15 no-margin color-grey" style="text-align: right; line-height: 22px !important;">
                            {{ 'change_password_info' | translate }}
                        </p>
                    </div>
                </div>

                <div fxLayout="row wrap" fxLayoutAlign="flex-end center" style="width: 100%" class="info-row-pswd">
                    <button
						fxFlex="100px"
						mat-button
						class="button-positive button-small"
						(click)="changePassword()"
					>{{ 'com_save' | translate }}</button>
                </div>

                <div
                    *ngIf="hasErrorMessage"
                    fxLayout="row"
                    fxLayoutAlign="center flex-start"
                    style="margin-top: 20px; margin-bottom: 20px; height: 40px"
                >
                    <p class="regular-15 no-margin color-red text-center">
                        {{ 'change_password_error_1' | translate }}
                    </p>
                </div>
                <div
                    *ngIf="hasErrorMessage2"
                    fxLayout="row"
                    fxLayoutAlign="center flex-start"
                    style="margin-top: 20px; margin-bottom: 20px; height: 40px"
                >
                    <p class="regular-15 no-margin color-red text-center">
                        {{ 'change_password_error_2' | translate }}
                    </p>
                </div>
                
                
            </div>
            <!-- <div *ngIf="currentPageIndex === 3">
                
                <p class="semi-bold-12 no-margin">{{ 'com_invoicing_info' | translate }}</p>

        
                <app-invoice-form
                    [invoiceData]="gottenInvoiceData"
                    (invoiceDataChange)="invoiceDataChanged($event)"
                ></app-invoice-form>

                <p class="semi-bold-12" style="margin: 20px 0;">{{ 'com_bank_details' | translate }}</p>

                <app-bank-form
                    [bankData]="gottenBankData"
                    (bankDataChange)="bankDataChanged($event)"
                    style="width: 100%;"
                ></app-bank-form>

                <div fxLayout="row wrap" fxLayoutAlign="flex-end center" style="width: 100%; margin-top: 30px;" class="info-row-pswd">
                    <button
						fxFlex="100px"
						mat-button
						class="button-positive button-small"
						(click)="saveBillingData()"
					>{{ 'com_save' | translate }}</button>
                </div>
            </div> -->
            <div *ngIf="currentPageIndex === 2">
                <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%" class="info-row">
                    <div [fxFlex]="mobile ? '80%' : '50%'" fxLayout="row" fxLayoutAlign="space-between center">
                        <p class="semi-bold-12 no-margin">{{ 'settings_data_privacy_policy' | translate }}</p>
                        <a class="settings-data-link clickable" (click)="privacyClick()">Link 01</a>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%" class="info-row">
                    <div [fxFlex]="mobile ? '80%' : '50%'" fxLayout="row" fxLayoutAlign="space-between center">
                        <p class="semi-bold-12 no-margin">{{ 'settings_data_terms_of_use' | translate }}</p>
                        <a class="settings-data-link clickable" (click)="termsClick()">Link 02</a>
                    </div>
                </div>
                
                <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%" class="info-row">
                    <div [fxFlex]="mobile ? '80%' : '50%'" fxLayout="row" fxLayoutAlign="space-between center">
                        <p class="semi-bold-12 no-margin">{{ 'settings_data_request_personal_data' | translate }}</p>
                        <a class="settings-data-link clickable"  (click)="personalDataClick()">Link 03</a>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%" class="info-row">
                    <div [fxFlex]="mobile ? '80%' : '50%'" fxLayout="row" fxLayoutAlign="space-between center">
                        <p class="semi-bold-12 no-margin">{{ 'settings_data_request_delete_account' | translate }}</p>
                        <a class="settings-data-link clickable"  (click)="deleteAccountClick()">Link 04</a>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%; position: absolute; bottom: 0px; width: 100%; text-align: center;" class="info-row color-grey">
                    {{placesVersion}}
                </div>
            </div>
        </div>
    </div>
</div>
