import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { BankData } from '../../models/common';

@Component({
	selector: 'app-bank-form',
	templateUrl: './bank-form.component.html',
	styleUrls: ['./bank-form.component.scss']
})
export class BankFormComponent implements OnInit, OnChanges {
	defaultBankData: BankData = {};

	public mainInfoForm = this.formBuilder.group({
		bankName: new FormControl('', []),
		iban: new FormControl('', [Validators.pattern('^[a-zA-Z]{2}\\d{2}\\s*(\\w{4}\\s*){2,7}\\w{1,4}\\s*$')]),
		bic: new FormControl('', []),
		beneficiary: new FormControl('', [])
	});

	@Input() bankData: BankData = {};

	@Output() bankDataChange = new EventEmitter<BankData>();

	constructor(private readonly formBuilder: FormBuilder) {}

	currentBankData: BankData = { ...this.defaultBankData };

	ngOnChanges(changes: SimpleChanges) {
		if (changes['bankData']) {
			if (this.bankData) {
				this.currentBankData = this.bankData;

				this.mainInfoForm.setValue({
					bankName: this.bankData.bankName || '',
					iban: this.bankData.iban || '',
					bic: this.bankData.bic || '',
					beneficiary: this.bankData.beneficiary || ''
				});

				// This trigger valueChanges and set data in parent component | Without this data won't be synced if I'm editing other fields
				setTimeout(() => {
					this.mainInfoForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
				}, 0);
			}
		}
	}

	ngOnInit() {
		this.mainInfoForm.valueChanges.subscribe(value => {
			this.currentBankData = { ...this.currentBankData, ...value };
			if (!this.mainInfoForm.pristine && this.mainInfoForm.valid) {
				this.bankDataChange.next(this.currentBankData);
			}
		});
	}
}