<div mat-dialog-content>
	<input type="text" style="display: none" cdkFocusInitial />

	<div fxLayout="column" fxLayoutAlign="flex-start center" class="popup-top-section">
		<div class="v-spacing-20"></div>

		<p *ngIf="id === 0" class="semi-bold-15 color-white no-margin" [innerHTML]="'delete_confirm_maintenance_title' | translate">
		</p>

		<img
			src="../../assets/img/close.svg"
			class="clickable"
			(click)="cancelClick()"
			style="width: 15px; height: 15px"
		/>
	</div>

	<div fxLayout="column" fxLayoutAlign="center center" fxLayoutWrap>
		<img src="./assets/img/delete_popup_image.png" class="v-spacing-20" style="width: 240px" />

		<p *ngIf="id === 0" class="regular-15 text-center">
			{{ 'delete_confirm_maintenance_desc' | translate }}
		</p>

		<div
			class="popup-bottom-section"
			fxLayout="row"
			fxLayoutAlign="space-between center"
			fxLayoutWrap
			style="width: 100%"
		>
			<button mat-button class="button-neutral button-small" (click)="cancelClick()">{{ 'com_cancel' | translate }}</button>

			<button
				(click)="proceedClick()"
				mat-button
				class="button-negative button-small"
			>{{ 'com_delete' | translate }}</button>
		</div>
	</div>
</div>
