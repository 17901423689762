import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BrowserInfo, InvoiceData } from "../models/common";
import { PaymentProviderRecurringPaymentStatus, PropertyPayment } from "../models/payments";
import { MaintenanceRequest } from "../models/properties";
@Injectable()
export class AppUtils {

    constructor(
        private readonly translateService: TranslateService
    ) {

    }

	static deepCopy(obj : any, onlyTopFields?: string[]) : any {
		let copy : any;

		// Handle the 3 simple types, and null or undefined
		if (null === obj || 'object' !== typeof obj) return obj;

		// Handle Date
		if (obj instanceof Date) {
			copy = new Date();
			copy.setTime(obj.getTime());
			return copy;
		}

		// Handle Array
		if (obj instanceof Array) {
			copy = [];
			for (let i = 0, len = obj.length; i < len; i++) {
				copy[i] = this.deepCopy(obj[i]);
			}
			return copy;
		}

		// Handle Object
		if (obj instanceof Object) {
			copy = {};
			for (const attr in obj) {
				if (obj.hasOwnProperty(attr) && (!onlyTopFields || onlyTopFields.includes(attr)))
					copy[attr] = this.deepCopy(obj[attr]);
			}
			return copy;
		}

		throw new Error("Unable to copy obj! Its type isn't supported.");
	}

    static amountKeyPressValidate(event: any) {
		const pattern = /[0-9]/;

		const inputChar = String.fromCharCode(event.charCode);

		if (event.keyCode !== 8 && !pattern.test(inputChar)) {
			event.preventDefault();
			const totPatter = /^\d+(\.)?(\d+)?$/;
			let toSetString = event.target.value + inputChar;
			if (inputChar === ',') {
				toSetString = event.target.value + '.';
			}
			if (totPatter.test(toSetString)) {
				event.target.value = toSetString;
			}
		}
	}

	public getBrowserInfo(): BrowserInfo {

		const browserInfo: BrowserInfo = {
			JavaEnabled: navigator.javaEnabled(),
            Language: navigator.language,
            ColorDepth: window.screen.colorDepth,
            ScreenHeight: window.screen.height,
            ScreenWidth: window.screen.width,
            TimeZoneOffset: new Date().getTimezoneOffset(),
            UserAgent: navigator.userAgent,
            JavascriptEnabled: true
		}

        return browserInfo
    }


    public maintenanceCategories: { [key: string]: string } = {
        'electrical': this.translateService.instant('maintenanceCategories.ctr_maintenances_cat_1'),
		'plumbing': this.translateService.instant('maintenanceCategories.ctr_maintenances_cat_2'),
		'furniture': this.translateService.instant('maintenanceCategories.ctr_maintenances_cat_3'),
		'windows': this.translateService.instant('maintenanceCategories.ctr_maintenances_cat_4'),
		'boiler': this.translateService.instant('maintenanceCategories.ctr_maintenances_cat_5'),
		'heating': this.translateService.instant('maintenanceCategories.ctr_maintenances_cat_6'),
		'structural_renovation': this.translateService.instant('maintenanceCategories.ctr_maintenances_cat_8'),
		'small_renovation': this.translateService.instant('maintenanceCategories.ctr_maintenances_cat_9'),
		'cleaning': this.translateService.instant('maintenanceCategories.ctr_maintenances_cat_10'),
		'other': this.translateService.instant('maintenanceCategories.ctr_maintenances_cat_7')
	};

    public getMaintenanceCategoryName (m : MaintenanceRequest) {
        return this.maintenanceCategories[m.category] || this.maintenanceCategories['other'];   
    }


    public paymentCategories: { [key: string]: string } = {
        'stamp_duty': this.translateService.instant('paymentCategories.ctr_pay_cat_stamp_duty'),
		'stamp': this.translateService.instant('paymentCategories.ctr_pay_cat_stamp'),
		'electrical': this.translateService.instant('paymentCategories.ctr_pay_cat_electrical'),
		'garbage':  this.translateService.instant('paymentCategories.ctr_pay_cat_garbage'),
		'gas': this.translateService.instant('paymentCategories.ctr_pay_cat_gas'),
		'condo': this.translateService.instant('paymentCategories.ctr_pay_cat_condo'),
		'internet': this.translateService.instant('paymentCategories.ctr_pay_cat_internet'),
		'furnitures': this.translateService.instant('paymentCategories.ctr_pay_cat_furnitures'),
		'laundry': this.translateService.instant('paymentCategories.ctr_pay_cat_laundry'),
		'water': this.translateService.instant('paymentCategories.ctr_pay_cat_water'),
		'cleaning': this.translateService.instant('paymentCategories.ctr_pay_cat_cleaning'),
		'maintenance': this.translateService.instant('paymentCategories.ctr_pay_cat_maintenance'),
		'general': this.translateService.instant('paymentCategories.ctr_pay_cat_general'),
		'deposit_in': this.translateService.instant('paymentCategories.ctr_pay_cat_deposit_in'),
		'deposit_out': this.translateService.instant('paymentCategories.ctr_pay_cat_deposit_out'),
		'rent': this.translateService.instant('paymentCategories.ctr_pay_cat_rent'),
		'flat_fees': this.translateService.instant('paymentCategories.ctr_pay_cat_flat_fees'),
		'adjustment': this.translateService.instant('paymentCategories.ctr_pay_cat_adjustment'),
		'late_fees': this.translateService.instant('paymentCategories.ctr_pay_cat_late_fees'),
		'acquisition': this.translateService.instant('paymentCategories.ctr_pay_cat_acquisition'),
		'appliances': this.translateService.instant('paymentCategories.ctr_pay_cat_appliances'),
		'entry_fee': this.translateService.instant('paymentCategories.ctr_pay_cat_entry_fee'),
		'renovation': this.translateService.instant('paymentCategories.ctr_pay_cat_renovation'),
		'management_fee': this.translateService.instant('paymentCategories.ctr_pay_cat_management_fee'),
		'eviction': this.translateService.instant('paymentCategories.ctr_pay_cat_eviction'),
		'legal': this.translateService.instant('paymentCategories.ctr_pay_cat_legal'),
		'insurance': this.translateService.instant('paymentCategories.ctr_pay_cat_insurance'),
		'marketing': this.translateService.instant('paymentCategories.ctr_pay_cat_marketing'),
		'interests': this.translateService.instant('paymentCategories.ctr_pay_cat_interests'),
		'activities': this.translateService.instant('paymentCategories.ctr_pay_cat_activities'),
		'loan': this.translateService.instant('paymentCategories.ctr_pay_cat_loan'),
		'taxes': this.translateService.instant('paymentCategories.ctr_pay_cat_taxes'),
		'exit_fee': this.translateService.instant('paymentCategories.ctr_pay_cat_exit_fee'),
		'booking_fee': this.translateService.instant('paymentCategories.ctr_pay_cat_booking_fee'),
		'administration_fee': this.translateService.instant('paymentCategories.ctr_pay_cat_administration_fee')
	};

    public getPaymentTitle (p : PropertyPayment) {
        const title = p.title !== '' ? p.title : (this.paymentCategories[p.category] || this.paymentCategories['general']);

		if(p.recurringPaymentStatus === PaymentProviderRecurringPaymentStatus.ACTIVE && p.status === "open"){
			return `${title} (${this.translateService.instant('paymentCategories.ctr_pay_recurring_payment_active')})`;
		}

		if(p.recurringPaymentStatus === PaymentProviderRecurringPaymentStatus.AUTH_NEEDED && p.status === "open"){
			return `${title} (${this.translateService.instant('paymentCategories.ctr_pay_recurring_payment_auth_needed')})`;
		}

		return title
    }


    public isInvoiceDataComplete(invoiceData: InvoiceData): boolean {

		if (invoiceData) {
			if (invoiceData.type === 'legal') {
				if (invoiceData.countryCode === 'IT') {
					return !!(
						invoiceData.address &&
						invoiceData.taxCode &&
						invoiceData.type &&
						invoiceData.city &&
						invoiceData.countryCode &&
						invoiceData.postalCode &&
						invoiceData.region &&
						invoiceData.businessName
					);
				} else {
					return !!(
						invoiceData.address &&
						invoiceData.taxCode &&
						invoiceData.type &&
						invoiceData.city &&
						invoiceData.countryCode &&
						invoiceData.businessName &&
						invoiceData.postalCode
					);
				}
			} else {
				if (invoiceData.countryCode === 'IT') {
					return !!(
						invoiceData.address &&
						invoiceData.taxCode &&
						invoiceData.city &&
						invoiceData.countryCode &&
						invoiceData.postalCode &&
						invoiceData.region
					);
				} else {
					return !!(
						invoiceData.address &&
						invoiceData.city &&
						invoiceData.countryCode &&
						invoiceData.postalCode &&
						invoiceData.taxCode
					);
				}
			}
		} else {
			return false;
		}
	}


	public isAddressComplete(invoiceData: InvoiceData): boolean {
		return !!(
			invoiceData.address &&
			invoiceData.city &&
			invoiceData.countryCode &&
			invoiceData.postalCode
		);
	}
}

