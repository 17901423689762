<div class="operation-card">
    <div fxLayout="row" fxLayoutAlign="flex-start center" style="width: 100%;">
        <div fxFlex="65%" fxLayout="column" fxLayoutAlign="flex-start flex-start">
            <p class="semi-bold-10 operation-card-p-margin">{{ 'com_up_date' | translate }}</p>
            <p class="regular-12">{{ operation?.createdDate | dfnsFormat: 'd MMM yyyy' }}</p>
        </div>
        <div fxFlex="35%" fxLayout="column" fxLayoutAlign="flex-start flex-start">
            <p class="semi-bold-10 operation-card-p-margin">{{ 'com_up_amount' | translate }}</p>
            <p class="regular-12">{{ (operation?.amount || 0) / 100 | currency: operation?.currency }}</p>
        </div>
    </div>
    
    <div fxLayout="row" fxLayoutAlign="flex-start center" style="width: 100%; margin-top: 15px;">
        <div fxFlex="65%" fxLayout="column" fxLayoutAlign="flex-start flex-start">
            <p class="semi-bold-10 operation-card-p-margin">{{ 'com_up_status' | translate }}</p>
            <div *ngIf="operation?.status === 'started'" class="rm-tag-view tag-primary">
                <p class="semi-bold-12 color-white">{{ 'pay_status_started' | translate }}</p>
            </div>
            <div *ngIf="operation?.status === 'pending'" class="rm-tag-view tag-primary">
                <p class="semi-bold-12 color-white">{{ 'pay_status_pending' | translate }}</p> 
            </div>
            <div *ngIf="operation?.status === 'failure' || operation?.status === 'late_failure'" class="rm-tag-view tag-red">
                <p class="semi-bold-12 color-white">{{ 'pay_status_failed' | translate }}</p>
            </div>
            <div *ngIf="operation?.status === 'succeeded'"  class="rm-tag-view tag-green">
                <p class="semi-bold-12 color-white">{{ 'pay_status_paid' | translate }}</p>
            </div>
        </div>
        <div fxFlex="35%" *ngIf="operation?.metadata?.secureModeRedirectUrl && operation?.status === 'pending'" fxLayout="column" fxLayoutAlign="flex-start flex-start">
            <span (click)="on3DSLinkPressed()">{{ 'cc_payments_redirect_label' | translate }}</span>
        </div>
    </div>



    <p *ngIf="!!operation && operation?.failureReason && operation?.failureReason !== ''" class="regular-12" style="margin: 15px 0 0 0;">{{ operation?.failureReason }}</p>
</div>