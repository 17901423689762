import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SnotifyService } from 'ng-snotify';
import { NgxSpinnerService } from 'ngx-spinner';
import { startWith, Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceStateService } from 'src/app/services/device-state.service';
import { LoadingService } from 'src/app/services/loading.service';
import { AppConstants } from 'src/app/utils/app-constants';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {

    @ViewChild('emailElement') emailElement: ElementRef | undefined;

	hasErrorMessage = false;
	hasSuccessMessage = false;

	email = new FormControl('', [Validators.required, Validators.email]);

	mobile = false;


	private ngUnsubscribe = new Subject<void>();

	constructor(
		public readonly authService: AuthService,
		private readonly loadingService: LoadingService,
		private readonly toastService: SnotifyService,
		private readonly deviceStateService: DeviceStateService,
		private readonly translateService: TranslateService
	) {
        this.mobile = this.deviceStateService.isMobileResolution();
    }

	ngOnInit() {

		this.deviceStateService.screenResized
			.pipe(
				startWith(this.mobile),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(isMobile => this.mobile = isMobile);

		setTimeout(() => {
			if (!!this.emailElement) {
				this.emailElement.nativeElement.focus();
			}
		}, 100);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	recoverPassword() {
		this.hasSuccessMessage = false;
		this.hasErrorMessage = false;

		this.email.markAsTouched();

		if (!this.email.hasError('required') && !this.email.hasError('email')) {

			this.loadingService.show(this.translateService.instant('loadings.loading_sending_request'));

			this.authService
				.recoverPassword(this.email.value, 'https://places.the-roommate.com')
				.then(() => {
					
					this.hasSuccessMessage = true;
					this.loadingService.hide();
					
					this.toastService.success(
						this.translateService.instant('toasts.toast_password_recovery_desc'),
            			this.translateService.instant('toasts.toast_password_recovery_title'),
						{
							...AppConstants.TOAST_STD_CONFIG,
							timeout: 4000
						}
					);
				})
				.catch(() => {
					this.hasErrorMessage = true;
					this.email.setErrors({ 'submitted-error': true });
					this.loadingService.hide();
				});
		}
	}
}
