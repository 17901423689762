import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { fromEvent, Subject } from 'rxjs';
import { RoomMate } from '../app.module';
import { PushNotifications } from '@capacitor/push-notifications';
import { SplashScreen } from '@capacitor/splash-screen';
import { BackendService } from './backend.service';

@Injectable({
	providedIn: 'root'
})
export class DeviceStateService {

    public screenName : string = "";
    public backClickDisabled: boolean = false;
	public iOS: boolean = false;

    fakeBackClick$: Subject<void> = new Subject<void>();
    
    private mobileResolution: boolean;

	public screenResized: EventEmitter<boolean>;

	constructor(
        private readonly router: Router,
        private readonly backendService: BackendService
    ) {
		this.mobileResolution = window.innerWidth < 768;
		this.screenResized = new EventEmitter<boolean>();

		fromEvent(window, 'resize').subscribe(() => {
			const newVal = window.innerWidth < 768;
			if (newVal != this.mobileResolution) {
				this.screenResized.emit(newVal);
			}
			this.mobileResolution = newVal;
		});

        this.setupMobileExceptions();
	}

	public isMobileResolution(): boolean {
		return this.mobileResolution;
	}

    private async setupMobileExceptions () {

        const platform = Capacitor.getPlatform();
        const native = Capacitor.isNativePlatform();

        // iOS top bar GUI is different
        this.iOS = (platform === 'ios' && native);

        this.router.events.subscribe(async (val : any) => {
            
            if (!!val && !!val['urlAfterRedirects'] && !!!val['state']) {
                
                // iOS can swipe back (natively) on every page BUT home
                if (this.iOS && Capacitor.isPluginAvailable('RoomMate')) {

                    let webviewSwipeBack : boolean = !!!(['login', 'loading', 'passwordlessLogin', 'missingLandlordInfo', 'choosepassword', 'mandateCreationSucceeded'].find(it => val['urlAfterRedirects'].indexOf(it) > -1)) && val['urlAfterRedirects'] !== '/';

                    await RoomMate.setupBackNavigation({ webviewSwipeBack: webviewSwipeBack });
                }
            }
        });
    }

    async hideSplashScreenOnMobile () {

        if (Capacitor.isPluginAvailable('SplashScreen')) {
            await SplashScreen.hide();
        }
    }

    

    async addNotificationListeners() {
        await PushNotifications.addListener('registration', token => {
            console.info('Registration token: ', token.value);
            
            this.backendService.setToken(token.value, 'iOS');
            
        });

        await PushNotifications.addListener('registrationError', err => {
            console.error('Registration error: ', err.error);
        });

        await PushNotifications.addListener('pushNotificationReceived', notification => {
            console.log('Push notification received: ', notification);
        });

        await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
            console.log('Push notification action performed', notification.actionId, notification.inputValue);
        });
    }

    async registerNotifications (){
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
            permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== 'granted') {
            throw new Error('User denied permissions!');
        }

        await PushNotifications.register();
    }

    async getDeliveredNotifications () {
        const notificationList = await PushNotifications.getDeliveredNotifications();
        console.log('delivered notifications', notificationList);
    }
}
