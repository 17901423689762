<div class="payment-card">
    <div fxLayout="row" fxLayoutAlign="flex-start center" style="width: 100%;">
        <div fxFlex="65%" fxLayout="column" fxLayoutAlign="flex-start flex-start">
            <p class="semi-bold-10 payment-card-p-margin">{{ 'com_up_description' | translate }}</p>
            <p class="regular-12">{{ getPaymentTitle() }}</p>
        </div>
        <div fxFlex="35%" fxLayout="column" fxLayoutAlign="flex-start flex-start">
            <p class="semi-bold-10 payment-card-p-margin">{{ 'com_up_status' | translate }}</p>
            
            <div *ngIf="getStatus() === 'open'" class="rm-tag-view tag-primary">
                <p class="semi-bold-12 color-white">{{ 'pay_status_open' | translate }}</p>
            </div>
            <div *ngIf="getStatus() === 'pending'" class="rm-tag-view tag-primary">
                <p class="semi-bold-12 color-white">{{ 'pay_status_pending' | translate }}</p>
            </div>
            <div *ngIf="getStatus() === 'close' || getStatus() === 'succeeded'" class="rm-tag-view tag-green">
                <p class="semi-bold-12 color-white">{{ 'pay_status_paid' | translate }}</p>
            </div>
            <div *ngIf="getStatus() === 'overdue'" class="rm-tag-view tag-red">
                <p class="semi-bold-12 color-white">{{ 'pay_status_overdue' | translate }}</p>
            </div>
        </div>
    </div>
    
    <div fxLayout="row" fxLayoutAlign="flex-start center" style="width: 100%; margin-top: 15px;">
        <div fxFlex="65%" fxLayout="column" fxLayoutAlign="flex-start flex-start">
            <p class="semi-bold-10 payment-card-p-margin">{{ 'com_up_due_date' | translate }}</p>
            <p class="regular-12 no-margin-bottom">{{ payment?.dueDate | dfnsFormat: 'd MMM yyyy' }}</p>
        </div>
        <div fxFlex="35%" fxLayout="column" fxLayoutAlign="flex-start flex-start">
            <p class="semi-bold-10 payment-card-p-margin">{{ 'com_up_total' | translate }}</p>
            <p class="regular-12 no-margin-bottom">{{ (payment?.amount || 0) / 100 | currency: payment?.currency }}</p>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%; margin-top: 15px;">
        <button *ngIf="(getStatus() === 'open' || getStatus() === 'overdue') && !ctaIsViewAndNotPay; else viewPaymentBtn" mat-button class="button-positive button-small" (click)="viewPaymentClick()">{{ 'com_pay' | translate }}</button>
        <ng-template #viewPaymentBtn>
            <button mat-button class="button-primary button-small" (click)="viewPaymentClick()">{{ 'com_view' | translate }}</button>
        </ng-template>
    </div>
</div>