import { SnotifyPosition, SnotifyToastConfig } from "ng-snotify";
import { Size, Spinner } from "ngx-spinner";

export class AppConstants {

    public static SPINNER_ELEMENT_CONFIG: Spinner = {
        type: 'line-scale-party',
        size: 'medium' as Size,
        bdColor: '#ffffff',
        color: '#00BFF2'
    };

    public static TOAST_STD_CONFIG: SnotifyToastConfig = {
		position: SnotifyPosition.rightTop,
		timeout: 2000,
		titleMaxLength: 40
	};

    public static TOAST_SLOW_CONFIG: SnotifyToastConfig = {
		position: SnotifyPosition.rightTop,
		timeout: 10000,
		titleMaxLength: 40
	};

    public static SPINNER_STANDARD_CONFIG: Spinner = {
		type: 'line-scale-party',
		size: 'large' as Size,
		bdColor: 'rgba(0,0,0, .7)',
		color: '#00BFF2'
	};
}