<div *ngIf="!isLoading" fxLayout="row" fxLayoutAlign="center flex-start">

    <div fxLayout="column" fxLayoutAlign="flex-start center" class="pswd-main-content" [ngClass]="{'pswd-main-content-desk': !mobile, 'pswd-main-content-mobile': mobile }">

        <div [ngClass]="{'page-inner-content-desk': !mobile, 'page-inner-content-mobile': mobile}">
            
            <div fxLayout="column" fxLayoutAlign="flex-start center" style="width: 100%;">
                <img class="top-img" [ngClass]="{'mobile': mobile }" src="../../../assets/img/rm_img_choose_password.png" />
            
                <p class="regular-20 no-margin" [innerHTML]="'com_hi_x' | translate:{tenantNameSurname: tenantNameSurname}">
                </p>

                <p class="regular-15 text-center page-main-desc" *ngIf="!mobile" [innerHTML]="'choose_pswd_page_desc' | translate">
                </p>
                <p class="regular-15 text-center page-main-desc" *ngIf="mobile" [innerHTML]="'choose_pswd_page_desc_2' | translate">
                </p>

                <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%; margin: 20px 0 0 0;">
                    <p class="regular-15 text-center no-margin" [innerHTML]="'choose_password_email_account' | translate:{tenantEmail: email}">
                    </p>
                </div>

                <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%; margin: 10px 0 20px 0;">
                    <div [fxFlex]="mobile ? '100%' : '40%'">
                        <mat-form-field appearance="outline" class="field-full-width no-padding-bottom">
                            <input
                                [type]="hidePassword ? 'password' : 'text'"
                                matInput
                                [placeholder]="'com_password' | translate"
                                [formControl]="password"
                                (keyup)="checkValidity()"
                            />
                            <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </mat-form-field>
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%" class="info-row">
                    <p class="regular-12 no-margin text-center" [ngClass]="{'color-red': hasError}" style="line-height: 22px !important;">
                        {{ 'change_password_info' | translate }}
                    </p>
                </div>

                <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%" class="info-row-pswd">
                    <button
						fxFlex="100px"
						mat-button
						class="button-small"
                        [ngClass]="{'button-positive': password.valid, 'button-neutral': !password.valid}"
						(click)="saveClick()"
					>{{ 'com_save' | translate}}</button>
                </div>



                <p class="regular-15 text-center page-second-desc" *ngIf="!mobile" [innerHTML]="'choose_pswd_page_second_desc' | translate">
                </p>
                <p class="regular-15 text-center page-second-desc mobile" *ngIf="mobile" [innerHTML]="'choose_pswd_page_second_desc_2' | translate">
                </p>

                <div fxLayout="row wrap" fxLayoutAlign="center center" [ngClass]="{'home-tiles-desk': !mobile, 'home-tiles-mobile': mobile }">
        
                    <div fxLayout="column" fxLayoutAlign="flex-start center">
                        <div [ngClass]="{'home-tile-desk': !mobile, 'home-tile-mobile': mobile }">
                            <img [ngClass]="{'home-tile-img-desk': !mobile, 'home-tile-img-mobile': mobile }" src="../../assets/img/rm_icon_payments.svg" />
                        </div>
                        <p [ngClass]="{'regular-15': !mobile, 'regular-10': mobile, 'home-tile-desc-desk': !mobile, 'home-tile-desc-mobile': mobile }">{{ 'tiles.home_tile_payments' | translate }}</p>
                    </div>

                    <div fxLayout="column" fxLayoutAlign="flex-start center">
                        <div [ngClass]="{'home-tile-desk': !mobile, 'home-tile-mobile': mobile }">
                            <img [ngClass]="{'home-tile-img-desk': !mobile, 'home-tile-img-mobile': mobile }" src="../../assets/img/rm_icon_maintenances.svg" />
                        </div>
                        <p [ngClass]="{'regular-15': !mobile, 'regular-10': mobile, 'home-tile-desc-desk': !mobile, 'home-tile-desc-mobile': mobile }">{{ 'tiles.home_tile_maintenances' | translate }}</p>
                    </div>
            
                    <div fxLayout="column" fxLayoutAlign="flex-start center">
                        <div [ngClass]="{'home-tile-desk': !mobile, 'home-tile-mobile': mobile }">
                            <img [ngClass]="{'home-tile-img-desk': !mobile, 'home-tile-img-mobile': mobile }" src="../../assets/img/rm_icon_chat.svg" />
                        </div>
                        <p [ngClass]="{'regular-15': !mobile, 'regular-10': mobile, 'home-tile-desc-desk': !mobile, 'home-tile-desc-mobile': mobile }">{{ 'tiles.home_tile_chat' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</div>