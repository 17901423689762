import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, startWith, Subject, takeUntil } from 'rxjs';
import { BackendService } from '../services/backend.service';
import { LoadingService } from '../services/loading.service';
import { DeviceStateService } from '../services/device-state.service';
import { Landlord, NotificationCounters, UserData } from '../models/common';
import { SnotifyService } from 'ng-snotify';
import { AppConstants } from '../utils/app-constants';
import { MessagingService } from '../services/messaging.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { PaymentsSetupReminderDialogComponent } from '../payments-setup-reminder-dialog/payments-setup-reminder-dialog.component';
import { EpaymentPreferences } from '../models/payments';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit, OnDestroy {

    userData = {} as UserData;
    tenantNameSurname : string = "";
    landlordLogo : string = "../../assets/img/RYN-logo.svg";
    counters : NotificationCounters = {};

    landlords: Landlord[] = [];

    leasePresent : boolean = false;

    isLoading : boolean = true;

    allowedPaymentMethods: "only_credit_card" | "only_mandate" | "both" | "none" = "none"

    // Mobile
    mobile : boolean = false;
    addToHomescreen : boolean = false;
    popoverClosed : boolean = false;
    deferredPrompt : any;

    currentLandlordId : string = '';

    private ngUnsubscribe = new Subject<void>();

	constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
		private readonly loadingService: LoadingService,
        private readonly deviceStateService: DeviceStateService,
        private readonly backendService: BackendService,
        private readonly toastService: SnotifyService,
        private readonly messagingService: MessagingService,
        private readonly translateService: TranslateService,
        public dialog: MatDialog
    ) {
        this.mobile = this.deviceStateService.isMobileResolution();
    }

	ngOnInit(): void {
        this.initialConfiguration();
	}

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    initialConfiguration() {
        this.deviceStateService.screenName = 'dash_name_your_dashboard';
        this.loadingService.show(this.translateService.instant('loadings.loading_loading'));


        this.backendService.loadBasicUserData()
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(userData => {

                this.userData = userData;

                if (userData.language !== this.translateService.currentLang) {
                    this.backendService.editBasicUserData({...userData, language: this.translateService.currentLang})
                }

                if (userData.landlords.length === 0 || userData.currentLandlordId === "") {
                    
                    this.router.navigate(['missingLandlordInfo'], {relativeTo: this.route});
                }

                this.landlords = userData.landlords;

                this.tenantNameSurname = userData.name + " " + userData.surname;
                this.currentLandlordId = userData.currentLandlordId;
            });

        combineLatest([this.backendService.loadTenantLandlordData(), this.backendService.getCurrentCounters(), this.backendService.getUserPaymentInfo()])
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(([landlordData, counters, userPaymentInfo]) => {
                
                this.leasePresent = !!landlordData.currentLease;

                if (landlordData.logo !== '') {
                    this.landlordLogo = landlordData.logo
                }

                this.counters = counters;
                for (let key in this.counters) {
                    if (this.counters[key] > 99) {
                        this.counters[key] = 99;
                    }
                }

                this.setPaymentPreferences(landlordData.epaymentPreferences)

                const setupNeeded = !((userPaymentInfo.userInfo.status === 'created' && !!userPaymentInfo.userInfo.mangopay && !!userPaymentInfo.userInfo.mangopay.walletId && !!userPaymentInfo.userInfo.data) &&
                    (this.allowedPaymentMethods !== "only_mandate" ||(!!userPaymentInfo.bankAccountInfo && userPaymentInfo.bankAccountInfo.status === 'created' && !!userPaymentInfo.bankAccountInfo.data))// &&
                    //(this.allowedPaymentMethods !== "only_mandate" || (!!userPaymentInfo.kycInfo && (userPaymentInfo.kycInfo.status === 'created' || userPaymentInfo.kycInfo.status === 'active' || userPaymentInfo.kycInfo.status === 'succeeded' || userPaymentInfo.kycInfo.status === 'succeded'))
                );
                console.log(userPaymentInfo)
                const landlordEpaymentsConfigured = landlordData.landlordEpaymentsConfigured;

                if (setupNeeded && landlordEpaymentsConfigured && !this.backendService.homepagePaymentsSetupReminderSeen) {

                    this.backendService.homepagePaymentsSetupReminderSeen = true;

                    const dialogRef = this.dialog.open(PaymentsSetupReminderDialogComponent, {
                        width: '520px',
                        backdropClass: 'backdrop-dark-background',
                        panelClass: 'no-padding-modalbox'
                    });
            
                    dialogRef.afterClosed().subscribe(result => {
        
                        if (result && result.action === 'navigate') {
                            this.router.navigate(['payments'], {relativeTo: this.route});
                        }
                    });
                }


                this.isLoading = false;
                this.loadingService.hide();
            });


        // Notifications

        if (this.deviceStateService.iOS) {

            console.log("Push NATIVE iOS");
            
            this.deviceStateService.addNotificationListeners();
            this.deviceStateService.registerNotifications();

        } else {

            console.log("Web messaging notifications");

            try {
                this.messagingService.initMessages();
                this.messagingService.requestPermission();
            } catch (e) {
                console.error(`FirebaseMessaging service failed to load. Error`);
            }
        }

        
        this.deviceStateService.screenResized
            .pipe(
               startWith(this.mobile),
               takeUntil(this.ngUnsubscribe)
            )
            .subscribe(isMobile => this.mobile = isMobile);


        // PWA
        window.addEventListener('beforeinstallprompt', (e) => {

            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            
            this.deferredPrompt = e;
            this.addToHomescreen = true;
        });
    }

    async selectedLandlordId(landlordId: string) {
		console.log(landlordId);
        await this.backendService.editBasicUserData({...this.userData, currentLandlordId: landlordId})
        this.initialConfiguration();
	}

    setPaymentPreferences(epaymentPreferences: EpaymentPreferences) {
        const mandateEnabled = epaymentPreferences.bankMandatePolicy && epaymentPreferences.bankMandatePolicy !== "none"
        const creditCardsEnabled = epaymentPreferences.creditCardPolicy && epaymentPreferences.creditCardPolicy !== "none"

        if(mandateEnabled && !creditCardsEnabled){
            this.allowedPaymentMethods = "only_mandate"
        }
        else if(creditCardsEnabled && !mandateEnabled){
            this.allowedPaymentMethods = "only_credit_card"
        }
        else if(creditCardsEnabled && mandateEnabled){
            this.allowedPaymentMethods = "both"
        }else{
            this.allowedPaymentMethods = "none"
        }
    }

    settingsClick () {
        this.router.navigate(['/settings'], {relativeTo: this.route});
    }

    tileClick (num : number) {

        if (num === 1) {
            if (this.leasePresent) {
                this.router.navigate(['unit'], {relativeTo: this.route});
            } else if (this.mobile) {
                this.toastService.error(
                    this.translateService.instant('toasts.homepage_tooltip_no_lease'),
                    this.translateService.instant('toasts.toast_error_homepage_no_lease_title'),
                    AppConstants.TOAST_STD_CONFIG
                );
            }
        } else if (num === 2) {
            this.router.navigate(['payments'], {relativeTo: this.route});
        } else if (num === 3) {
            if (this.leasePresent) {
                this.router.navigate(['lease'], {relativeTo: this.route});
            } else if (this.mobile) {
                this.toastService.error(
                    this.translateService.instant('toasts.homepage_tooltip_no_lease'),
                    this.translateService.instant('toasts.toast_error_homepage_no_lease_title'),
                    AppConstants.TOAST_STD_CONFIG
                );
            }
        } else if (num === 4) {
            if (this.isLandlordAllowingFeature('chat')) {
                this.router.navigate(['chat'], {relativeTo: this.route});
            }
        } else if (num === 5) {
            if (this.leasePresent) {
                if (this.isLandlordAllowingFeature('maintenances')) {
                    this.router.navigate(['maintenances'], {relativeTo: this.route});
                }
            } else if (this.mobile) {
                this.toastService.error(
                    this.translateService.instant('toasts.homepage_tooltip_no_lease'),
                    this.translateService.instant('toasts.toast_error_homepage_no_lease_title'),
                    AppConstants.TOAST_STD_CONFIG
                );
            }
        } else if (num === 6) {
            this.router.navigate(['files'], {relativeTo: this.route});
        }
    }

    closeMobilePopoverClick () {
        this.popoverClosed = true;
    }
/*
    @HostListener('window:beforeinstallprompt', ['$event']) onbeforeinstallprompt(e : any) {
        
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        //e.preventDefault();

        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
        this.addToHomescreen = true;
    }
*/
    addToHomeScreenClick() {
        
        this.addToHomescreen = false;
        
        this.deferredPrompt.prompt();
        
        this.deferredPrompt.userChoice.then((choiceResult : any) => {
            
            if (choiceResult.outcome === 'accepted') {
              console.log('User accepted the A2HS prompt');
            } else {
              console.log('User dismissed the A2HS prompt');
            }

            this.deferredPrompt = null;
        });
    }

    getFeatureLockedTooltip () : string {
        if (!this.leasePresent && !this.mobile) {
            return this.translateService.instant('toasts.homepage_tooltip_no_lease');
        }
        return '';
    }

    isLandlordAllowingFeature (feature: string) {
        if (feature === 'maintenances') {
            if (this.currentLandlordId === 's8e9d3WDc2gr35KAMJIURIWfYV03') { // Cooliving
                return false;
            }
            if (this.currentLandlordId === 'elelx6L3ZjbnNgJAF78d9IwpAkg2') { // Bjorn
                return false;
            }
        } else if (feature === 'chat') {
            if (this.currentLandlordId === 's8e9d3WDc2gr35KAMJIURIWfYV03') { // Cooliving
                return false;
            }
        }

        return true;
    }
}
