import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SnotifyService } from 'ng-snotify';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { combineLatest, startWith, Subject, takeUntil } from 'rxjs';
import { AddressData, BankData, InvoiceData, UserData } from '../models/common';
import { PaymentProviderRecurringPaymentStatus, PropertyPayment, RecurringPayment, UserPaymentInfoBankAccountData, UserPaymentInfoProfileData } from '../models/payments';
import { BackendService } from '../services/backend.service';
import { DeviceStateService } from '../services/device-state.service';
import { LoadingService } from '../services/loading.service';
import { AppConstants } from '../utils/app-constants';
import { AppUtils } from '../utils/app-utils';

const ibantools = require('ibantools');

@Component({
  selector: 'app-payments-settings',
  templateUrl: './payments-settings.component.html',
  styleUrls: ['./payments-settings.component.scss']
})
export class PaymentsSettingsComponent implements OnInit {

    appUtils: AppUtils;

	// Step 1
	public basicInfoForm = this.formBuilder.group({
		name: new FormControl('', [Validators.required]),
		surname: new FormControl('', [Validators.required]),
		dateOfBirth: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
		nationality: new FormControl('', [Validators.required]),
        countryOfResidence: new FormControl('', [Validators.required])
	});
    today = new Date();
	basicInfoFormInitialValue: any;

	// Step 2
    public bankInfoForm = this.formBuilder.group({
		beneficiary: new FormControl('', [Validators.required]),
		iban: new FormControl('', [Validators.required])
	});
	bankInfoFormInitialValue: any = {};

    defaultInvoiceData: InvoiceData = {};
    invoiceData: InvoiceData = {
		type: 'natural'
	};
    currentInvoiceData: InvoiceData = { ...this.defaultInvoiceData };

	creditCards: {
        cardId: string,
        last4Digits: string
    }[] = []

	recurringPayments:{
		recurringPayment: RecurringPayment
	}[] = []

	// Status

	isLoading : boolean = true;
	bankDataEnabled : boolean = false;
	somethingChanged1 : boolean = false;
	somethingChanged2 : boolean = false;
	somethingChanged3 : boolean = false;
	mobile : boolean = false;

	userData : UserData | undefined;
	bankData: BankData | undefined;

	private ngUnsubscribe = new Subject<void>();

	constructor(
		private readonly location: Location,
		private readonly backendService: BackendService,
		private readonly deviceStateService: DeviceStateService,
		private readonly formBuilder: FormBuilder,
		private readonly loadingService: LoadingService,
		private readonly translateService: TranslateService,
        private readonly gaService: GoogleAnalyticsService,
        private readonly toastService: SnotifyService
	) {
		this.mobile = deviceStateService.isMobileResolution();

        this.appUtils = new AppUtils(translateService);
	}

	ngOnInit(): void {

		this.deviceStateService.screenName = 'menu_title_your_payments';
		this.loadingService.show(this.translateService.instant('loadings.loading_loading'));

		combineLatest([
			this.backendService.loadBasicUserData(),
			this.backendService.getUserPaymentInfo(),
			this.backendService.getPayments(),
			this.backendService.loadTenantLandlordData(),
		])
			.pipe(
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(([userData, info, payments, tenantLandlordData]) => {
				this.userData = userData;
				this.bankData = tenantLandlordData.bankData;
				if (info.userInfo.status === 'created' && !!info.userInfo.mangopay && !!info.userInfo.mangopay.walletId && !!info.userInfo.data) {
					// Step 1 ok (user info)
		
					this.basicInfoFormInitialValue = {
						"name": info.userInfo.data.firstName || "",
						"surname": info.userInfo.data.lastName || "",
						"dateOfBirth": !!info.userInfo.data.birthDayUtcSeconds ? new Date(info.userInfo.data.birthDayUtcSeconds * 1000) : "",
						"email": info.userInfo.data.email || "",
						"nationality": (!!info.userInfo.data.nationality && info.userInfo.data.nationality !== '') ? { alpha2Code: info.userInfo.data.nationality } : "",
						"countryOfResidence": (!!info.userInfo.data.nationality && info.userInfo.data.nationality !== '') ? { alpha2Code: info.userInfo.data.countryOfResidence } : ""
					}
					// Load data
					this.basicInfoForm.setValue(this.basicInfoFormInitialValue);
		
					if(info.creditCardInfo?.creditCards){
						this.creditCards = Object.values(info.creditCardInfo.creditCards).map(creditCard => { return {
							cardId: creditCard.id,
							last4Digits: creditCard.last4Digits
						}});
					}
		
					if (!!info.bankAccountInfo && info.bankAccountInfo.status === 'created' && !!info.bankAccountInfo.data) {
						// Step 2 ok (bank info)
		
						this.bankInfoFormInitialValue = {
							"beneficiary": info.bankAccountInfo.data.benificiaryName || "",
							"iban": info.bankAccountInfo.data.iban || ""
						};

						this.bankInfoForm.setValue(this.bankInfoFormInitialValue);
		
						let gottenInvoiceData = userData.invoiceData;
						
						gottenInvoiceData.address = info.bankAccountInfo.data.address?.address || "";
						gottenInvoiceData.houseNumber = info.bankAccountInfo.data.address?.houseNumber || "";
						gottenInvoiceData.postalCode = info.bankAccountInfo.data.address?.postalCode || "";
						gottenInvoiceData.city = info.bankAccountInfo.data.address?.city || "";
						gottenInvoiceData.region = info.bankAccountInfo.data.address?.region || "";
						gottenInvoiceData.regionCode = info.bankAccountInfo.data.address?.regionCode || "";
						gottenInvoiceData.country = info.bankAccountInfo.data.address?.country || "";
						gottenInvoiceData.countryCode = info.bankAccountInfo.data.address?.countryCode || "";
		
						this.invoiceData = gottenInvoiceData
						this.currentInvoiceData = this.invoiceData;
					}
				}

				this.basicInfoForm.disable();
				this.bankInfoForm.disable();

				this.isLoading = false;
                this.loadingService.hide();

				const linkedRecurringIds = payments.map(p => p.linkedPeriodicPaymentId).filter((v, i, a) => a.indexOf(v) === i).filter(it=> it);

				linkedRecurringIds.forEach(recurringPaymentId => {
					this.backendService.getRecurringPayment(recurringPaymentId).subscribe(backendRecurringPayment => {
						if(backendRecurringPayment.paymentProviderAssociation?.status && backendRecurringPayment.paymentProviderAssociation.status !== PaymentProviderRecurringPaymentStatus.INVALID && !this.recurringPayments.find(recurringPayment => recurringPayment.recurringPayment.id === backendRecurringPayment.id)){
							this.recurringPayments.push({
								recurringPayment: backendRecurringPayment
							});
						}
					})
				});
			});

		this.deviceStateService.screenResized
			.pipe(
				startWith(this.mobile),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(isMobile => this.mobile = isMobile);

		this.basicInfoForm.valueChanges.subscribe(x => {
			let different : boolean = false;
			for (let key in x) {
				if (key === 'nationality' || key === 'countryOfResidence') {

					if (x[key] && x[key]['alpha2Code'] !== this.basicInfoFormInitialValue[key]['alpha2Code']) {
						different = true;
						break;
					}
				} else if (x[key] !== this.basicInfoFormInitialValue[key]) {
					different = true;
					break;
				}
			}

			this.somethingChanged1 = different;
		});

		this.bankInfoForm.valueChanges.subscribe(x => {
			let different : boolean = false;
			for (let key in x) {
				if (x[key] !== this.bankInfoFormInitialValue[key]) {
					different = true;
					break;
				}
			}

			this.somethingChanged2 = different;
		});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	backClick () {
		this.location.back();
	}

	onAddressUpdated(event: AddressData) {
		
        this.currentInvoiceData = { ...this.currentInvoiceData, ...event };

		this.somethingChanged3 = true;
	}
	
	editPeronalDataClick () {
		this.basicInfoForm.enable();
	}

	editBankAccountClick () {
		this.bankInfoForm.enable();
		this.bankDataEnabled = true;
	}

	deletePaymentMethod(paymentMethodId: string){
		this.backendService.deletePaymentMethod(paymentMethodId).then(()=>{
			this.creditCards = []
		})
	}

	cancelRecurringPayment(recurringPaymentId: string){
		this.backendService.cancelRecurringPayment(recurringPaymentId).then(()=>{
			this.recurringPayments = this.recurringPayments.filter(recurringPayment => recurringPayment.recurringPayment.id !== recurringPaymentId);
		})
	}

	saveClick () {

        let promises : Promise<any>[] = [];

		if (this.somethingChanged1) {
			
            this.basicInfoForm.markAllAsTouched();

            if (this.basicInfoForm.valid) {

                const data : UserPaymentInfoProfileData = {
                    birthDayUtcSeconds: (this.basicInfoForm.get("dateOfBirth")?.value as Date).getTime() / 1000,
                    countryOfResidence: this.basicInfoForm.get("countryOfResidence")?.value.alpha2Code,
                    email: this.basicInfoForm.get("email")?.value,
                    firstName: this.basicInfoForm.get("name")?.value,
                    lastName: this.basicInfoForm.get("surname")?.value,
                    nationality: this.basicInfoForm.get("nationality")?.value.alpha2Code
                }

                promises.push(
                    this.backendService.postPaymentsProfile(data)
                        .then(() => {
                            this.gaService.gtag('event', 'payments_setup_change_profile_called', {
                                'event_label': 'Payments setup profile called',
                                'event_category': 'payments'
                            });
                        })
                );

            } else {
                this.toastService.error(
                    this.translateService.instant('toasts.toast_error_check_fields_desc'),
                    this.translateService.instant('toasts.toast_error_check_fields_title'),
                    AppConstants.TOAST_STD_CONFIG
                );
            }

		}

		if (this.somethingChanged2 || this.somethingChanged3) {
			
            this.bankInfoForm.markAllAsTouched();

            if (this.bankInfoForm.valid && this.appUtils.isAddressComplete(this.currentInvoiceData)) {

                const iban = ibantools.electronicFormatIBAN(this.bankInfoForm.get("iban")?.value);

                const sepaCountriesNotEUR = ["BG", "DK", "GI", "HU", "IS", "LI", "NO", "PL", "RO", "SE", "CH", "GB"];

                if (this.currentInvoiceData.countryCode === "GB" || iban.startsWith("GB")) {

                    this.toastService.error(
                        this.translateService.instant('toasts.toast_error_no_gb_support_desc'),
                        this.translateService.instant('toasts.toast_error_no_gb_support_title'),
                        AppConstants.TOAST_STD_CONFIG
                    );
    
                } else if (!ibantools.isValidIBAN(iban)) {

                    this.toastService.error(
                        this.translateService.instant('toasts.toast_error_iban_not_valid_desc'),
                        this.translateService.instant('toasts.toast_error_iban_not_valid_title'),
                        AppConstants.TOAST_STD_CONFIG
                    );
    
                } else if (!ibantools.isSEPACountry(this.currentInvoiceData.countryCode || "") || sepaCountriesNotEUR.includes(this.currentInvoiceData.countryCode || "")) {
    
                    this.toastService.error(
                        this.translateService.instant('toasts.toast_error_country_not_sepa_eur_desc'),
                        this.translateService.instant('toasts.toast_error_country_not_sepa_eur_title'),
                        AppConstants.TOAST_STD_CONFIG
                    );
    
                } else {

                    const addressData : AddressData = {
                        address: this.currentInvoiceData.address,
                        houseNumber: this.currentInvoiceData.houseNumber,
                        postalCode: this.currentInvoiceData.postalCode,
                        city: this.currentInvoiceData.city,
                        region: this.currentInvoiceData.region,
                        regionCode: this.currentInvoiceData.regionCode,
                        country: this.currentInvoiceData.country,
                        countryCode: this.currentInvoiceData.countryCode
                    }

                    const data : UserPaymentInfoBankAccountData = {
                        "address": addressData,
                        "benificiaryName": this.bankInfoForm.get("beneficiary")?.value,
                        "iban": iban,
                        "type": "iban"
                    }
    
                    promises.push(
                        this.backendService.postPaymentsBankAccount(data)
                            .then(async () => {
															if(this.userData && this.bankData) {
																this.userData.invoiceData = this.currentInvoiceData;
																this.bankData = {
																	...this.bankData,
																	"beneficiary": this.bankInfoForm.get("beneficiary")?.value,
																	"iban": iban,
																}
																await this.backendService.editBasicUserData(this.userData)
																await this.backendService.editTenantLandlordBankData(this.bankData)
															}

															this.gaService.gtag('event', 'payments_setup_bank_called', {
																	'event_label': 'Payments setup bank called',
																	'event_category': 'payments'
															});
                            })
                    );
                
                }

            } else {
                this.toastService.error(
                    this.translateService.instant('toasts.toast_error_check_fields_desc'),
                    this.translateService.instant('toasts.toast_error_check_fields_title'),
                    AppConstants.TOAST_STD_CONFIG
                );
            }

		}


        if (promises.length > 0) {

            this.loadingService.show(this.translateService.instant('loadings.loading_info_loading'));

            Promise.all(promises)
                .then(() => {
                    this.backClick();
                })
                .catch(err => {

                    if (!!err && !!err.error && !!err.error.message && err.error.message.indexOf("IBAN") >= 0) {
                        
						if (err.error.message === "You cannot use the same IBAN as your landlord") {
							this.toastService.error(
								this.translateService.instant('toasts.toast_error_payments_setup_request_iban_desc'),
								this.translateService.instant('toasts.toast_error_payments_setup_request_title'),
								AppConstants.TOAST_STD_CONFIG
							);
						}else{
							this.toastService.error(
								this.translateService.instant('toasts.toast_error_payments_setup_request_iban_desc'),
								this.translateService.instant('toasts.toast_error_payments_setup_request_title'),
								AppConstants.TOAST_STD_CONFIG
							);
						}
                    }
					else {

                        // TODO: show error-specific message
                        this.toastService.error(
                            this.translateService.instant('toasts.toast_error_payments_setup_request_desc'),
                            this.translateService.instant('toasts.toast_error_payments_setup_request_title'),
                            AppConstants.TOAST_STD_CONFIG
                        );
                    }
                })
                .finally(() => {
                    this.loadingService.hide();
                });
        }
	}
}
