<div fxLayout="row" fxLayoutAlign="center flex-start">

    <input
		type="file"
		accept=".jpg,.jpeg,.png"
		multiple
		(change)="picturesSelected($event)"
		#file
		style="display: none"
	/>

    <div fxLayout="column" fxLayoutAlign="flex-start center" class="maintenance-main-content" [ngClass]="{'maintenance-main-content-desk': !mobile, 'maintenance-main-content-mobile': mobile }">

        <div *ngIf="!mobile" fxLayout="row" fxLayoutAlign="flex-start center" class="top-row">
            <img class="img-back" src="../../assets/img/rm_icon_arrow_left.svg" (click)="backClick()" />
            <p class="regular-20 no-margin" [innerHTML]="'screenTitles.add_maintenance_title' | translate">
            </p>
        </div>
        <div *ngIf="mobile" fxLayout="row" fxLayoutAlign="flex-start center" class="top-row-mobile">
            <p class="regular-15" [innerHTML]="'screenTitles.add_maintenance_title_2' | translate">
            </p>
        </div>

        <div [ngClass]="{'page-inner-content-desk': !mobile, 'page-inner-content-mobile': mobile}">
            <div fxLayout="column" fxLayoutAlign="flex-start flex-start" style="width: 100%;">
                <p class="semi-bold-15 no-margin">{{ 'com_maintenance_details' | translate }}</p>
                <p class="regular-12 no-margin">{{ 'add_maintenance_details_desc' | translate }}</p>
                <div class="sep-row-light"></div>

                <div fxLayout="row wrap" fxLayoutAlign="flex-startflex-start flex-start" style="width: 100%;">
                
                    <div [fxFlex]="mobile ? '100%' : '200px'" class="field-margin" [ngStyle]="{'margin-right': mobile ? '0px' : '20px'}">
                        <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                            <mat-label>{{ 'com_title' | translate }}</mat-label>
                            <input
                                type="text"
                                matInput
                                [placeholder]="'com_title' | translate"
                                [formControl]="title"
                                autocomplete="off"
                            />
                        </mat-form-field>
                    </div>

                    <div [fxFlex]="mobile ? '100%' : '200px'" class="field-margin">
                        <mat-form-field class="field-full-width" appearance="outline" hideRequiredMarker>
                            <mat-label>{{ 'com_category' | translate }}</mat-label>
                            <mat-select [formControl]="category">
                                <mat-option *ngFor="let cat of maintenanceCategories" [value]="cat">
                                    {{ cat }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="flex-start flex-start" style="width: 100%; margin-bottom: 40px;">
                
                    <div [fxFlex]="mobile ? '100%' : '60%'">
                        <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                            <mat-label>{{ 'com_description' | translate }}</mat-label>
                            <textarea
                                cdkTextareaAutosize
                                matInput
                                [placeholder]="'com_description' | translate"
                                [formControl]="description"
                                autocomplete="off"
                            ></textarea>
                        </mat-form-field>
                    </div>

                </div>


                <p class="semi-bold-15 no-margin">{{ 'com_add_photos' | translate }}</p>
                <p class="regular-12 no-margin">{{ 'add_maintenance_photos_desc' | translate }}</p>
                <div class="sep-row-light"></div>

                <button mat-button class="button-primary button-small" (click)="attachPhoto()" style="margin-bottom: 10px;">{{ 'com_upload' | translate }}</button>

                <p class="regular-12" [innerHTML]="'add_photos_allowed_desc' | translate">
                </p>


                <app-file-picker [files]="stagingArea" [showUploadTime]="false" [allowLink]="false" [allowEdit]="true" (filesChanged)="photosChanged($event)"></app-file-picker>


                <div fxLayout="row" fxLayoutAlign="center flex-start" style="width: 100%; margin-top: 40px;">

                    <button mat-button class="button-positive" [ngClass]="{'button-small': mobile}" (click)="sendRequest()">{{ 'com_send' | translate }}</button>

                </div>
            </div>
        </div>
    </div>
</div>