import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-delete-confirm-dialog',
	templateUrl: './delete-confirm-dialog.component.html',
	styleUrls: ['./delete-confirm-dialog.component.scss']
})
export class DeleteConfirmDialogComponent implements OnInit {
	id = 0;

	constructor(
		public dialogRef: MatDialogRef<DeleteConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			id: number;
		}
	) {
		this.id = data.id;
	}

	ngOnInit() {}

	cancelClick() {
		this.dialogRef.close({ action: 'none' });
	}

	proceedClick() {
		this.dialogRef.close({ action: 'do_delete' });
	}
}
