import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject, startWith, takeUntil } from "rxjs";
import { Unit, UnitInfo } from "src/app/models/properties";
import { DeviceStateService } from "src/app/services/device-state.service";
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-unit-sidebar',
  templateUrl: './unit-sidebar.component.html',
  styleUrls: ['./unit-sidebar.component.scss'],
})
export class UnitSidebarComponent implements OnInit{
  mobile: boolean = false;
  private ngUnsubscribe = new Subject<void>();

  @Input() units: UnitInfo[] = [];
  @Input() selectedId: string = '';
  @Output() unitChange:EventEmitter<UnitInfo> =new EventEmitter<UnitInfo>();

  constructor(
    private readonly deviceStateService: DeviceStateService,
    private readonly translateService: TranslateService,
  ) {
    this.mobile = deviceStateService.isMobileResolution();
  }

  ngOnInit(): void {
    this.deviceStateService.screenResized
      .pipe(startWith(this.mobile), takeUntil(this.ngUnsubscribe))
      .subscribe((isMobile) => (this.mobile = isMobile));
  }

  selectUnit(unit: UnitInfo) {
    this.unitChange.emit(unit);
  }

  redirectToRYN(id: string) {
    console.log(`${environment.rentYourNest.baseURL}?unitId=${id}&lang=${this.translateService.currentLang}`)
    window.open(`${environment.rentYourNest.baseURL}?unitId=${id}&lang=${this.translateService.currentLang}`, '_blank');
  }

  redirectRynTooltip(): string {
    return this.translateService.instant('toasts.redirect_to_ryn_unit');
  }
}
