<div mat-dialog-content>
	<div fxLayout="column" fxLayoutAlign="flex-start center" class="popup-top-section">
		<div class="v-spacing-20"></div>

		<p *ngIf="data.paymentStatus === 'succeeded'" class="semi-bold-15 color-green no-margin" [innerHTML]="'payment_complete_confirm_positive_title' | translate">
		</p>
		<p *ngIf="data.paymentStatus === 'failure'" class="semi-bold-15 color-red no-margin" [innerHTML]="'payment_complete_confirm_negative_title' | translate">
		</p>
		<p *ngIf="data.paymentStatus === 'pending'" class="semi-bold-15 color-1 no-margin" [innerHTML]="'payment_complete_confirm_pending_title' | translate">
		</p>

		<img
			src="../../assets/img/close.svg"
			class="clickable"
			(click)="cancelClick()"
			style="width: 15px; height: 15px"
		/>
	</div>

	<div fxLayout="column" fxLayoutAlign="center center" fxLayoutWrap>
		<p *ngIf="data.paymentStatus === 'succeeded'" class="regular-15 text-center" style="padding: 20px;">
			{{ 'payment_complete_confirm_positive_desc' | translate }}
		</p>
		<p *ngIf="data.paymentStatus === 'failure'" class="regular-15 text-center" style="padding: 20px;">
			{{ 'payment_complete_confirm_negative_desc' | translate }} <br> {{data.data}}
		</p>
		<p *ngIf="data.paymentStatus === 'pending'" class="regular-15 text-center" style="padding: 20px;">
			{{ 'payment_complete_confirm_pending_desc' | translate }}
		</p>

		<div
			class="popup-bottom-section"
			fxLayout="row"
			fxLayoutAlign="center center"
			fxLayoutWrap
			style="width: 100%"
		>
			<button
				*ngIf="data.paymentStatus === 'succeeded'"
				(click)="proceedClick()"
				mat-button
				class="button-positive button-small"
			>{{ 'com_confirm' | translate }}</button>
			<button
				*ngIf="data.paymentStatus === 'failure'"
				(click)="proceedClick()"
				mat-button
				class="button-negative button-small"
			>{{ 'com_confirm' | translate }}</button>
			<button
				*ngIf="data.paymentStatus === 'pending'"
				(click)="proceedClick()"
				mat-button
				class="button-primary button-small"
			>{{ 'com_confirm' | translate }}</button>
		</div>
	</div>
</div>
