import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MaintenanceRequest } from 'src/app/models/properties';
import { AppUtils } from 'src/app/utils/app-utils';

@Component({
  selector: 'app-mobile-maintenance-card',
  templateUrl: './mobile-maintenance-card.component.html',
  styleUrls: ['./mobile-maintenance-card.component.scss']
})
export class MobileMaintenanceCardComponent {

    appUtils: AppUtils;

	@Input() maintenance : MaintenanceRequest | undefined;

	@Output() viewMaintenance = new EventEmitter<MaintenanceRequest>();

	constructor(
        private readonly translateService: TranslateService
    ) {
        this.appUtils = new AppUtils(translateService);
    }

	hasAttachments () : boolean {
		return !!this.maintenance ? (((this.maintenance?.photos || []).length + (this.maintenance?.files || []).length) > 0) : false;
	}

    getCategoryName () {
        if (!!this.maintenance) {
            return this.appUtils.getMaintenanceCategoryName(this.maintenance);
        }
        
        return "";
    }

	viewMaintenanceClick () {
		if (!!this.maintenance) {
			this.viewMaintenance.emit(this.maintenance);
		}
	}
}
