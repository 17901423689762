<div mat-dialog-content>
	<div *ngIf="!isLoading" fxLayout="column" fxLayoutAlign="center center">
		<div fxLayout="row" fxLayoutAlign="center center" class="top-row">
            <p class="semi-bold-15 color-white" [innerHTML]="'crop_picture_popup_title' | translate">
            </p>
        </div>

        <p class="regular-12 no-margin text-center" [innerHTML]="'crop_picture_popup_desc' | translate:{tenantNameSurname: tenantNameSurname}" >
        </p>

		<image-cropper
			style="max-height: 350px; margin: 20px 0 10px 0"
			[imageURL]="imageURL"
			format="png"
			(imageCropped)="imageCropped($event)"
			[maintainAspectRatio]="false"
		></image-cropper>

        <div fxLayout="row" fxLayoutAlign="space-between center" class="bottom-row">
            <button mat-button class="button-negative-empty button-small" (click)="closeDialog()">{{ 'com_cancel' | translate }}</button>
            <button mat-button class="button-positive button-small" (click)="okClick()">{{ 'com_save' | translate }}</button>
        </div>
	</div>
</div>
