<div class="container container-mobile">
	<div fxLayout="column" fxLayoutAlign="center center" class="container-mobile-inner" style="height: 100%">
		
        <div fxLayout="row" fxLayoutAlign="center flex-start" style="width: 100%">
			<div [fxFlex]="mobile ? '80%' : '500px'" fxLayout="column">

                <div fxLayout="row" fxLayoutAlign="center flex-start" style="width: 100%">
                    <img class="logo-img" src="../../assets/img/RYN-logo-negative-orange.svg" />
                </div>

				<p fxLayout="row" fxLayoutAlign="center flex-start" class="semi-bold-25 no-margin color-orange">{{ 'login_mobile_login' | translate }}</p>
				<!-- <p class="regualar-15 no-margin color-white v-spacing" [innerHTML]="'login_mobile_estelle_dashboard' | translate">
				</p> -->

				<mat-form-field appearance="outline" class="field-full-width" style="margin-top: 40px">
					<input type="email" matInput placeholder="Email" [formControl]="email" />
					<mat-error *ngIf="email.invalid"></mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="field-full-width no-padding-bottom v-spacing-20">
					<input
						type="password"
						matInput
						placeholder="Password"
						[formControl]="password"
						(keyup.enter)="login()"
						#passwordElement
					/>
				</mat-form-field>
				<div fxLayout="row" fxLayoutWrap style="width: 100%">
					<div fxFlex="100%" fxLayoutAlign="end flex-start">
						<p
							class="light-12 color-grey v-spacing clickable"
							style="text-align: right"
							(click)="passwordRecoveryClick()"
							>{{ 'login_password_recover' | translate }}
                        </p>
					</div>
				</div>

				<div fxLayout="row" fxLayoutAlign="center center" style="width: 100%; margin-top: 40px">
					<button
						fxFlex="50%"
						mat-button
						class="button-positive button-small"
						(click)="login()"
					>
                        {{ 'com_login' | translate }}
                    </button>
				</div>

                <div style="height: 52px; width: 100%;">
                    <p *ngIf="hasErrorMessage" class="error-message">{{ errorMessage }}</p>
                </div>

                <!-- <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%;">
                    <p class="regular-12 color-white text-center no-margin" [innerHtml]="'login_old_app_social_login' | translate"></p>
                </div> -->

                <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%; margin-top: 25px;">
                    <div fxFLex="50%">
                        <mat-form-field class="field-full-width no-padding-bottom" appearance="outline">
                            <mat-select (selectionChange)="socialLoginPressed($event)" [placeholder]="'login_other_login' | translate">
                                <mat-option value="facebook">
                                    <div fxLayout="row" fxLayoutAlign="flex-start center" style="width: 100%;">
                                        <img class="social-img" src="../../assets/img/rm_ic_facebook.svg" />
                                        <p class="regular-12" style="margin: 0 0 0 20px;">Facebook</p>
                                    </div>
                                </mat-option>
                                <mat-option value="google">
                                    <div fxLayout="row" fxLayoutAlign="flex-start center" style="width: 100%;">
                                        <img class="social-img" src="../../assets/img/rm_ic_google.svg" />
                                        <p class="regular-12" style="margin: 0 0 0 20px;">Google</p>
                                    </div>
                                </mat-option>
                                <mat-option value="apple">
                                    <div fxLayout="row" fxLayoutAlign="flex-start center" style="width: 100%;">
                                        <img class="social-img" src="../../assets/img/rm_ic_apple.svg" />
                                        <p class="regular-12" style="margin: 0 0 0 20px;">Apple</p>
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>


				<p class="regular-12 color-white" style="text-align: center; margin-top: 25px;">
					<a
                        href="https://rentyournest.com"
						target="_blank"
						class="color-white"
                        [innerHTML]="'login_back_to_website' | translate"
					>
                    </a>
				</p>

                <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%; margin-top: 20px;">
                    <p class="regular-12 color-grey">
                        <span class="clickable" [class]="{'semi-bold-12': currentLanguage === 'en', 'color-orange': currentLanguage === 'en'}" (click)="setLanguage('en')">{{ 'languages.english' | translate }}</span> - <span class="clickable" [class]="{'semi-bold-12': currentLanguage === 'it', 'color-orange': currentLanguage === 'it' }" (click)="setLanguage('it')">{{ 'languages.italian' | translate }}</span> - <span class="clickable" [class]="{'semi-bold-12': currentLanguage === 'es', 'color-orange': currentLanguage === 'es' }" (click)="setLanguage('es')">{{ 'languages.spanish' | translate }}</span> - <span class="clickable" [class]="{'semi-bold-12': currentLanguage === 'fr', 'color-orange': currentLanguage === 'fr' }" (click)="setLanguage('fr')">{{ 'languages.french' | translate }}</span>
                    </p>
                </div>

                <div style="width: 100%; margin: 20px 0; text-align: center;">
                    <p class="regular-12 color-white text-center no-margin" style="display: inline;" [innerHtml]="'msg_helpcenter_login' | translate"></p>
                    <a
                        href="https://helpcenter.the-roommate.com/en/"
                        target="_blank"
                        class="regular-12 helpcenter-link"
                        [innerHTML]="'helpcenter_text' | translate"
                    >
                    </a>
                </div>

                <div class="appVersion regular-12 color-grey">{{placesVersion}}</div>
			</div>
		</div>
	</div>
</div>