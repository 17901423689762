import { switchMap } from 'rxjs/operators';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { AuthService } from '../auth.service';
import packageInfo from  '../../../../package.json'

interface InterceptorHeaders {
	authorization: string;
	estelleVersion: string;
	estelleSession: string;}

@Injectable({
	providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

	currentSession = this.firebaseAuth.currentSession;

	constructor(private firebaseAuth: AuthService) {}

	private getAuthenticatedHeaders(): Observable<InterceptorHeaders> {
		return from(this.firebaseAuth.getTokenHeader()).pipe(
			switchMap(token => {
				const headers = {
					authorization: `Bearer ${token}`,
					estelleVersion: packageInfo.version,
					estelleSession: this.currentSession
				};
				return of(headers);
			})
		);
	}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.url.includes('/assets/strings')) {
            return next.handle(request);
        } else {

            return this.getAuthenticatedHeaders().pipe(
                switchMap((headers: InterceptorHeaders) => {
                    const newRequest = request.clone({
                        setHeaders: {
                            Authorization: headers.authorization,
                            'Estelle-Version': `${headers.estelleVersion}`,
                            'Estelle-Session': `${headers.estelleSession}`
                        }
                    });
                    return next.handle(newRequest);
                })
            );
        }
	}
}
