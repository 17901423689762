import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { firstValueFrom, startWith, Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceStateService } from 'src/app/services/device-state.service';

@Component({
  selector: 'app-request-data',
  templateUrl: './request-data.component.html',
  styleUrls: ['./request-data.component.scss']
})
export class RequestDataComponent implements OnInit, OnDestroy {

	isDelete : boolean = false;

	// Mobile
	mobile : boolean = false;


	private ngUnsubscribe = new Subject<void>();


	constructor(
		private readonly location: Location,
		private readonly deviceStateService: DeviceStateService,
		private readonly authService: AuthService
	) {
		this.isDelete = window.location.href.indexOf('/delete') >= 0;

		this.mobile = deviceStateService.isMobileResolution();
	}

	ngOnInit(): void {

		this.deviceStateService.screenName = 'menu_title_account_settings';

		this.deviceStateService.screenResized
			.pipe(
				startWith(this.mobile),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(isMobile => this.mobile = isMobile);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	backClick () {
		this.location.back();
	}

	async contactUsClick () {

		const subject = this.isDelete ? "request to delete account" : "request personal data";
		const uId : string = await firstValueFrom(this.authService.getUserId());

		window.location.href = "mailto:roommatehq@gmail.com?subject=" + subject + "&body=%0d%0a%0d%0aReference: " + uId;
	}
}
