import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
	
    constructor(
		private router: Router,
		private authService: AuthService
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		this.authService.routeToRedirect = state.url.toString();
		
        if (this.authService.isLoggedIn()) {
			return true;
		} else {

            if (this.authService.firstAuthCheckMade) {
				this.router.navigate(['/login']);
				return false;
			} else {

                if (!this.router.url.startsWith('/mandateCreationSucceeded') && !this.router.url.startsWith('/passwordrecovery')) {
                    this.router.navigate(['/loading']);
                }
				
				return false;
			}
		}
	}
}
