import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { CookieService } from 'ngx-cookie-service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { firstValueFrom, takeUntil } from 'rxjs';
import { BackendService } from 'src/app/services/backend.service';
import { LoadingService } from 'src/app/services/loading.service';
import { DeviceStateService } from 'src/app/services/device-state.service';
import { AppConstants } from 'src/app/utils/app-constants';
import { TranslateService } from '@ngx-translate/core';
import { PaymentMethods } from 'src/app/models/payments';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-process-payment-after-mandate-creation',
  templateUrl: './process-payment-after-mandate-creation.component.html',
  styleUrls: ['./process-payment-after-mandate-creation.component.scss']
})
export class ProcessPaymentAfterMandateCreationComponent implements OnInit {

    idempotencyKey: string = "";

	constructor(
		private readonly router: Router,
		private readonly route: ActivatedRoute,
        private readonly loadingService: LoadingService,
        private readonly backendService: BackendService,
        private readonly toastService: SnotifyService,
        private readonly cookieService: CookieService,
        private readonly gaService: GoogleAnalyticsService,
        private readonly translateService: TranslateService
	) { }

	ngOnInit(): void {

        this.idempotencyKey = uuidv4();

        console.log(this.route.snapshot);
		const paymentId = this.route.snapshot.queryParamMap.get('paymentId');
		const amount = this.route.snapshot.queryParamMap.get('amount');
		const mandateStatus = this.route.snapshot.queryParamMap.get('mandateStatus');
        const mandateId = this.route.snapshot.queryParamMap.get('MandateId');

        // Read cookie to check if it's possible the payin straigh away
        const cookieValue = this.cookieService.get('payinAfterMandateCreation');
        // Delete the cookie to avoid another execution
        this.cookieService.delete('payinAfterMandateCreation');

        if (
            !!paymentId && paymentId !== '' &&
            !!mandateStatus && mandateStatus === 'submitted' &&
            !!mandateId && mandateId !== '' &&
            !!amount && +amount > 0 &&
            !!cookieValue && cookieValue === paymentId
        ) {

            this.loadingService.show(this.translateService.instant('loadings.loading_sending_payment'));

            // wait for data loading
            firstValueFrom(this.backendService.loadBasicUserData())
                .then(() => {

                    this.gaService.gtag('event', 'payments_mandate_created', {
                        'event_label': 'Payments mandate created',
                        'event_category': 'payments'
                    });

                    this.backendService.postPayment(paymentId, +amount, PaymentMethods.Mandate , mandateId, undefined, this.idempotencyKey)
                        .then(() => {

                            this.gaService.gtag('event', 'payment_paid', {
                                'event_label': 'Payment paid',
                                'event_category': 'payments'
                            });
                            
                            setTimeout(() => {

                                this.toastService.success(
                                    this.translateService.instant('toasts.toast_success_payment_sent_desc'),
                                    this.translateService.instant('toasts.toast_success_payment_sent_title'),
                                    {
                                        ...AppConstants.TOAST_STD_CONFIG,
                                        timeout: 8000
                                    }
                                );
                                
                                this.router.navigate(['/payments/' + paymentId], { replaceUrl: true });
                                this.loadingService.hide();
                            }, 1000);
                        })
                        .catch(err => {
                            if(!err.error?.message?.includes("idempotent_creation_conflict")){
                                // TODO: show error-specific message
                                this.toastService.error(
                                    this.translateService.instant('toasts.toast_error_payments_setup_request_desc'),
                                    this.translateService.instant('toasts.toast_error_payments_setup_request_title'),
                                    {
                                        ...AppConstants.TOAST_STD_CONFIG,
                                        timeout: 8000
                                    }
                                );
                            }
                            this.idempotencyKey = uuidv4();

                            this.router.navigate(['/'], { replaceUrl: true });
                            this.loadingService.hide();
                        });
                })
                .catch(err => {

                    // TODO: show error-specific message
                    this.toastService.error(
                        this.translateService.instant('toasts.toast_error_payments_setup_request_desc'),
                        this.translateService.instant('toasts.toast_error_payments_setup_request_title'),
                        {
                            ...AppConstants.TOAST_STD_CONFIG,
                            timeout: 8000
                        }
                    );
                })

        } else {

			this.router.navigate(['/'], { replaceUrl: true });

			this.toastService.error(
				this.translateService.instant('toasts.toast_error_payments_setup_request_desc'),
                this.translateService.instant('toasts.toast_error_payments_setup_request_title'),
				{
					...AppConstants.TOAST_STD_CONFIG,
					timeout: 8000
				}
			);
		}
	}

}
