import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SnotifyService } from 'ng-snotify';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { startWith, Subject, takeUntil } from 'rxjs';
import { UserData } from 'src/app/models/common';
import { BackendService } from 'src/app/services/backend.service';
import { DeviceStateService } from 'src/app/services/device-state.service';
import { FileUploaderService } from 'src/app/services/fileUploaderService';
import { LoadingService } from 'src/app/services/loading.service';
import { AppConstants } from 'src/app/utils/app-constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-crop-picture-page',
  templateUrl: './crop-picture-page.component.html',
  styleUrls: ['./crop-picture-page.component.scss']
})
export class CropPicturePageComponent implements OnInit {

	userData: UserData | undefined;
	tenantNameSurname : string = "";
	isLoading : boolean = true;

	imageURL : string = "";
	extension : string = "";
	pendingCropImageEvent: ImageCroppedEvent | undefined;

	// Mobile
	mobile : boolean = false;


	private ngUnsubscribe = new Subject<void>();


	constructor(
        private router: Router,
		private route: ActivatedRoute,
		private readonly location: Location,
		private readonly deviceStateService: DeviceStateService,
        private readonly backendService: BackendService,
		private readonly loadingService: LoadingService,
		private readonly fileUploaderService: FileUploaderService,
		private readonly gaService: GoogleAnalyticsService,
		private readonly toastService: SnotifyService,
        private readonly translateService: TranslateService
	) {
		this.mobile = deviceStateService.isMobileResolution();

		const navigation = this.router.getCurrentNavigation();

		if (!!navigation && !!navigation.extras.state) {
			
			const pic = navigation.extras.state['picture'];
			const ext = navigation.extras.state['extension'];
			if (!!pic && !!ext) {
				this.imageURL = pic;
				this.extension = ext;
			} else {
				this.backClick();
			}
		} else {
			this.backClick();
		}
	}

	ngOnInit(): void {

		this.deviceStateService.screenName = 'menu_title_account_settings';

		this.backendService.loadBasicUserData()
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(userData => {

				this.userData = userData;
                this.tenantNameSurname = userData.name + " " + userData.surname;
                
                this.isLoading = false;
                this.loadingService.hide();
            });

        this.deviceStateService.screenResized
			.pipe(
				startWith(this.mobile),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(isMobile => this.mobile = isMobile);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	backClick () {
		this.location.back();
	}

	async okClick() {

		if (!!this.pendingCropImageEvent && !!this.pendingCropImageEvent.base64) {

			this.loadingService.show(this.translateService.instant('loadings.com_uploading_image'));

			const data : string = await this.fileUploaderService.uploadPropfilePic(this.pendingCropImageEvent.base64, this.extension);

			if (!!this.userData) {
				this.userData.photo = data;

				await this.backendService.editBasicUserData(this.userData);

				this.gaService.gtag('event', 'profile_picture_changed', {
					'event_label': 'Profile picture changed',
					'event_category': 'settings'
				});

				const base64 = await this.getBase64ImageFromUrl(this.userData.photo);

				this.toastService.success(
                    this.translateService.instant('toasts.toast_success_picture_changed_desc'),
                    this.translateService.instant('toasts.toast_success_picture_changed_title'),
					AppConstants.TOAST_STD_CONFIG
				);
			}

			this.loadingService.hide();
			this.location.back();
		}

		this.location.back();
	}

	imageCropped(event: ImageCroppedEvent) {
		this.pendingCropImageEvent = event;
	}

	async getBase64ImageFromUrl(imageUrl : string) : Promise<string> {

        let fetchUrl : string = "";

        if (imageUrl.indexOf('https://firebasestorage.googleapis.com') > -1) {
			fetchUrl = imageUrl.replace(
				'https://firebasestorage.googleapis.com',
				`${environment.services.picsProxy}`
			);
		} else {
			fetchUrl = imageUrl;
		}

        var res = await fetch(fetchUrl);
        var blob = await res.blob();
      
        return new Promise((resolve, reject) => {
            var reader  = new FileReader();
            reader.addEventListener("load", function () {
                resolve(reader.result as string);
            }, false);

            reader.onerror = () => {
                return reject(this);
            };
            reader.readAsDataURL(blob);
        })
    }
}
