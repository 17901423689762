<div *ngIf="!mobile" class="chat-header" fxLayout="row" fxLayoutAlign="flex-start center">
    <p class="semi-bold-15 color-1 no-margin">{{ landlordName }}</p>
</div>

<div *ngIf="!mobile && thereIsUnits" class="view-unit-container">
    <div (click)="redirectToRYN()" class="view-unit">
        <p [matTooltip]="redirectRynTooltip()" class="no-margin">{{ 'chat_view_unit' | translate }}</p>
        <img [matTooltip]="redirectRynTooltip()" src="./assets/img/new-window.svg" alt=" select chat" style="width: 20px; height: 20px;">
    </div>
</div>


<input type="file" accept=".jpg,.jpeg,.png,.pdf" multiple (change)="picturesSelected($event)" #file style="display: none" />

<div #scrollMe detect-scroll (onScroll)="handleScroll($event)" [bottomOffset]="200" [topOffset]="200"
    [ngStyle]="{
        height: 'calc(var(--app-height) - ' + (mobile ? ((thereIsUnits ? 136 : 100) + footerHeight) : (335 + footerHeight)) + 'px)'
    }" style="overflow-y: scroll; background-color: white;">

    <div *ngIf="loadingMessages" style="position: relative; z-index: 1" [ngStyle]="{
            height: 'calc(var(--app-height) - ' + (mobile ? ((thereIsUnits ? 136 : 100) + footerHeight) : (335 + footerHeight)) + 'px)'
        }">
        <ngx-spinner name="spinner1" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
            <p style="margin-top: 80px">{{ 'chat_loading' | translate }}</p>
        </ngx-spinner>
    </div>

    <div fxLayout="row" *ngFor="let m of messagesToShow; trackBy: trackByFn">
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="flex-start center" class="msg-row">
            <div *ngIf="isChatMessageSentByMe(m); else sentMsg" fxFlex fxLayoutAlign="flex-start flex-start">
                <div class="msg-bubble-received" [ngStyle]="{
                        'background-color': mobile ? '#D8D8D8' : '#EDEDED',
                        'max-width': mobile ? '75%' : '60%'
                    }">
                    <div fxLayout="row" fxLayoutAlign="flex-start flex-start">
                        <p class="semi-bold-15 color-orange">{{ m.senderName }}</p>
                    </div>

                    <a *ngIf="!!m.url && m.url !== ''; else noLink" [href]="m.url" target="_blank" class="regular-15"
                        style="margin: 0 0 5px 0">{{ m.message }}</a>
                    <ng-template #noLink>
                        <p class="regular-15" style="margin: 0 0 5px 0" [innerHTML]="m.message | linkify"></p>
                    </ng-template>

                    <div fxLayout="row" fxLayoutAlign="flex-end flex-end">
                        <span class="regular-12 color-1 msg-time">{{ m.sentDateTime | dfnsFormat: 'dd/MM/yyyy,HH:mm'}}</span>
                    </div>
                </div>
            </div>
            <ng-template #sentMsg>
                <div fxFlex fxLayoutAlign="end flex-start">
                    <div class="msg-bubble-sent" [ngStyle]="{ 'max-width': mobile ? '75%' : '60%' }">
                        <a *ngIf="!!m.url && m.url !== ''; else noLink" [href]="m.url" target="_blank"
                            class="regular-15 color-white">{{ m.message }}</a>
                        <ng-template #noLink>
                            <p class="regular-15 color-white" style="margin: 0 0 5px 0"
                                [innerHTML]="m.message | linkify"></p>
                        </ng-template>

                        <div fxLayout="row" fxLayoutAlign="flex-start flex-end">
                            <span class="regular-12 color-white msg-time">{{ m.sentDateTime | dfnsFormat: 'dd/MM/yyyy,HH:mm'}}</span>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>

    <div *ngIf="messagesToShow.length === 0" fxLayout="column" fxLayoutAlign="center center" style="margin-top: 30px;">
        <img src="../../assets/img/RYN-logo.svg" />

        <p class="regular-15 text-center" style="margin-top: 25px; width: 90%;" [innerHTML]="'emptyScreens.empty_private_chat' | translate:{tenantNameSurname: tenantNameSurname}">
        </p>
    </div>
</div>

<div class="chat-footer" fxLayout="row" [ngClass]="{'chat-footer-mobile': mobile}">
    <div fxFlex fxLayoutAlign="center center" class="chat-type">
        <div class="type-container" fxFlex>
            <textarea #inputElement maxlength="2000" type="text" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" [formControl]="typeFormControl"
                [placeholder]="'chat_textfield_placeholder' | translate" autocomplete="off"
                (keyup.enter)="onSendClick()"></textarea>
        </div>

        <div style="min-width: 40px">
            <img *ngIf="!mobile || typeFormControl.value.length === 0"
                [src]="mobile ? './assets/img/rm_icon_chat_attach.svg' : './assets/img/attachment_blue.svg'"
                (click)="onAttachClick()" class="img-attach clickable" [matTooltip]="'com_attach_file' | translate" />

            <img *ngIf="!mobile || typeFormControl.value.length > 0"
                [src]="mobile ? './assets/img/rm_icon_chat_send.svg' : './assets/img/send_message_blue.svg'"
                [ngStyle]="{ 'margin-left': mobile ? '10px' : '0px' }" (click)="onSendClick()"
                class="img-send clickable" />
        </div>
    </div>
</div>