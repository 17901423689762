import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { startWith, Subject, takeUntil } from 'rxjs';
import { DeviceStateService } from 'src/app/services/device-state.service';
import { AttachedFile } from '../../models/fileRm';

@Component({
	selector: 'app-file-picker',
	templateUrl: './file-picker.component.html',
	styleUrls: ['./file-picker.component.scss']
})
export class FilePickerComponent implements OnInit, OnDestroy {
	
    @Input() files: AttachedFile[] = [];

    @Input() showUploadTime: boolean = true;
    @Input() allowLink: boolean = true;
    
    @Input() allowEdit: boolean = false;

    @Output() filesChanged: EventEmitter<AttachedFile[]> = new EventEmitter<AttachedFile[]>();
    

    displayedColumns: string[] = [];

    // Mobile
    mobile : boolean = true;


    private ngUnsubscribe = new Subject<void>();
    

	constructor(
        private readonly deviceStateService: DeviceStateService
	) {
		this.mobile = deviceStateService.isMobileResolution();
	}

	ngOnInit(): void {

		this.deviceStateService.screenResized
			.pipe(
				startWith(this.mobile),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(isMobile => this.mobile = isMobile);

        let cols = ["name"];
        if (this.showUploadTime) {
            cols.push("creation_time");
        }
        if (this.allowEdit) {
            cols.push("action_remove");
        }

        this.displayedColumns = cols;
	}

    ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

    getFileImg (name: string) {

        let fName = "../../assets/img/rm_icon_doc_jpg.svg";

        if (name.indexOf(".pdf") >= 0) {
            fName = "../../assets/img/rm_icon_doc_pdf.svg";
        } else if (name.indexOf(".docx") >= 0 || name.indexOf(".doc") >= 0) {
            fName = "../../assets/img/rm_icon_doc_docx.svg";
        }
        // else jpg jpeg png + FALLBACK

        return fName;
    }

    openFile (urlStr : string) {
        if (urlStr !== '' && this.allowLink) {
            window.open(urlStr, "_blank");
        }
    }

    deleteFile (indx: number) {
        this.files.splice(indx, 1);
        this.filesChanged.emit(this.files);
    }
}
