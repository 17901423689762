import { Component, OnInit } from '@angular/core';
import { ScrollEvent } from 'ngx-scroll-event';
import { NgxSpinnerService } from 'ngx-spinner';
import { startWith, Subject, switchMap, takeUntil, take } from 'rxjs';
import { AttachedFile } from 'src/app/models/fileRm';
import { ChatMessage } from 'src/app/models/message';
import { BackendService } from 'src/app/services/backend.service';
import { DeviceStateService } from 'src/app/services/device-state.service';
import { LoadingService } from 'src/app/services/loading.service';
import { AppConstants } from 'src/app/utils/app-constants';

@Component({
  selector: 'app-communications-chat',
  templateUrl: './communications-chat.component.html',
  styleUrls: ['./communications-chat.component.scss'],
})
export class CommunicationsChatComponent implements OnInit {
  
	tenantId: string = '';
	landlordName: string = '';
	tenantNameSurname: string = '';

	communications: ChatMessage[] = [];
	communicationFiles: { [key: string]: AttachedFile } = {};

	totalMessagesNumber : number = 0;
	totalPagesLoaded : number = 0;

	isLoading = false;
	loadingMessages = true;

	footerHeight = 70;

	unreadCount: number = 0;

	// Mobile
	mobile: boolean = false;

	private ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly deviceStateService: DeviceStateService,
    private readonly spinner: NgxSpinnerService,
    private readonly backendService: BackendService,
    private readonly loadingService: LoadingService
  ) {
    this.mobile = deviceStateService.isMobileResolution();
  }

	ngOnInit(): void {
		this.deviceStateService.screenName = 'menu_title_chat';

		this.deviceStateService.screenResized
			.pipe(startWith(this.mobile), takeUntil(this.ngUnsubscribe))
			.subscribe((isMobile) => (this.mobile = isMobile));

		this.isLoading = true;
		this.loadingMessages = true;

		this.spinner.show('spinner1', AppConstants.SPINNER_ELEMENT_CONFIG);

		this.backendService
			.loadBasicUserData()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((userData) => {
			this.tenantNameSurname = userData.name + ' ' + userData.surname;
			});

		this.backendService
			.loadTenantLandlordData()
			.pipe(
				takeUntil(this.ngUnsubscribe),
				switchMap(data => {
					this.tenantId = data.id;
					return this.backendService.getAllCommunicationsPaginated(0);
				}),
				take(1)
			)
			.subscribe(queryResult => {
				
				this.communications = queryResult.data;

				this.totalMessagesNumber = queryResult.metadata.totalItems || queryResult.data.length;
				this.totalPagesLoaded = 1;

				this.communications.forEach((m) => {
					if (m.url) {
						this.communicationFiles[m.id] = this.getFileFromMessage(m);
					}
				});

				this.isLoading = false;
				this.loadingMessages = false;
				this.loadingService.hide();
			});

		this.backendService.landlordName$
			.pipe(startWith(''), takeUntil(this.ngUnsubscribe))
			.subscribe((landlordName) => {
				this.landlordName = landlordName;
			});

		this.backendService
			.getCurrentCounters()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((counters) => {
				if (!!counters['communications']) {
					this.unreadCount = counters['communications'] || 0;
				}

				this.backendService.resetCounter('communications');
			});
	}

	private getFileFromMessage(m: ChatMessage): AttachedFile {
		return new AttachedFile(
			m.message,
			0, // No size
			m.url,
			m.sentDateTime
		);
	}


	handleScroll(event: ScrollEvent) {

		if (event.isReachingBottom) {
			this.addOtherMessages();
		}
	}

	private addOtherMessages() {
    
		if (this.communications.length < this.totalMessagesNumber && !this.isLoading) {
			console.log('Add other messages');
	
			this.isLoading = true;
	
			this.backendService
				.getAllCommunicationsPaginated(this.totalPagesLoaded)
				.pipe(take(1))
				.subscribe(messagesQueryResult => {
					const msgsIds = this.communications.map(communication => communication.id);
					this.communications = this.communications.concat(
						messagesQueryResult.data.filter(message => !msgsIds.includes(message.id))
					);
	
					this.totalMessagesNumber = messagesQueryResult.metadata.totalItems || messagesQueryResult.data.length;
					this.totalPagesLoaded += 1;
	
					this.isLoading = false;
				});
		}
	  }
}
