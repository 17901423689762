<div class="maintenance-card">
    <div fxLayout="row" fxLayoutAlign="flex-start center" style="width: 100%;">
        <div fxFlex="65%" fxLayout="column" fxLayoutAlign="flex-start flex-start">
            <p class="semi-bold-10 maintenance-card-p-margin">{{ 'com_up_title' | translate }}</p>
            <p class="regular-12">{{ maintenance?.title }}</p>
        </div>
        <div fxFlex="35%" fxLayout="column" fxLayoutAlign="flex-start flex-start">
            <p class="semi-bold-10 maintenance-card-p-margin">{{ 'com_up_status' | translate }}</p>
            
            <div *ngIf="maintenance?.status === 0" class="rm-tag-view tag-primary">
                <p class="semi-bold-12 color-white">{{ 'maintenance_open_one' | translate }}</p>
            </div>
            <div *ngIf="maintenance?.status === 1" class="rm-tag-view tag-green">
                <p class="semi-bold-12 color-white">{{ 'maintenance_closed_one' | translate }}</p>
            </div>
        </div>
    </div>
    
    <div fxLayout="row" fxLayoutAlign="flex-start center" style="width: 100%; margin-top: 15px;">
        <div fxFlex="65%" fxLayout="column" fxLayoutAlign="flex-start flex-start">
            <p class="semi-bold-10 maintenance-card-p-margin">{{ 'com_up_category' | translate }}</p>
            <p class="regular-12 no-margin-bottom">{{ getCategoryName() }}</p>
        </div>
        <div fxFlex="35%" fxLayout="column" fxLayoutAlign="flex-start flex-start">
            <p class="semi-bold-10 maintenance-card-p-margin">{{ 'com_up_attachments' | translate }}</p>

            <p *ngIf="hasAttachments(); else noAttachments" class="regular-12 no-margin">{{ 'com_yes' | translate }}</p>
            <ng-template #noAttachments>
                <p class="semi-bold-12 no-margin">-</p>
            </ng-template>
            
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%; margin-top: 15px;">
        <button mat-button class="button-primary button-small" (click)="viewMaintenanceClick()">{{ 'com_view' | translate }}</button>
    </div>
</div>