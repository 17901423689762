import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, startWith, Subject, takeUntil } from 'rxjs';
import { DeviceStateService } from '../services/device-state.service';
import { UnitInfo } from "../models/properties";
import { BackendService } from "../services/backend.service";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {

	currentPageIndex: number = 0;

	tabs = [ 'com_landlord_chat', 'com_communications' ];

	// Mobile
	mobile: boolean = false;

	landlordId: string = '';
	units: UnitInfo[] = [];

	unitLease = {} as UnitInfo;
	unit = {} as UnitInfo;
	unitIdParam: string = '';
	unitId: string = '';
	thereIsUnit = false;

	private ngUnsubscribe = new Subject<void>();

	constructor(
		private readonly route: ActivatedRoute,
		private readonly location: Location,
		private readonly deviceStateService: DeviceStateService,
		private readonly backendService: BackendService,
		private readonly translateService: TranslateService,
	) {
		this.mobile = deviceStateService.isMobileResolution();
	}

	ngOnInit(): void {
		this.route.queryParams.subscribe(params => {
			this.unitIdParam = params['unitId'] || '';
		});

		this.deviceStateService.screenName = 'menu_title_chat';

		this.deviceStateService.screenResized
			.pipe(startWith(this.mobile), takeUntil(this.ngUnsubscribe))
			.subscribe((isMobile) => (this.mobile = isMobile));

		this.backendService.landlordId$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((landlordId) => this.landlordId = landlordId);

		combineLatest([
			this.backendService.loadBasicUserData(),
			this.backendService.loadTenantLandlordData()
		])
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(([userData, tenantLandlordData]) => {
				const lease = tenantLandlordData.currentLease
				const unitEstelle = tenantLandlordData.currentUnit;
				const property = tenantLandlordData.currentProperty;

				if (lease && lease.unitId && unitEstelle && property) {
					this.unitLease = {
						id: lease.unitId,
						name: unitEstelle.name,
						address: property.address || '',
						country: property.country || '',
						houseNumber: property.houseNumber || '',
						photo: unitEstelle.picturesData[0].data || '../../assets/img/pic_upload_placeholder.png',
						landlordId: this.landlordId
					};
				}

				if (userData.units && userData.units.length > 0) {
					if (this.unitIdParam === '') {
						this.units = userData.units.map((unit) => {
							return {
								...unit,
								photo: unit.photo || '../../assets/img/pic_upload_placeholder.png',
							}
						});
						const hasUnitLease = this.units.some(unit => unit.id === this.unitLease.id);
						if(!hasUnitLease && this.unitLease.id) {
							this.units.unshift(this.unitLease);
						}
						this.unit = this.units[0];
						this.unitId = this.unit.id;
					} else {
						this.unitId = this.unitIdParam;
						this.units = userData.units;
						const index = this.units.findIndex(element => element.id === this.unitId);
						if (index > 0) {
							const [element] = this.units.splice(index, 1);
							this.units.unshift(element);
						}
						const hasUnitLease = this.units.some(unit => unit.id === this.unitLease.id);
						if(!hasUnitLease && this.unitLease.id) {
							this.units.splice(1, 0, this.unitLease);
						}
						this.unit = this.units[0];
					}
				} else {
					this.units = [];
				}
			});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	backClick() {
		this.location.back();
	}

	onTabClicked(indx: any) {
		this.currentPageIndex = indx;
	}

	unitChange(unit: UnitInfo) {
		this.unit = unit;
		this.unitId = unit.id;
		this.thereIsUnit = true;
	}

	sentUnitId(unitId: string) {
		const index = this.units.findIndex(element => element.id === unitId);
		if (index > 0) {
			const [element] = this.units.splice(index, 1);
			this.units.unshift(element);
		}
	}

	returnToConversations() {
		this.thereIsUnit = false;
	}

	redirectToRYN() {
    window.open(`${environment.rentYourNest.baseURL}?unitId=${this.unitId}&lang=${this.translateService.currentLang}`, '_blank');
	}
}
