<div fxLayout="column" style="max-width: 720px">
	<div [formGroup]="mainInfoForm" fxLayout="column">
		<div fxFlex flexLayout="column" *ngIf="showPersonType" style="margin-top: 10px;">
			<mat-radio-group
				fxLayout="row"
				formControlName="type"
				style="margin-top: 0px; margin-bottom: 15px; width: 50%;"
			>
				<mat-radio-button fxFlex class="regular-15 color-grey" [value]="'natural'">
					{{ 'inv_choice_natural' | translate }}</mat-radio-button
				>
				<mat-radio-button
					fxFlex
					class="regular-15 color-grey"
					[value]="'legal'"
					style="margin-left: 10px"
				>
                    {{ 'inv_choice_company' | translate }}
                </mat-radio-button>
			</mat-radio-group>
		</div>

		<div fxLayout="row wrap" fxLayoutGap="20px">
			<mat-form-field
				*ngIf="mainInfoForm.controls['type'].value !== 'natural'"
				class="dash-form-field-add"
				appearance="outline"
				fxFlex="200px"
			>
				<mat-label>{{ 'invoice_legal_business_name' | translate }}</mat-label>
				<input type="text" matInput formControlName="businessName" maxlength="50" autocomplete="off" />
			</mat-form-field>

			<mat-form-field
				*ngIf="mainInfoForm.controls['type'].value !== 'natural'"
				class="dash-form-field-add"
				appearance="outline"
				fxFlex="200px"
			>
				<mat-label>{{ 'com_unique_code' | translate }}</mat-label>
				<input type="text" matInput formControlName="uniqueCode" maxlength="20" autocomplete="off" />
			</mat-form-field>

			<mat-form-field class="dash-form-field-add" appearance="outline" fxFlex="200px">
				<mat-label *ngIf="mainInfoForm.controls['type'].value !== 'natural'">{{ 'invoice_legal_tax_code' | translate }}</mat-label
				>
				<mat-label *ngIf="mainInfoForm.controls['type'].value === 'natural'">{{ 'invoice_natural_tax_code' | translate }}</mat-label
				>
				<input type="text" matInput formControlName="taxCode" maxlength="20" autocomplete="off" />
			</mat-form-field>
		</div>
	</div>

	<app-address-form
		#address_selector
		style="max-width: 700px"
		[required]="this.showMissing"
		[inputAddressData]="invoiceData"
		(addressDataChange)="onAddressUpdated($event)"
	></app-address-form>
</div>
