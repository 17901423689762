<div *ngIf="!isLoading" fxLayout="row" fxLayoutAlign="center flex-start">

    <div fxLayout="column" fxLayoutAlign="flex-start center" class="maintenance-main-content" [ngClass]="{'maintenance-main-content-desk': !mobile, 'maintenance-main-content-mobile': mobile }">

        <div *ngIf="!mobile" fxLayout="row" fxLayoutAlign="flex-start center" class="top-row">
            <img class="img-back" src="../../assets/img/rm_icon_arrow_left.svg" (click)="backClick()" />
            <p class="regular-20 no-margin" [innerHTML]="'screenTitles.maintenance_title' | translate"></p>
        </div>
        <div *ngIf="mobile" fxLayout="row" fxLayoutAlign="flex-start center" class="top-row-mobile">
            <p class="regular-15" [innerHTML]="'screenTitles.maintenance_title_2' | translate"></p>
        </div>

        <div *ngIf="!mobile" class="page-inner-content page-inner-content-desk">
            <div fxLayout="column" fxLayoutAlign="flex-start flex-start" style="width: 100%;">
                <div fxLayout="row" fxLayoutAlign="flex-start flex-end" style="width: 100%;">
                    <p class="semi-bold-15 no-margin">{{ 'com_details' | translate }}</p>
                </div>
                <div class="sep-row-light"></div>
            </div>

            <mat-card class="no-padding" *ngIf="!!maintenance" style="margin-bottom: 20px;">
                <table mat-table [dataSource]="[maintenance]" style="z-index: 10">
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>{{ 'com_up_status' | translate }}</th>
                        <td mat-cell *matCellDef="let m" style="white-space: nowrap">
                            <div *ngIf="m.status === 0" class="rm-tag-view tag-primary">
                                <p class="semi-bold-12 color-white">{{ 'maintenance_open_one' | translate }}</p>
                            </div>
                            <div *ngIf="m.status === 1" class="rm-tag-view tag-green">
                                <p class="semi-bold-12 color-white">{{ 'maintenance_closed_one' | translate }}</p>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef>{{ 'com_up_title' | translate }}</th>
                        <td mat-cell *matCellDef="let m" style="white-space: nowrap">
                            <p class="regular-12 no-margin">{{ m.title }}</p>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="category">
                        <th mat-header-cell *matHeaderCellDef>{{ 'com_up_category' | translate }}</th>
                        <td mat-cell *matCellDef="let m" style="white-space: nowrap">
                            <p class="regular-12 no-margin">{{ getCategoryName() }}</p>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="enteredBy">
                        <th mat-header-cell *matHeaderCellDef>{{ 'com_up_entered_by' | translate }}</th>
                        <td mat-cell *matCellDef="let m" style="white-space: nowrap">
                            <p *ngIf="m.tenantId === tenantId; else notYou" class="regular-12 no-margin">{{ 'com_you' | translate }}</p>
                            <ng-template #notYou>
                                <p *ngIf="m.tenantId === ''; else notLandlord" class="regular-12 no-margin">{{ 'com_landlord' | translate }}</p>
                                <ng-template #notLandlord>
                                    <p class="regular-12 no-margin">{{ 'com_other' | translate }}</p>
                                </ng-template>
                            </ng-template>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="enteredOn">
                        <th mat-header-cell *matHeaderCellDef>{{ 'com_up_entered_on' | translate }}</th>
                        <td mat-cell *matCellDef="let m" style="white-space: nowrap">
                            <p class="regular-12 no-margin">{{ m.reportedOn | dfnsFormat: 'dd/MM/yyyy' }}</p>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"
                    ></tr>
                </table>
            </mat-card>

            <div *ngIf="!!maintenance && maintenance.message !== ''" style="width: calc(100% - 20px); margin-bottom: 30px;" class="maintenance-desc-container">
                <p class="regular-12 no-margin">{{ maintenance?.message }}</p>
            </div>

            <div *ngIf="photosAndFilesTogether.length > 0" fxLayout="column" fxLayoutAlign="flex-start flex-start" style="width: 100%; margin-bottom: 40px;">
                <p class="semi-bold-15 no-margin">{{ 'com_attached_files' | translate }}</p>
                <div class="sep-row-light"></div>

                <app-file-picker [files]="photosAndFilesTogether" [showUploadTime]="false"></app-file-picker>
            </div>
        </div>



        <div *ngIf="mobile" class="page-inner-content page-inner-content-mobile">

            <div fxLayout="column" fxLayoutAlign="flex-start flex-start" style="width: 100%;">
                <div fxLayout="column" fxLayoutAlign="flex-start flex-start" style="width: 100%;">
                    <div fxLayout="row" fxLayoutAlign="flex-start flex-end" style="width: 100%;">
                        <p class="semi-bold-15 no-margin">{{ 'com_details' | translate }}</p>
                    </div>
                    <div class="sep-row-light"></div>
                </div>


                <app-dash-label-simple fxFlex="100%" [label]="'com_title' | translate">{{ maintenance?.title }}</app-dash-label-simple>
                <app-dash-label-simple fxFlex="100%" [label]="'com_message' | translate" class="v-spacing-20" [multiline]="true">{{ maintenance?.message }}</app-dash-label-simple>
                <app-dash-label-simple fxFlex="100%" [label]="'com_category' | translate" class="v-spacing-20">{{ getCategoryName() }}</app-dash-label-simple>                
                
                <p class="semi-bold-12 color-grey v-spacing-20" style="margin-bottom: 5px; font-weight: 600;">{{ 'com_status' | translate }}</p>
                <div style="width: min-content;">
                    <div *ngIf="maintenance?.status === 0" class="rm-tag-view tag-primary">
                        <p class="semi-bold-12 color-white">{{ 'maintenance_open_one' | translate }}</p>
                    </div>
                    <div *ngIf="maintenance?.status === 1" class="rm-tag-view tag-green">
                        <p class="semi-bold-12 color-white">{{ 'maintenance_closed_one' | translate }}</p>
                    </div>
                </div>

                <app-dash-label-simple *ngIf="maintenance?.tenantId === tenantId" fxFlex="100%" [label]="'com_entered_by' | translate" class="v-spacing-20">{{ 'com_you' | translate }}</app-dash-label-simple>
                <app-dash-label-simple *ngIf="maintenance?.tenantId === ''" fxFlex="100%" [label]="'com_entered_by' | translate" class="v-spacing-20">{{ 'com_landlord' | translate }}</app-dash-label-simple>
                <app-dash-label-simple *ngIf="maintenance?.tenantId !== tenantId && maintenance?.tenantId !== ''" fxFlex="100%" [label]="'com_entered_by' | translate" class="v-spacing-20">{{ 'com_other' | translate }}</app-dash-label-simple>

                <app-dash-label-simple fxFlex="100%" [label]="'com_entered_on' | translate" class="v-spacing-20">{{ maintenance?.reportedOn | dfnsFormat: 'dd/MM/yyyy' }}</app-dash-label-simple>


                <div *ngIf="photosAndFilesTogether.length > 0" fxLayout="column" fxLayoutAlign="flex-start flex-start" style="width: 100%; margin: 30px 0 40px 0;">
                    <p class="semi-bold-15 no-margin">{{ 'com_attached_files' | translate }}</p>
                    <div class="sep-row-light"></div>

                    <app-file-picker [files]="photosAndFilesTogether" [showUploadTime]="false"></app-file-picker>
                </div>
            </div>
        </div>
    </div>
</div>