import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentOperation } from 'src/app/models/payments';

@Component({
  selector: 'app-mobile-payment-operation-card',
  templateUrl: './mobile-payment-operation-card.component.html',
  styleUrls: ['./mobile-payment-operation-card.component.scss']
})
export class MobilePaymentOperationCardComponent {

	@Input() operation : PaymentOperation | undefined;
  @Output() on3DSLinkPressedEvent = new EventEmitter();
	
	constructor() { }

  on3DSLinkPressed(){
    this.on3DSLinkPressedEvent.emit();
  }

}

