import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentDetails, PaymentMethods, PaymentProviderRecurringPaymentStatus } from '../models/payments';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateService } from '@ngx-translate/core';

export interface PaymentData {
	title: string,
	amount: number,
	currency: string
}
@Component({
  selector: 'app-confirm-payments-notice-dialog',
  templateUrl: './confirm-payment-notice-dialog.component.html',
  styleUrls: ['./confirm-payment-notice-dialog.component.scss']
})
export class ConfirmPaymentNoticeDialogComponent implements OnInit {
	isAcknowledged: boolean =false;

	paymentMethods = PaymentMethods
	paymentMethod: PaymentMethods = undefined as any

	paymentProviderRecurringPaymentStatus = PaymentProviderRecurringPaymentStatus
	recurringStatus: PaymentProviderRecurringPaymentStatus = PaymentProviderRecurringPaymentStatus.INVALID

	payment: PaymentData = {
		title: "",
		amount: 0,
		currency: 'EUR'
	};

	paymentDetails: PaymentDetails ={
		feesAmount: 0,
		netFeeAmount: 0,
		totalAmount: 0,
		vatRate: 0,
		feesType: 'tenant',
		feeRate: 0
	};

	platformFee = 0;
	mobile = true;

	tooltipbutton = '';

	constructor(
		private readonly translateService: TranslateService,
		public dialogRef: MatDialogRef<ConfirmPaymentNoticeDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			paymentMethod: PaymentMethods,
			payment: PaymentData,
			paymentDetails: PaymentDetails,
			mobile: boolean,
			recurringStatus: PaymentProviderRecurringPaymentStatus
		}
	) {
		this.paymentMethod = data.paymentMethod
		this.recurringStatus = data.recurringStatus
		this.payment = {
			...data.payment,
			amount: data.payment.amount? data.payment.amount : 0,
			currency: data.payment.currency? data.payment.currency :'EUR',
		}

		this.paymentDetails = data.paymentDetails;
		this.platformFee = this.paymentDetails.feesAmount - this.paymentDetails.netFeeAmount;
		
		if(this.platformFee === 0 || this.paymentMethod === this.paymentMethods.Mandate){
			this.isAcknowledged = true;
			this.tooltipbutton = '';
		}else {
			this.tooltipbutton = this.translateService.instant('credit_card_waiting_acknowledge_tooltip');
		}

		this.mobile = data.mobile;
	}

	ngOnInit() {}

	click(){
		console.log(true);
	}

	cancelClick() {
		this.dialogRef.close({ action: 'stop' });
	}

	proceedClick() {
		this.dialogRef.close({ action: 'continue' });
	}

	isChecked(){
		this.isAcknowledged = !this.isAcknowledged;
	}
}
