<div *ngIf="!isLoading" fxLayout="row" fxLayoutAlign="center flex-start">

    <div fxLayout="column" fxLayoutAlign="flex-start center" class="user-not-linked-main-content" [ngClass]="{'user-not-linked-main-content-desk': !mobile, 'user-not-linked-main-content-mobile': mobile }">

        <div *ngIf="!isGodUser" [ngClass]="{'page-inner-content-desk': !mobile, 'page-inner-content-mobile': mobile}" fxLayout="column" fxLayoutAlign="center center">
            
            <img class="empty-image" src="../../assets/img/RYN-logo.svg" />
            
            <p [innerHTML]="'com_hi_x' | translate:{tenantNameSurname: tenantNameSurname}" [ngClass]="{'regular-20': !mobile, 'regular-15': mobile }">
            </p>

            <p class="regular-15 text-center" style="margin: 0 0 20px 0;" [innerHTML]="'missingLandlordInfoDesc' | translate">
            </p>

            <div fxLayout="row wrap" fxLayoutAlign="center center" style="width: 100%;" class="v-spacing-20">
                    
                <div [fxFlex]="mobile ? '100%' : '200px'" class="field-margin">
                    <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                        <mat-label>{{ 'com_email_or_phone' | translate }}</mat-label>
                        <input
                            type="text"
                            matInput
                            [placeholder]="'com_email_or_phone' | translate"
                            [formControl]="contact"
                            autocomplete="off"
                        />
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="center flex-start" style="width: 100%;" class="v-spacing-20">
                <button mat-button class="button-positive button-small" (click)="send()">{{ 'com_send' | translate }}</button>
            </div>

        </div>



        <!-- God user -->
        <div *ngIf="isGodUser" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" style="width: 100%; margin-top: 50px;" class="info-row">
            <mat-form-field appearance="outline" class="field-full-width no-padding-bottom">
                <mat-label>User ID</mat-label>
                <input
                    type="text"
                    matInput
                    placeholder="User ID"
                    [formControl]="godUserID"
                />
            </mat-form-field>
            <button
                fxFlex="100px"
                mat-button
                class="button-positive button-small"
                (click)="gouUserSwitch()"
            >Switch</button>
        </div>
    </div>    
</div>