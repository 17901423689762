import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SnotifyService } from 'ng-snotify';
import { startWith, Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { DeviceStateService } from 'src/app/services/device-state.service';
import { LoadingService } from 'src/app/services/loading.service';
import { AppConstants } from 'src/app/utils/app-constants';

@Component({
  selector: 'app-user-not-linked',
  templateUrl: './user-not-linked.component.html',
  styleUrls: ['./user-not-linked.component.scss']
})
export class UserNotLinkedComponent implements OnInit {

    isLoading : boolean = true;
	tenantNameSurname : string = "";

    contact = new FormControl('', [Validators.required]);

	// Mobile
	mobile : boolean = false;


	private ngUnsubscribe = new Subject<void>();


    // God mode

    isGodUser: boolean = true;
    godUserID = new FormControl('', [Validators.required]);


	constructor(
        private readonly router: Router,
		private readonly deviceStateService: DeviceStateService,
        private readonly translateService: TranslateService,
        private readonly loadingService: LoadingService,
		private readonly backendService: BackendService,
        private readonly toastService: SnotifyService,
        private readonly authService: AuthService
	) {
		this.mobile = deviceStateService.isMobileResolution();
	}

	ngOnInit(): void {

		this.deviceStateService.screenName = 'dash_name_your_dashboard';
        this.loadingService.show(this.translateService.instant('loadings.loading_loading'));


		this.backendService.loadBasicUserData()
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(userData => {
                
                this.tenantNameSurname = userData.name + " " + userData.surname;

                this.isGodUser = this.authService.isGodUser();

                this.isLoading = false;
                this.loadingService.hide();
            });


		this.deviceStateService.screenResized
			.pipe(
				startWith(this.mobile),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(isMobile => this.mobile = isMobile);
    }

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

    send () {

        this.contact.markAsTouched();

        if (this.contact.valid) {

            this.loadingService.show(this.translateService.instant('loadings.contact_us_loading'));

            this.backendService.contactUs("Places: user not linked => REFERS", this.contact.value)
                .then(_ => {

                    this.toastService.success(
                        this.translateService.instant('toasts.toast_success_refer_landlord_desc'),
                        this.translateService.instant('toasts.toast_success_refer_landlord_title'),
                        {
                            ...AppConstants.TOAST_STD_CONFIG,
                            timeout: 4000
                        }
                    );
    
                    this.contact.setValue("");
                    this.contact.markAsUntouched();

                }).catch(err => {

                    this.toastService.error(
                        this.translateService.instant('toasts.toast_error_contact_us_desc'),
                        this.translateService.instant('toasts.toast_error_contact_us_title'),
                        AppConstants.TOAST_STD_CONFIG
                    );

                }).finally(() => {

                    this.loadingService.hide();
                });

        } else {

            this.toastService.error(
                this.translateService.instant('toasts.toast_error_refer_landlord_desc'),
                this.translateService.instant('toasts.toast_error_refer_landlord_title'),
                {
                    ...AppConstants.TOAST_STD_CONFIG,
                    timeout: 4000
                }
            );

        }
    }


    gouUserSwitch () {
        if (this.godUserID.valid) {

            this.authService.changeUserForGodUser(this.godUserID.value);

            setTimeout(() => {
                this.router.navigate(['/']);
            }, 2000);
        } else {
            console.log("Email not valid")
        }
    }
}
