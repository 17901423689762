<div style="width: 100%" [ngClass]="{ 'two-lines-container': isTwoLines }">
	<p class="detail-label" [ngClass]="{'mobile': mobile}" [ngStyle]="{ 'text-align': align }">{{ label }}</p>
	<p
		class="detail-content"
		[ngClass]="{
			'regular-15': !isBold,
			'semi-bold-15': isBold,
			'color-green': color === 'green',
			'color-red': color === 'red',
			'color-orange': color === 'orange',
			'color-1': color === 'blue',
			'color-priority-0': color === 'color-priority-0',
			'color-priority-1': color === 'color-priority-1',
			'color-priority-2': color === 'color-priority-2',
			'color-priority-3': color === 'color-priority-3',
			'label-multiline': multiline,
			'two-lines-text': isTwoLines
		}"
		[ngStyle]="{ 'text-align': align }"
	>
		<ng-content></ng-content>
	</p>
</div>
