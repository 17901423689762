<div *ngIf="!isLoading" fxLayout="row" fxLayoutAlign="center flex-start">

    <div fxLayout="column" fxLayoutAlign="flex-start center" class="crop-main-content" [ngClass]="{'crop-main-content-desk': !mobile, 'crop-main-content-mobile': mobile }">
        
        <div *ngIf="!mobile" fxLayout="row" fxLayoutAlign="flex-start center" class="top-row">
            <img class="img-back" src="../../assets/img/rm_icon_arrow_left.svg" (click)="backClick()" />
            <p class="regular-20 no-margin" [innerHTML]="'screenTitles.crop_pic_page_title_2' | translate">
            </p>
        </div>
        <div *ngIf="mobile" fxLayout="row" fxLayoutAlign="space-between center" class="top-row-mobile">
            <p class="regular-15 no-margin" [innerHTML]="'screenTitles.crop_pic_page_title_2' | translate">
            </p>
        </div>
        
        <div class="sep-row-light"></div>

        <div fxLayout="row" fxLayoutAlign="flex-start center">
            <p class="regular-12 no-margin" [innerHTML]="'crop_picture_popup_desc' | translate:{tenantNameSurname: tenantNameSurname}">
            </p>
        </div>

        <image-cropper
			style="max-height: 350px; margin: 20px 0"
			[imageURL]="imageURL"
			format="png"
			(imageCropped)="imageCropped($event)"
			[maintainAspectRatio]="false"
		></image-cropper>

        <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
            <button mat-button class="button-negative-empty button-small" (click)="backClick()">{{ 'com_cancel' | translate }}</button>
            <button mat-button class="button-positive button-small" (click)="okClick()">{{ 'com_save' | translate }}</button>
        </div>
    </div>
</div>
