import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { startWith, Subject, takeUntil } from 'rxjs';
import { BackendService } from '../services/backend.service';
import { LoadingService } from '../services/loading.service';
import { DeviceStateService } from '../services/device-state.service';
import { AppConstants } from '../utils/app-constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, OnDestroy {
	
	title = new FormControl('', [Validators.required]);
    description = new FormControl('', [Validators.required]);

	// Mobile
	mobile : boolean = false;

	private ngUnsubscribe = new Subject<void>();
	

    constructor(
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly location: Location,
        private readonly deviceStateService: DeviceStateService,
        private readonly toastService: SnotifyService,
        private readonly backendService: BackendService,
        private readonly loadingService: LoadingService,
        private readonly translateService: TranslateService
	) {
		this.mobile = deviceStateService.isMobileResolution();
	}

	ngOnInit(): void {

		this.deviceStateService.screenName = 'menu_title_contact_us';

		this.deviceStateService.screenResized
			.pipe(
				startWith(this.mobile),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(isMobile => this.mobile = isMobile);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	backClick () {
		this.location.back();
	}

    sendRequest () {
        
        this.title.markAsTouched();
        this.description.markAsTouched();

        if (!this.title.valid || !this.description.valid) {

            this.toastService.error(
                this.translateService.instant('toasts.toast_error_fields_desc'),
                this.translateService.instant('toasts.toast_error_fields_title'),
                AppConstants.TOAST_STD_CONFIG
            );
            return;
        }

        this.loadingService.show(this.translateService.instant('loadings.contact_us_loading'));


        this.backendService.contactUs(this.title.value, this.description.value)
            .then(_ => {

                this.toastService.success(
                    this.translateService.instant('toasts.toast_success_contact_us_desc'),
                    this.translateService.instant('toasts.toast_success_contact_us_title'),
                    AppConstants.TOAST_STD_CONFIG
                );
                
                this.router.navigate(['/'], { relativeTo: this.route });

            }).catch(err => {

                // TODO: show the right error message 

                this.toastService.error(
                    this.translateService.instant('toasts.toast_error_contact_us_desc'),
                    this.translateService.instant('toasts.toast_error_contact_us_title'),
                    AppConstants.TOAST_STD_CONFIG
                );

            }).finally(() => {

                this.loadingService.hide();
            });
    }
}
