<div *ngIf="!isLoading" fxLayout="column" style="min-height: 100%; padding-bottom: 40px;">
    <app-dropdown-list *ngIf="landlords.length > 1" [landlords]="landlords" [currentLandlordId]="currentLandlordId" (selectedLandlordId)="selectedLandlordId($event)"></app-dropdown-list>

    <div fxLayout="column" fxLayoutAlign="flex-start center" style="margin-top: 60px;">
        <img [ngClass]="{'home-top-img-desk': !mobile, 'home-top-img-mobile': mobile }" [src]="landlordLogo" />

        <p [innerHTML]="'com_hi_x' | translate:{tenantNameSurname: tenantNameSurname}" [ngClass]="{'home-tenant-name-desk': !mobile, 'home-tenant-name-mobile': mobile, 'regular-20': !mobile, 'regular-15': mobile }">
        </p>

        <p class="color-1 clickable" [ngClass]="{'regular-15': !mobile, 'regular-13': mobile }" (click)="settingsClick()">{{ 'home_account_settings' | translate }}</p>



        <div fxLayout="row wrap" fxLayoutAlign="flex-start flex-start" [ngClass]="{'home-tiles-desk': !mobile, 'home-tiles-mobile': mobile }">
            
            <div fxLayout="column" fxLayoutAlign="flex-start center">
                <div [ngClass]="{'home-tile-desk': !mobile, 'home-tile-mobile': mobile }" (click)="tileClick(1)" [matTooltip]="getFeatureLockedTooltip()">
                    <img *ngIf="leasePresent" [ngClass]="{'home-tile-img-desk': !mobile, 'home-tile-img-mobile': mobile }" src="../../assets/img/rm_icon_unit_info.svg" />
                    <img *ngIf="!leasePresent" [ngClass]="{'home-tile-img-desk': !mobile, 'home-tile-img-mobile': mobile }" src="../../assets/img/rm_icon_unit_info_grey.svg" />
                    <div *ngIf="!!counters['unit'] && counters['unit'] > 0" class="badge"><p class="regular-15 no-margin color-white">{{ counters['unit'] }}</p></div>
                </div>
                <p [ngClass]="{'regular-15': !mobile, 'regular-13': mobile, 'home-tile-desc-desk': !mobile, 'home-tile-desc-mobile': mobile }">{{ 'tiles.home_tile_unit_info' | translate }}</p>
            </div>

            <div fxLayout="column" fxLayoutAlign="flex-start center">

                <div [ngClass]="{'home-tile-desk': !mobile, 'home-tile-mobile': mobile }" (click)="tileClick(2)">
                    <img [ngClass]="{'home-tile-img-desk': !mobile, 'home-tile-img-mobile': mobile }" src="../../assets/img/rm_icon_payments.svg" />
                    <div *ngIf="!!counters['payments'] && counters['payments'] > 0" class="badge"><p class="regular-15 no-margin color-white">{{ counters['payments'] }}</p></div>
                </div>
                <p [ngClass]="{'regular-15': !mobile, 'regular-13': mobile, 'home-tile-desc-desk': !mobile, 'home-tile-desc-mobile': mobile }">{{ 'tiles.home_tile_payments' | translate }}</p>
            </div>

            <div fxLayout="column" fxLayoutAlign="flex-start center">
                <div [ngClass]="{'home-tile-desk': !mobile, 'home-tile-mobile': mobile }" (click)="tileClick(3)" [matTooltip]="getFeatureLockedTooltip()">
                    <img *ngIf="leasePresent" [ngClass]="{'home-tile-img-desk': !mobile, 'home-tile-img-mobile': mobile }" src="../../assets/img/rm_icon_lease.svg" />
                    <img *ngIf="!leasePresent" [ngClass]="{'home-tile-img-desk': !mobile, 'home-tile-img-mobile': mobile }" src="../../assets/img/rm_icon_lease_grey.svg" />
                    <div *ngIf="!!counters['lease'] && counters['lease'] > 0" class="badge"><p class="regular-15 no-margin color-white">{{ counters['lease'] }}</p></div>
                </div>
                <p [ngClass]="{'regular-15': !mobile, 'regular-13': mobile, 'home-tile-desc-desk': !mobile, 'home-tile-desc-mobile': mobile }">{{ 'tiles.home_tile_lease' | translate }}</p>
            </div>

            <div fxLayout="column" fxLayoutAlign="flex-start center">
                <div [ngClass]="{'home-tile-desk': !mobile, 'home-tile-mobile': mobile }" (click)="tileClick(4)">
                    <img *ngIf="isLandlordAllowingFeature('chat')" [ngClass]="{'home-tile-img-desk': !mobile, 'home-tile-img-mobile': mobile }" src="../../assets/img/rm_icon_chat.svg" />
                    <img *ngIf="!isLandlordAllowingFeature('chat')" [ngClass]="{'home-tile-img-desk': !mobile, 'home-tile-img-mobile': mobile }" src="../../assets/img/rm_icon_chat_grey.svg" />
                    <div *ngIf="(!!counters['chat'] && counters['chat'] > 0) || (!!counters['communications'] && counters['communications'] > 0)" class="badge"><p class="regular-15 no-margin color-white">{{ (counters['chat'] || 0) + (counters['communications'] || 0) }}</p></div>
                </div>
                <p [ngClass]="{'regular-15': !mobile, 'regular-13': mobile, 'home-tile-desc-desk': !mobile, 'home-tile-desc-mobile': mobile }">{{ 'tiles.home_tile_chat' | translate }}</p>
            </div>

            <div fxLayout="column" fxLayoutAlign="flex-start center">
                <div [ngClass]="{'home-tile-desk': !mobile, 'home-tile-mobile': mobile }" (click)="tileClick(5)" [matTooltip]="getFeatureLockedTooltip()">
                    <img *ngIf="leasePresent && isLandlordAllowingFeature('maintenances')" [ngClass]="{'home-tile-img-desk': !mobile, 'home-tile-img-mobile': mobile }" src="../../assets/img/rm_icon_maintenances.svg" />
                    <img *ngIf="!leasePresent || !isLandlordAllowingFeature('maintenances')" [ngClass]="{'home-tile-img-desk': !mobile, 'home-tile-img-mobile': mobile }" src="../../assets/img/rm_icon_maintenances_grey.svg" />
                    <div *ngIf="!!counters['maintenances'] && counters['maintenances'] > 0" class="badge"><p class="regular-15 no-margin color-white">{{ counters['maintenances'] }}</p></div>
                </div>
                <p [ngClass]="{'regular-15': !mobile, 'regular-13': mobile, 'home-tile-desc-desk': !mobile, 'home-tile-desc-mobile': mobile }">{{ 'tiles.home_tile_maintenances' | translate }}</p>
            </div>

            <div fxLayout="column" fxLayoutAlign="flex-start center">
                <div [ngClass]="{'home-tile-desk': !mobile, 'home-tile-mobile': mobile }" (click)="tileClick(6)">
                    <img [ngClass]="{'home-tile-img-desk': !mobile, 'home-tile-img-mobile': mobile }" src="../../assets/img/rm_icon_files.svg" />
                    <div *ngIf="!!counters['files'] && counters['files'] > 0" class="badge"><p class="regular-15 no-margin color-white">{{ counters['files'] }}</p></div>
                </div>
                <p [ngClass]="{'regular-15': !mobile, 'regular-13': mobile, 'home-tile-desc-desk': !mobile, 'home-tile-desc-mobile': mobile }">{{ 'tiles.home_tile_files' | translate }}</p>
            </div>
        </div>
    </div>

    
    <div *ngIf="!leasePresent" fxLayout="row" fxLayoutAlign="center center">
        <div class="homepage-no-lease-container" [ngClass]="{'mobile': mobile }">
            <p class="text-center" [ngClass]="{'regular-15': !mobile, 'regular-12': mobile }" [innerHTML]="'home_no_lease_desc' | translate:{tenantNameSurname: tenantNameSurname}">
            </p>
        </div>
    </div>
</div>


<div *ngIf="!isLoading && addToHomescreen && !popoverClosed" class="msg-bubble-container">

    <div class="msg-bubble">
        <div fxLayout="row" fxLayoutAlign="flex-end flex-start" style="width: 100%;">
            <div class="mobile-popover-close-trigger" (click)="closeMobilePopoverClick()">
                <img class="mobile-popover-close-img" src="../../assets/img/rm_icon_close_white.svg" />
            </div>
        </div>
    
        <div fxLayout="row" fxLayoutAlign="center flex-start" style="width: 100%; margin-top: -20px;">
            <img class="mobile-popover-logo clickable" src="../../assets/img/RYN-logo.svg" (click)="addToHomeScreenClick()" />
        </div>
    
        <div fxLayout="row" fxLayoutAlign="center flex-start">
            <p class="regular-12 no-margin text-center clickable underlined" (click)="addToHomeScreenClick()">{{ 'com_add_to_homescreen' | translate }}</p>
        </div>

    </div>
    <div fxLayout="row" fxLayoutAlign="center flex-start" style="width: 100%;">
        <img class="mobile-popover-bubble-bottom" src="../../assets/img/rm_grey_bubble_bottom.svg" />
    </div>
</div>
