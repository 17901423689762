import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { startWith, Subject, takeUntil } from 'rxjs';
import { DeviceStateService } from 'src/app/services/device-state.service';

@Component({
  selector: 'app-dash-label-simple',
  templateUrl: './dash-label-simple.component.html',
  styleUrls: ['./dash-label-simple.component.scss']
})
export class DashLabelSimpleComponent implements OnInit, OnDestroy {

	@Input() label: string = "";
	@Input() color = 'black';
	@Input() align = 'left';

	@Input() isBold = false;

	@Input() multiline = false;
	@Input() isTwoLines = false;


    mobile: boolean = false;
    private ngUnsubscribe = new Subject<void>();
    
	
	constructor(
        private readonly deviceStateService: DeviceStateService
    ) { 
        this.mobile = deviceStateService.isMobileResolution();
    }

    ngOnInit(): void {
        this.deviceStateService.screenResized
			.pipe(
				startWith(this.mobile),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(isMobile => {
                this.mobile = isMobile;
            });
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
