import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { startWith, Subject, takeUntil } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { DeviceStateService } from '../services/device-state.service';
import { slideInAnimation } from '../utils/animations';
import { IntercomService } from '../services/intercom-service.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [slideInAnimation]
})
export class DashboardComponent implements OnInit, OnDestroy {


    // Mobile
    mobile: boolean = false;

    private ngUnsubscribe = new Subject<void>();

    helpCenterWasOpen = false;

	constructor(
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly location: Location,
		public readonly deviceStateService: DeviceStateService,
        private readonly authService: AuthService,
        private readonly intercomService: IntercomService
	) {

        this.mobile = deviceStateService.isMobileResolution();
    
        this.intercomService.startIntercom();
    }

	ngOnInit(): void {this.intercomService.startIntercom();
        
        this.deviceStateService.screenResized
            .pipe(
               startWith(this.mobile),
               takeUntil(this.ngUnsubscribe)
            )
            .subscribe(isMobile => this.mobile = isMobile);

            
        this.deviceStateService.hideSplashScreenOnMobile();
	}

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    isHome () : boolean {
		return window.location.pathname === '/' || window.location.href.indexOf('/missingLandlordInfo') >= 0;
	}

    isCommunications () : boolean {
        return window.location.href.indexOf('/communications') >= 0;
    }

    isSettings () : boolean {
        return window.location.href.indexOf('/settings') >= 0 && window.location.href.indexOf('/payments') < 0;
    }

    isChat () : boolean {
        return window.location.href.indexOf('/chat') >= 0;
    }

	backClick () {
        if(!this.deviceStateService.backClickDisabled){
            this.location.back();
        } else {
            this.deviceStateService.backClickDisabled = false;
            this.deviceStateService.fakeBackClick$.next();
        }
    }
    
	goHome () {
		this.router.navigate(['/'], { relativeTo: this.route });
	}

    contactUsClick () {
        this.router.navigate(['/contact'], { relativeTo: this.route });
    }

    logoutClick () {
        this.authService.logout();
    }

    prepareRoute(outlet: RouterOutlet) {
        return (!this.mobile && outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation']);
	}

    openHelpCenter() {
		this.intercomService.displayHelpCenter();
		this.helpCenterWasOpen = true;
	}

    hideHelpCenter() {
		if (this.helpCenterWasOpen) {
			this.intercomService.hideHelpCenter();
			this.helpCenterWasOpen = false;
		}
	}

}
