<div *ngIf="!isLoading" fxLayout="row" fxLayoutAlign="center flex-start">

    <div fxLayout="column" fxLayoutAlign="flex-start center" class="lease-main-content" [ngClass]="{'lease-main-content-desk': !mobile, 'lease-main-content-mobile': mobile }">

        <div *ngIf="!mobile" fxLayout="row" fxLayoutAlign="flex-start center" class="top-row">
            <img class="img-back" src="../../assets/img/rm_icon_arrow_left.svg" (click)="backClick()" />
            <p class="regular-20 no-margin" [innerHTML]="'screenTitles.lease_data_title' | translate"></p>
        </div>
        <div *ngIf="mobile" fxLayout="row" fxLayoutAlign="flex-start center" class="top-row-mobile">
            <p class="regular-15" [innerHTML]="'screenTitles.lease_data_title_2' | translate"></p>
        </div>

        <div [ngClass]="{'page-inner-content-desk': !mobile, 'page-inner-content-mobile': mobile}">
            <div fxLayout="column" fxLayoutAlign="flex-start flex-start" style="width: 100%;">
                <p class="semi-bold-15 no-margin">{{ 'com_details' | translate }}</p>
                <div class="sep-row-light"></div>
            </div>
    
            <div fxLayout="row wrap" fxLayoutAlign="center flex-start" style="width: 100%;">
                <app-dash-label-simple [fxFlex]="mobile ? '100%' : '25%'" [label]="'com_from' | translate">{{ leaseFrom | dfnsFormat: 'dd/MM/yyyy' }}</app-dash-label-simple>
                <app-dash-label-simple [fxFlex]="mobile ? '100%' : '25%'" [ngClass]="{'v-spacing-20': mobile}" [label]="'com_to' | translate">{{ leaseTo > 0 ? (leaseTo | dfnsFormat: 'dd/MM/yyyy') : ('com_no_end_date' | translate) }}</app-dash-label-simple>
                <app-dash-label-simple [fxFlex]="mobile ? '100%' : '25%'" [ngClass]="{'v-spacing-20': mobile}" [label]="'com_monthly_rent' | translate">{{ (monthlyRent / 100) | currency: rentCurrency }}</app-dash-label-simple>
                
                <div [fxFlex]="mobile ? '100%' : '25%'" [ngClass]="{'v-spacing-20': mobile}">
                    <p class="semi-bold-12 color-grey" style="margin-bottom: 5px;">{{ 'com_lease_status' | translate }}</p>
                    <div style="width: min-content;">
                        <div *ngIf="leaseRunning === 0" class="rm-tag-view tag-green">
                            <p class="semi-bold-15 color-white">{{ 'com_running' | translate }}</p>
                        </div>
                        <div *ngIf="leaseRunning === -1" class="rm-tag-view tag-red">
                            <p class="semi-bold-15 color-white">{{ 'com_ended' | translate }}</p>
                        </div>
                        <div *ngIf="leaseRunning === 1" class="rm-tag-view tag-orange">
                            <p class="semi-bold-15 color-white">{{ 'com_starting' | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="center flex-start" style="width: 100%; margin-bottom: 30px;" class="v-spacing-20">
                <app-dash-label-simple [fxFlex]="mobile ? '100%' : '25%'" [label]="'com_property' | translate">{{ leaseProperty }}</app-dash-label-simple>
                <app-dash-label-simple [fxFlex]="mobile ? '100%' : '25%'" [ngClass]="{'v-spacing-20': mobile}" [label]="'com_address' | translate">{{ address }}</app-dash-label-simple>
                <app-dash-label-simple [fxFlex]="mobile ? '100%' : '25%'" [ngClass]="{'v-spacing-20': mobile}" [label]="'com_unit' | translate">{{ unit }}</app-dash-label-simple>
                
                <app-dash-label-simple *ngIf="depositPresent" [fxFlex]="mobile ? '100%' : '25%'" [ngClass]="{'v-spacing-20': mobile}" [label]="'com_deposit' | translate">{{ (depositValue / 100) | currency: depositCurrency }}</app-dash-label-simple>
                <div *ngIf="!depositPresent" [fxFlex]="mobile ? '100%' : '25%'" [ngClass]="{'v-spacing-20': mobile}">
                    <p class="semi-bold-12 color-grey" style="margin-bottom: 5px;">{{ 'com_deposit' | translate }}</p>
                    <div style="width: min-content;">
                        <div class="rm-tag-view tag-red">
                            <p class="semi-bold-15 color-white">{{ 'com_no_deposit' | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="extraFees.length > 0" style="margin-bottom: 30px;">
                
                <p class="regular-15 color-1 no-margin">{{ 'com_rent_details' | translate }}</p>
                <p class="regular-15 v-spacing">{{ 'com_rent_details_desc' | translate }}</p>
        
                <div fxLayout="column wrap" fxLayoutAlign="flex-start flex-start">
                    <ng-container
                        *ngFor="let f of extraFees; let index = index; let last = last"
                        style="margin-bottom: 20px"
                    >
                        <div *ngIf="!!f" fxLayout="row" fxLayoutAlign="flex-start flex-start" style="width: 100%">
                            <div *ngIf="index === 0" [fxFlex]="mobile ? '65%' : '25%'">
                                <app-dash-label-simple [label]="'com_tipology' | translate">{{
                                    f.name
                                }}</app-dash-label-simple>
                            </div>
                            <div *ngIf="index === 0" [fxFlex]="mobile ? '35%' : '25%'">
                                <app-dash-label-simple [label]="'com_amount' | translate">{{
                                    (f.amount / 100) | currency: depositCurrency
                                }}</app-dash-label-simple>
                            </div>
        
                            <div *ngIf="index > 0" [fxFlex]="mobile ? '65%' : '25%'">
                                <p class="regular-15" style="margin: 15px 0 0 0">{{ f.name }}</p>
                            </div>
                            <div *ngIf="index > 0" [fxFlex]="mobile ? '35%' : '25%'">
                                <p class="regular-15" style="margin: 15px 0 0 0">
                                    {{ (f.amount / 100) | currency: depositCurrency }}
                                </p>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>


            <div *ngIf="files.length > 0" fxLayout="column" fxLayoutAlign="flex-start flex-start" style="width: 100%; margin-bottom: 40px;">
                <p class="semi-bold-15 no-margin">{{ 'com_attached_files' | translate }}</p>
                <div class="sep-row-light"></div>

                <app-file-picker [files]="files"></app-file-picker>
            </div>
        </div>
    </div>    
</div>