export const environment = {
    firebase: {
        projectId: 'roommate-d6e73',
        appId: '1:677472613267:web:305b44fc4970385a002e66',
        databaseURL: 'https://roommate-d6e73.firebaseio.com',
        storageBucket: 'roommate-d6e73.appspot.com',
        locationId: 'us-central',
        apiKey: 'AIzaSyDj2hGRTJkGnE6t1zbnL24oUReaSdC8SOU',
        authDomain: 'roommate-d6e73.firebaseapp.com',
        messagingSenderId: '677472613267',
        measurementId: 'G-XJ73BMWY6L'
    },
    firebaseMessagingPublicKey: "BOCO0nM2tLO7IMwUvLmqMCe69CKDHquY7myZTLt0uF_uF89aGPfp3sHAKiqk8seqWkWZmvvn1yz-3yFkdv0aaFs",
      
    production: true,
    
    maps: {
        key: 'AIzaSyAEFDycZlfSp2fsbtcraGc8czOPOP9XpaE'
    },

    rentYourNest: {
        baseURL: 'https://rentyournest.com/o.php'
    },

    services: {
        backend: 'https://backend-beta-gkd742rcla-ew.a.run.app/tenants/v1',
        backendPayments: 'https://backend-beta-gkd742rcla-ew.a.run.app/payments/v1',
        backendEvents: "https://backend-beta-gkd742rcla-ew.a.run.app/events/v1",
        picsProxy: 'https://backend-beta-gkd742rcla-ew.a.run.app/pics',
        socket: 'https://backend-beta-gkd742rcla-ew.a.run.app',
        fileManager: 'https://file-manager-beta-dot-roommate-d6e73.uc.r.appspot.com/fm/v1'
    },

    mangopay: {
        mandateCreationRedirectUrl: 'https://roommate-places-beta-gkd742rcla-ew.a.run.app'
    }
    
};
