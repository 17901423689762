

    <div *ngIf="loadingMessages" style="width:100%; position: relative; z-index: 1; background-color:#edf1fa !important; " 
        [ngStyle]="{height: 'calc(var(--app-height) - ' + (mobile ? (100 + footerHeight) : (305 + footerHeight)) + 'px)'}">
        <ngx-spinner name="spinner1" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
            <p style="margin-top: 80px;color: #042759;">{{ 'loadings.loading_loading' | translate }}</p>
        </ngx-spinner>
    </div>
    <div *ngIf="communications.length > 0 && !loadingMessages" fxFlex fxLayout="column" style="width: 100%; overflow-y: scroll; padding-bottom: 20px;" class="grey-background" #scrollMe detect-scroll (onScroll)="handleScroll($event)">
    <ng-container >
        <ng-container *ngFor="let m of communications; let index = index">
            <div fxLayout="row" fxLayoutAlign="flex-start flex-start" style="width: 100%;">
                <div fxFlex="100%" class="msg-container"
                    [ngClass]="{'unread': index < unreadCount, 'less-bottom-space': m.url && mobile, 'mobile': mobile }">
                    <p style="margin: 0 0 5px 0;" class="semi-bold-12 color-1">{{ m.sentDateTime | dfnsFormat: 'dd' }} <span
                            class="regular-12 color-1">{{ m.sentDateTime | dfnsFormat: 'MMM' }}</span> {{ m.sentDateTime |
                        dfnsFormat: 'yyyy' }}</p>
                    <p *ngIf="!m.url" class="regular-12 no-margin">{{ m.message }}</p>
                    <app-file-picker *ngIf="m.url" [files]="[communicationFiles[m.id]]" [showUploadTime]="false"></app-file-picker>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>

<div *ngIf="communications.length === 0 && !loadingMessages" fxLayout="column" fxLayoutAlign="center center"
    style="margin-top: 30px;">
    <img src="../../assets/img/RYN-logo.svg" />
    <p class="regular-15 text-center" style="margin-top: 25px; width: 90%;" [innerHTML]="'emptyScreens.empty_communications' | translate:{tenantNameSurname: tenantNameSurname}">
    </p>
</div>