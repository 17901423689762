import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Landlord } from "src/app/models/common";

@Component({
  selector: 'app-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.scss']
})
export class DropDownList implements OnInit{
  @Input() landlords: Landlord[] = [];
  @Input() currentLandlordId: string = '';
  @Output() selectedLandlordId: EventEmitter<string> = new EventEmitter<string>();

  selectedLandlord = '';
  isOpen = false;

  ngOnInit(): void {
    this.selectedLandlord = this.landlords.find(landlord => landlord.id === this.currentLandlordId)?.name || 'Select Landlord';
  }

	toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  selectLandlord(landlord: Landlord) {
    this.selectedLandlord = landlord.name;
    this.isOpen = false;
    this.selectedLandlordId.emit(landlord.id);
  }
}
