import { animate, group, query, style, transition, trigger } from '@angular/animations';

const right = [
    query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
    group([
        query(':enter', [style({ transform: 'translateX(100%)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
        optional: true,
        }),
        query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(-100%)' }))], {
        optional: true,
        })
    ])
];

const left = [
    query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
    group([
        query(':enter', [style({ transform: 'translateX(-100%)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
        optional: true,
        }),
        query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(100%)' }))], {
        optional: true,
        })
    ])
];

export const slideInAnimation = trigger('routeAnimations', [
	transition('HomePage => SlidePage, SlidePage => SlidePage2', right),
    transition('SlidePage2 => SlidePage, SlidePage => HomePage', left)
]);
