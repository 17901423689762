<div mat-dialog-content>
	<div fxLayout="column" fxLayoutAlign="flex-start center" class="popup-top-section">
		<div class="v-spacing-20"></div>

		<p class="semi-bold-15 color-white no-margin" [innerHTML]="'payments_setup_reminder_title' | translate">
		</p>

		<img
			src="../../assets/img/close.svg"
			class="clickable"
			(click)="cancelClick()"
			style="width: 15px; height: 15px"
		/>
	</div>

	<div fxLayout="column" fxLayoutAlign="center center" fxLayoutWrap>
		<img src="./assets/img/ic_payments_setup_reminder.svg" class="v-spacing-20" style="width: 60px" />

		<p class="regular-15 text-center">
			{{ 'payments_setup_reminder_desc' | translate }}
		</p>

		<div
			class="popup-bottom-section"
			fxLayout="row"
			fxLayoutAlign="center center"
			fxLayoutWrap
			style="width: 100%"
		>
			<button
				(click)="proceedClick()"
				mat-button
				class="button-primary button-small"
			>{{ 'payments_setup_reminder_cta' | translate }}</button>
		</div>
	</div>
</div>
