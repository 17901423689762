<div class="dropdown-container">
  <div class="dropdown-text" (click)="toggleDropdown()">
    <p class="dropdown-desc">OWNER</p>
    <div class="selector-row">
      <p class="dropdown-selected">{{ selectedLandlord }}</p>
      <img [ngClass]="{'dropdown-arrow-down': !isOpen, 'dropdown-arrow-up': isOpen}" src="./assets/img/arrow-down.svg" alt="arrow down">
    </div>
  </div>
  <div *ngIf="isOpen" class="options">
    <div *ngFor="let landlord of landlords" (click)="selectLandlord(landlord)">{{ landlord.name }}</div>
  </div>
</div>