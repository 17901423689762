import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subject, takeUntil } from 'rxjs';
import { BackendService } from 'src/app/services/backend.service';
import { LoadingService } from 'src/app/services/loading.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-crop-picture-dialog',
	templateUrl: './crop-picture-dialog.component.html',
	styleUrls: ['./crop-picture-dialog.component.scss']
})
export class CropPictureDialogComponent implements OnInit {
	imageURL;
	pendingCropImageEvent: ImageCroppedEvent | undefined;

    tenantNameSurname : string = "";

    isLoading : boolean = true;

    private ngUnsubscribe = new Subject<void>();

	constructor(
        public dialogRef: MatDialogRef<CropPictureDialogComponent>,
        private readonly backendService: BackendService,
        private readonly loadingService: LoadingService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
		const dataUrl: string = data.imageURL;

		// This library loves requiring cors support for the picture it loads. Luckily we set up a proxy for pictures since it was also needed by housing anywhere
		if (dataUrl.indexOf('https://firebasestorage.googleapis.com') > -1) {
			this.imageURL = dataUrl.replace(
				'https://firebasestorage.googleapis.com',
				`${environment.services.backend}/pics`
			);
		} else {
			this.imageURL = dataUrl;
		}
	}

	ngOnInit() {

        this.backendService.loadBasicUserData()
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(userData => {

                this.tenantNameSurname = userData.name + " " + userData.surname;
                
                this.isLoading = false;
                this.loadingService.hide();
            });
    }

	closeDialog() {
		this.dialogRef.close({ action: 'ok' });
	}

	okClick() {
		this.dialogRef.close({ cropImageEvent: this.pendingCropImageEvent });
	}

	imageCropped(event: ImageCroppedEvent) {
		this.pendingCropImageEvent = event;
	}
}
