import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxPicaService } from '@digitalascetic/ngx-pica';
import { SnotifyService } from 'ng-snotify';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ScrollEvent } from 'ngx-scroll-event';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  combineLatest,
  filter,
  startWith,
  Subject,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { ChatMessage, ChatMetadata } from 'src/app/models/message';
import { BackendService } from 'src/app/services/backend.service';
import { DeviceStateService } from 'src/app/services/device-state.service';
import { FileUploaderService } from 'src/app/services/fileUploaderService';
import { LoadingService } from 'src/app/services/loading.service';
import { AppConstants } from 'src/app/utils/app-constants';
import { TranslateService } from '@ngx-translate/core';
import { SocketService } from 'src/app/services/socket.service';
import { AttachedFile, PendingFile } from 'src/app/models/fileRm';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-private-chat',
  templateUrl: './private-chat.component.html',
  styleUrls: ['./private-chat.component.scss'],
})
export class PrivateChatComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef | undefined;
  @ViewChild('inputElement') inputElement: ElementRef | undefined;
  @ViewChild('file', { static: true }) elUploadFile: ElementRef | undefined;

  @Input() thereIsUnits: boolean = false;
  @Input() unitId: string = '';

  @Output() sentUnitId: EventEmitter<string> =new EventEmitter<string>();

  messages: ChatMessage[] = [];
  messagesToShow: ChatMessage[] = [];

  totalMessagesNumber: number = 0;
  totalPagesLoaded: number = 0;

  tenantId: string = '';
  landlordName: string = '';
  tenantNameSurname: string = '';

  typeFormControl: FormControl = new FormControl('', []);

  isLoading = false;
  loadingMessages = true;

  footerHeight = 70;

  unreadCount: number = 0;

  // Mobile
  mobile: boolean = false;

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly deviceStateService: DeviceStateService,
    private readonly spinner: NgxSpinnerService,
    private readonly backendService: BackendService,
    private readonly toastService: SnotifyService,
    private readonly loadingService: LoadingService,
    private ng2ImgMaxService: NgxPicaService,
    private readonly fileUploaderService: FileUploaderService,
    private readonly gaService: GoogleAnalyticsService,
    private readonly translateService: TranslateService,
    private readonly socketService: SocketService
  ) {
    this.mobile = deviceStateService.isMobileResolution();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.unitId = changes[`unitId`]['currentValue'];
    this.messagesToShow = this.messages.filter((message) => message.unitId === this.unitId);
  }

  ngOnInit(): void {
    this.deviceStateService.screenName = 'menu_title_chat';

    this.deviceStateService.screenResized
      .pipe(startWith(this.mobile), takeUntil(this.ngUnsubscribe))
      .subscribe((isMobile) => (this.mobile = isMobile));

	this.spinner.show('spinner1', AppConstants.SPINNER_ELEMENT_CONFIG);
    this.isLoading = true;
    this.loadingMessages = true;

    this.backendService
      .loadBasicUserData()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((userData) => {
        this.tenantNameSurname = userData.name + ' ' + userData.surname;
      });

    this.backendService
		.loadTenantLandlordData()
		.pipe(
			takeUntil(this.ngUnsubscribe),
			switchMap(data => {
				this.tenantId = data.id;
				return this.backendService.getAllMessagesPaginated(0);
			}),
			take(1)
		)
		.subscribe(queryResult => {

			this.messages = queryResult.data.sort((a, b) => a.sentDateTime - b.sentDateTime);

			this.totalMessagesNumber = queryResult.metadata.totalItems || queryResult.data.length;
			this.totalPagesLoaded = 1;

      if(this.unitId !== '') {
        this.messagesToShow = this.messages.filter((message) => message.unitId === this.unitId)
      } else {
        this.messagesToShow = this.messages;
      }

			setTimeout(() => {
				this.spinner.hide('spinner1');
				this.loadingMessages = false;
				this.isLoading = false;

				setTimeout(() => {
					this.scrollToBottom();
				}, 1);
			}, 1000);
		});

    combineLatest([
        this.backendService.landlordId$,
        this.backendService.tenantId$
    ])
        .pipe(
            takeUntil(this.ngUnsubscribe),
            filter(id => !!id),
            switchMap(([landlordId, tenantId]) => this.socketService.registerForUpdates(landlordId, tenantId))
        )
        .subscribe((metadata: ChatMetadata) => {
            
            if (metadata.lastOperation) {
                if (metadata.lastOperation.type === 'create' && metadata.lastMessage) {
                  this.messages.push(metadata.lastMessage);
                  this.totalMessagesNumber += 1;
                  if (this.unitId !== '' && (metadata.lastMessage.unitId && metadata.lastMessage.unitId === this.unitId)) {
                    this.messagesToShow.push(metadata.lastMessage);
                  }
                    setTimeout(() => {
                        this.scrollToBottom();
                    }, 10);
                }
            }

        });


    this.backendService.landlordName$
      .pipe(startWith(''), takeUntil(this.ngUnsubscribe))
      .subscribe((landlordName) => {
        this.landlordName = landlordName;
      });

    this.backendService
      .getCurrentCounters()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((counters) => {
        this.backendService.resetCounter('chat');
      });

    this.typeFormControl.valueChanges.subscribe(() => {
      this.onUserTyping();
    });


    this.ngUnsubscribe.subscribe(() => {
        this.socketService.unregisterForUpdates();
    })
  }

  ngOnDestroy(): void {
      this.ngUnsubscribe.next();
  }

  trackByFn(index: number, item: ChatMessage) {
    // Used in HTML
    return item.id;
  }

  redirectToRYN() {
    window.open(`${environment.rentYourNest.baseURL}?unitId=${this.unitId}&lang=${this.translateService.currentLang}`, '_blank');
  }

  redirectRynTooltip(): string {
    return this.translateService.instant('toasts.redirect_to_ryn_unit');
  }

  addOtherMessages() {
    
    if (this.messages.length < this.totalMessagesNumber && !this.isLoading) {
        console.log('Add other messages');

        this.isLoading = true;

        this.backendService
            .getAllMessagesPaginated(this.totalPagesLoaded)
            .pipe(take(1))
            .subscribe(messagesQueryResult => {
                const msgsIds = this.messages.map(message => message.id);
                this.messages = messagesQueryResult.data
                    .filter(message => !msgsIds.includes(message.id))
                    .sort((a, b) => a.sentDateTime - b.sentDateTime)
                    .concat(this.messages);

                if (this.unitId !== '') {
                  this.messagesToShow = this.messages.filter((message) => message.unitId === this.unitId);
                } else {
                  this.messagesToShow = this.messages;
                }

                this.totalMessagesNumber = messagesQueryResult.metadata.totalItems || messagesQueryResult.data.length;
                this.totalPagesLoaded += 1;

                this.isLoading = false;
            });
    }
  }

  scrollToBottom(): void {
    if (!!this.myScrollContainer) {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight;
    }
  }

  handleScroll(event: ScrollEvent) {
    if (event.isReachingTop) {
        this.addOtherMessages();
    }
  }

  onUserTyping() {
    setTimeout(() => {
      let offset = Math.floor(
        this.inputElement?.nativeElement.offsetHeight / 20
      );

      // The textarea already keeps 2 lines
      if (offset >= 2) {
        offset -= 1;
      }

      let newFooterHeight = 50 + offset * 20;

      if (newFooterHeight !== this.footerHeight) {
        this.footerHeight = newFooterHeight;
      }
    }, 5);
  }

  onSendClick() {
    if (
      !!this.typeFormControl.value &&
      this.typeFormControl.value.trim() !== ''
    ) {

		const textVal = this.typeFormControl.value;
		this.typeFormControl.setValue('');

		this.backendService
			.writeChatMessage(textVal, this.unitId)
			.then((_) => {
        this.sentUnitId.emit(this.unitId);

				this.gaService.gtag('event', 'chat_message_sent', {
					event_label: 'Chat message sent',
					event_category: 'chat',
					isAttachment: 0,
				});
			})
			.catch((err) => {
				// TODO: show the right error message

				this.typeFormControl.setValue(textVal);

				this.toastService.error(
                    this.translateService.instant('toasts.toast_error_send_chat_message_desc'),
                    this.translateService.instant('toasts.toast_error_send_chat_message_title'),
                    AppConstants.TOAST_STD_CONFIG
				);
			});
    }
  }

  onAttachClick() {
    if (!!this.elUploadFile) {
      this.elUploadFile.nativeElement.click();
    }
  }

  async picturesSelected(event: any) {
    const files = event.target.files;

    let fileArray: PendingFile[] = [];
		Object.values(files).forEach((file: any) => { 
      if (file.type.match('image.*') || file.type === 'application/pdf') {
        // Max 8 MB
        if (file.size <= 8 * 1024 * 1024) {
          fileArray.push(new PendingFile(file.name, 0, true, -1, true, 0, undefined, file)); 
        } else {
          this.toastService.error(
            this.translateService.instant('toasts.toast_error_file_too_big_desc'),
            this.translateService.instant('toasts.toast_error_file_too_big_title'),
            AppConstants.TOAST_STD_CONFIG
          );
        }
      }
    });

    if (fileArray.length > 0) {
      if (fileArray.length > 1) {
        this.loadingService.show(
            this.translateService.instant('loadings.sending_files')
        );
      } else {
        this.loadingService.show(
            this.translateService.instant('loadings.sending_file')
        );
      }
    
    }

    const filesToShare = fileArray.map(it => {
      return this.backendService.uploadFile(it)
    });

    const data = await Promise.all(filesToShare)

    data.forEach((file) => {
      const fileSent = file as AttachedFile;
      if(fileSent !== {} as AttachedFile){
        this.backendService
          .writeChatMessage(file.name, this.unitId, file.publicUrl)
          .then(() => {
            this.sentUnitId.emit(this.unitId);

            this.gaService.gtag('event', 'chat_message_sent', {
              event_label: 'Chat message sent',
              event_category: 'chat',
              isAttachment: 1,
            });
          })
          .catch((err) => {
            // TODO: show the right error message

            this.toastService.error(
                this.translateService.instant('toasts.toast_error_send_chat_file_desc'),
                this.translateService.instant('toasts.toast_error_send_chat_file_title'),
                AppConstants.TOAST_STD_CONFIG
            );
          });
      }
    });
    
    this.loadingService.hide();
  }

  isChatMessageSentByMe (m: ChatMessage) : boolean {
    return m.senderId === this.tenantId;
  }
}
