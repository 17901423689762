<div fxLayout="row" fxLayoutAlign="center flex-start">

    <div fxLayout="column" fxLayoutAlign="flex-start center" class="contact-main-content" [ngClass]="{'contact-main-content-desk': !mobile, 'contact-main-content-mobile': mobile }">

        <div *ngIf="!mobile" fxLayout="row" fxLayoutAlign="flex-start center" class="top-row">
            <img class="img-back" src="../../assets/img/rm_icon_arrow_left.svg" (click)="backClick()" />
            <p class="regular-20 no-margin" [innerHTML]="'screenTitles.contact_us_title' | translate"></p>
        </div>
        <div *ngIf="mobile" fxLayout="row" fxLayoutAlign="flex-start center" class="top-row-mobile">
            <p class="regular-15" [innerHTML]="'screenTitles.contact_us_title_2' | translate"></p>
        </div>

        <div [ngClass]="{'page-inner-content-desk': !mobile, 'page-inner-content-mobile': mobile}">
            <div fxLayout="column" fxLayoutAlign="flex-start flex-start" style="width: 100%;">
                <p class="semi-bold-15 color-1 no-margin">{{ 'com_send_a_message' | translate }}</p>
                <p class="regular-12 no-margin italic">{{ 'contact_us_details_desc' | translate }}</p>

                <div fxLayout="row wrap" fxLayoutAlign="flex-startflex-start flex-start" style="width: 100%;" class="v-spacing-20">
                    
                    <div [fxFlex]="mobile ? '100%' : '200px'" class="field-margin" [ngStyle]="{'margin-right': mobile ? '0px' : '20px'}">
                        <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                            <mat-label>{{ 'com_subject' | translate }}</mat-label>
                            <input
                                type="text"
                                matInput
                                [placeholder]="'com_subject' | translate"
                                [formControl]="title"
                                autocomplete="off"
                            />
                        </mat-form-field>
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="flex-start flex-start" style="width: 100%;" class="v-spacing">
                    <div fxFlex="100%">
                        <mat-form-field appearance="outline" class="field-full-width" hideRequiredMarker>
                            <mat-label>{{ 'com_message' | translate }}</mat-label>
                            <textarea
                                matInput
                                [placeholder]="'com_write_here_your_message' | translate"
                                [formControl]="description"
                                autocomplete="off"
                                [ngStyle]="{'min-height': mobile? '100px' : '300px'}"
                            ></textarea>
                        </mat-form-field>
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="flex-end flex-start" style="width: 100%;" class="v-spacing-20">
                    <button mat-button class="button-positive button-small" (click)="sendRequest()">{{ 'com_send' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>