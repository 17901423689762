import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { startWith, Subject, takeUntil } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AttachedFile } from '../models/fileRm';
import { Lease } from '../models/lease.model';
import { PaymentItem } from '../models/payments';
import { EstelleUnit, Property } from '../models/properties';
import { BackendService } from '../services/backend.service';
import { LoadingService } from '../services/loading.service';
import { DeviceStateService } from '../services/device-state.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-lease-data',
  templateUrl: './lease-data.component.html',
  styleUrls: ['./lease-data.component.scss']
})
export class LeaseDataComponent implements OnInit, OnDestroy {
	
	leaseFrom : number = 0;
	leaseTo : number = 0;
	monthlyRent : number = 500;
	rentCurrency : string = "EUR";
	leaseRunning : number = 0;

	leaseProperty : string = "";
	address : string = "";
	unit : string = "";

	depositPresent : boolean = false;
	depositValue : number = 0;
	depositCurrency : string = "EUR";

	extraFees : PaymentItem[] = [];

    files : AttachedFile[] = [];



    isLoading : boolean = true;

	
	// Mobile
	mobile : boolean = false;


	private ngUnsubscribe = new Subject<void>();


	constructor(
		private readonly location: Location,
		private readonly deviceStateService: DeviceStateService,
        private readonly backendService: BackendService,
        private readonly loadingService: LoadingService,
        private readonly translateService: TranslateService
	) {
		this.mobile = deviceStateService.isMobileResolution();
	}

	ngOnInit(): void {

		this.deviceStateService.screenName = 'menu_title_your_lease';
        this.loadingService.show(this.translateService.instant('loadings.loading_loading'));

		this.deviceStateService.screenResized
			.pipe(
				startWith(this.mobile),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(isMobile => this.mobile = isMobile);


        this.backendService.loadBasicUserData()
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(() => {});

        
        this.backendService.resetCounter('lease');

		this.backendService.loadTenantLandlordData()
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(async data => {

                // TODO: what's the logic to always show the right lease?
                // A lease can be RUNNING, ENDED or STARTING
                // What do we do if we have more than one lease?

                const nowTime = new Date().getTime();
                
                if (!!!data.currentLease || !!!data.currentProperty || !!!data.currentUnit) {

                    this.backClick();
                    return;   
                }

                const lease : Lease = data.currentLease;
                const property : Property = data.currentProperty;
                const unit : EstelleUnit = data.currentUnit;

                this.leaseFrom = lease.startDate;
                this.leaseTo = lease.endDate;
                this.monthlyRent = lease.monthlyRent;
                this.rentCurrency = lease.currency;
                if (lease.startDate <= nowTime && lease.endDate >= nowTime) {
                    this.leaseRunning = 0;
                } else if (lease.endDate < nowTime) {
                    this.leaseRunning = -1;
                } else {
                    this.leaseRunning = 1;
                }

                this.leaseProperty = property.name || property.extraAddressInfo?.formattedAddress || property.address || "Property";
                this.address = property.extraAddressInfo?.formattedAddress || property.address || "-";
                this.unit = unit.name;

                this.depositPresent = (lease.deposit > 0);
                this.depositValue = lease.deposit
                this.depositCurrency = lease.currency;

                this.extraFees = [];

                if (lease.rentItem && lease.paymentsSetup && lease.paymentsSetup.incomes) {
                    this.extraFees = [lease.rentItem].concat(lease.paymentsSetup.incomes.map(it => it.item));
                }

                this.files = await this.backendService.loadFiles("leases", lease.id);

                this.isLoading = false;
                this.loadingService.hide();
            });
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	backClick () {
		this.location.back();
	}
}
