import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { catchError, combineLatest, map, of, startWith, Subject, switchMap, take, takeUntil } from 'rxjs';
import { DeviceStateService } from '../services/device-state.service';
import { BankData, InvoiceData, UserData } from '../models/common';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '../services/loading.service';
import { AppConstants } from '../utils/app-constants';
import { SnotifyService } from 'ng-snotify';
import { LocalizationUtils } from '../utils/localization-utils';
import { BackendService } from '../services/backend.service';
import { CropPictureDialogComponent } from '../shared/crop-picture-dialog/crop-picture-dialog.component';
import * as exifr from 'exifr';
import { FileUploaderService } from '../services/fileUploaderService';
import { NgxPicaService } from '@digitalascetic/ngx-pica';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MatDialog } from '@angular/material/dialog';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MatSelectChange } from '@angular/material/select';
import { DateFnsConfigurationService } from 'ngx-date-fns';
import { it, enUS, fr, es } from 'date-fns/locale';
import packageInfo from '../../../package.json';

export function passwordMatchValidator(input: FormControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const valid = input.value === control.value;
        return valid ? null : {notMatch: {value: control.value}};
    };
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {

    @ViewChild('file', { static: true }) elUploadFile: ElementRef | undefined;

    currentPageIndex : number = 0;
    tabs = ['com_account', 'com_password', 'tab_your_data'];


    userData : UserData | undefined;

    tenantPhoto : string = "";

    isLoading : boolean = true;


    // Account

    tenantNameSurname : string = "";
    tenantPhone : string = "";
    tenantEmail : string = "";
    tenantStatus : number = 1;

    imgProfileHover: boolean = false;

    language = new FormControl('', [Validators.required]);
    availableLanguages : { code: string; name: string; }[] = [];
    placesVersion = packageInfo.version;

    // Password

    oldPassword = new FormControl('', [Validators.required]);
    newPassword = new FormControl('', [Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)]);
    newPasswordConfirm = new FormControl('', [Validators.required, passwordMatchValidator(this.newPassword)]);
    hasErrorMessage : boolean = false;
    hasErrorMessage2 : boolean = false;
    hidePassword : boolean = true;

    // Billing

    gottenInvoiceData: InvoiceData = {};
	gottenBankData: BankData = {};
    pendingInvoiceData: InvoiceData = {};
	pendingBankData: BankData = {};

	// Mobile
	mobile : boolean = false;


	private ngUnsubscribe = new Subject<void>();


	constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly loadingService: LoadingService,
		private readonly location: Location,
		private readonly deviceStateService: DeviceStateService,
        private readonly authService: AuthService,
        private readonly toastService: SnotifyService,
        private readonly backendService: BackendService,
        private ng2ImgMaxService: NgxPicaService,
        private readonly fileUploaderService: FileUploaderService,
        private dialog: MatDialog,
        private readonly gaService: GoogleAnalyticsService,
        private readonly translateService: TranslateService,
        public dateConfig: DateFnsConfigurationService
	) {
		this.mobile = deviceStateService.isMobileResolution();

        this.availableLanguages = [
            { code: "en", name: this.translateService.instant('languages.english')},
            { code: "it", name: this.translateService.instant('languages.italian')},
            { code: "es", name: this.translateService.instant('languages.spanish')},
            { code: "fr", name: this.translateService.instant('languages.french')}
        ];

        this.language.setValue(this.translateService.currentLang);
	}

	ngOnInit(): void {

        this.deviceStateService.screenName = 'menu_title_account_settings';
        this.loadingService.show(this.translateService.instant('loadings.loading_loading'));

        combineLatest(([
            this.backendService.loadBasicUserData(),
            this.backendService.loadTenantLandlordData(),
            this.backendService.getUserPaymentInfo()
        ]))
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(async ([userData, tenantLandlordData, paymentInfo]) => {
                
                this.userData = userData;
                
                this.tenantNameSurname = userData.name + " " + userData.surname;
                this.tenantPhone = userData.phone;
                this.tenantEmail = this.authService.loginEmail;

                this.gottenInvoiceData = userData.invoiceData || {};
                this.gottenBankData = tenantLandlordData.bankData || {};
                this.pendingInvoiceData = userData.invoiceData || {};
                this.pendingBankData = tenantLandlordData.bankData || {};

                if (!!userData.photo && userData.photo !== "") {
                    const base64 = await this.getBase64ImageFromUrl(userData.photo);
                    this.tenantPhoto = base64;
                }

                // Load bank data from Payments setup info
                if (!!paymentInfo.bankAccountInfo && paymentInfo.bankAccountInfo.status === 'created' && !!paymentInfo.bankAccountInfo.data) {
                    
                    if (paymentInfo.bankAccountInfo.data.benificiaryName !== '') {
                        this.gottenBankData.beneficiary = paymentInfo.bankAccountInfo.data.benificiaryName;
                    }
                    if (paymentInfo.bankAccountInfo.data.iban !== '') {
                        this.gottenBankData.iban = paymentInfo.bankAccountInfo.data.iban;
                    }
                    
                    this.pendingBankData = this.gottenBankData;
                }
                
                this.isLoading = false;
                this.loadingService.hide();
            });

		this.deviceStateService.screenResized
			.pipe(
				startWith(this.mobile),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(isMobile => this.mobile = isMobile);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	backClick () {
		this.location.back();
	}

    async getBase64ImageFromUrl(imageUrl : string) : Promise<string> {

        let fetchUrl : string = "";

        if (imageUrl.indexOf('https://firebasestorage.googleapis.com') > -1) {
			fetchUrl = imageUrl.replace(
				'https://firebasestorage.googleapis.com',
				`${environment.services.picsProxy}`
			);
		} else {
			fetchUrl = imageUrl;
		}

        var res = await fetch(fetchUrl);
        var blob = await res.blob();
      
        return new Promise((resolve, reject) => {
            var reader  = new FileReader();
            reader.addEventListener("load", function () {
                resolve(reader.result as string);
            }, false);

            reader.onerror = () => {
                return reject(this);
            };
            reader.readAsDataURL(blob);
        })
    }

	choosePicClick () {

        if (!!this.elUploadFile) {
            this.elUploadFile.nativeElement.click();
        }
	}

    async picturesSelected(event : any) {
		const files = event.target.files;

		let validPictureFile: File | undefined;

		if (files.length > 0) {

			const file = files.item(0);

			if (file.type.match('image.*')) {
				
                // Max 8 MB
				if (file.size <= 8 * 1024 * 1024) {
					validPictureFile = file;
				} else {
					this.toastService.error(
                        this.translateService.instant('toasts.toast_error_file_too_big_desc'),
                        this.translateService.instant('toasts.toast_error_file_too_big_title'),
						AppConstants.TOAST_STD_CONFIG
					);
				}
			}
		}
		if (!!validPictureFile) {
            
            this.loadingService.show(this.translateService.instant('loadings.com_loading_file'));

            const promise = new Promise<string>(async (resolve, reject) => {
                // tslint:disable-next-line: no-shadowed-variable
                const data = await new Promise(async (resolve, reject) => {

                    if (!!validPictureFile) {

                        const metadata = await exifr.parse(validPictureFile, {});
                        
                        let finalImage = validPictureFile;

                        let process = false;

                        if (metadata) {
                            const imageWidth = metadata.ImageWidth || 0;
                            const imageHeight = metadata.ImageHeight || 0;

                            if (imageWidth === 0 || imageHeight === 0) {
                                process = true;
                                console.warn(
                                    `Picture ${validPictureFile.name} has no metadata about size but will be resized anyway`
                                );
                            } else if (imageWidth > 1500 || imageHeight > 1500) {
                                process = true;
                                console.warn(`Picture ${validPictureFile.name} is too large and will be resized`);
                            }
                        } else {
                            process = true; // No idea why this has no metadata
                            console.warn(`Picture ${validPictureFile.name} has no metadata but will be resized anyway`);
                        }

                        if (process) {
                            this.ng2ImgMaxService
                                .resizeImage(validPictureFile, 1500, 1500, {
                                    exifOptions: { forceExifOrientation: false },
                                    aspectRatio: { keepAspectRatio: true }
                                })
                                .pipe(
                                    take(1),
                                    switchMap((resizedImage: File) => {
                                        finalImage = resizedImage;
                                        return this.ng2ImgMaxService.compressImage(resizedImage, 0.5);
                                    }),
                                    map((compressedImage: File) => (finalImage = compressedImage)),
                                    catchError((err: string) => {
                                        console.error(err);
                                        return of(finalImage);
                                    })
                                )
                                .subscribe(() => resolve(finalImage));
                        } else {
                            resolve(validPictureFile);
                        }
                    }
                });

                if (!data) {
                    resolve("");
                }

                const reader = new FileReader();
                reader.readAsDataURL(data as Blob);
                reader.onload = _event => {
                    resolve(reader.result as string);
                };
            });

			const chosenPic = await promise; // Base64

            if (chosenPic != "") {

                if (!this.mobile) {
                    // Once the popup will be ready it will hide the loading spinner

                    const cropRef = this.dialog.open(CropPictureDialogComponent, {
                        width: '720px',
                        backdropClass: 'backdrop-dark-background',
                        panelClass: ['no-padding-dialog'],
                        data: {
                            imageURL: chosenPic
                        }
                    });
            
                    cropRef.afterClosed().subscribe(async (result : any) => {
                        if (result && result.cropImageEvent) {
                            const event = result.cropImageEvent as ImageCroppedEvent;
                            
                            if (!!validPictureFile && !!event.base64) {

                                this.loadingService.show(this.translateService.instant('loadings.com_uploading_image'));

                                const extension = validPictureFile.name.split('.').pop() ?? "";

                                const data : string = await this.fileUploaderService.uploadPropfilePic(event.base64, extension);
                
                                if (!!this.userData) {
                                    this.userData.photo = data;

                                    await this.backendService.editBasicUserData(this.userData);

                                    this.gaService.gtag('event', 'profile_picture_changed', {
                                        'event_label': 'Profile picture changed',
                                        'event_category': 'settings'
                                    });

                                    const base64 = await this.getBase64ImageFromUrl(this.userData.photo);
                                    this.tenantPhoto = base64;

                                    this.toastService.success(
                                        this.translateService.instant('toasts.toast_success_picture_changed_desc'),
                                        this.translateService.instant('toasts.toast_success_picture_changed_title'),
                                        AppConstants.TOAST_STD_CONFIG
                                    );
                                }

                                this.loadingService.hide();

                            } else {
                                // Abort operation
                            }
        
                        } else {
                            // Abort operation
                        }
                    });

                } else {

                    // Mobile => use full page

                    const extension = validPictureFile.name.split('.').pop() ?? "";

                    this.router.navigate(['/chooseProfilePicture'], { state: { picture: chosenPic, extension: extension }});
                }

            } else {
                this.loadingService.hide();
                // Abort operation
            }
		}
	}

    
    languageChanged (event: MatSelectChange) {
        localStorage.setItem('locale', event.value);
        this.translateService.use(event.value);
        
        if (!!this.userData) {
            this.backendService.editBasicUserData({...this.userData, language: event.value});
        }

        switch (LocalizationUtils.getLanguage()) {
            case 'en':
                this.dateConfig.setLocale(enUS);
            break;
            case 'it':
                this.dateConfig.setLocale(it);
            break;
            case 'es':
                this.dateConfig.setLocale(es);
            break;
            case 'fr':
                this.dateConfig.setLocale(fr);
            break;
        }
    }




    onTabClicked (indx : number) {
        this.currentPageIndex = indx;
    }


    // Password

    changePassword () {

        this.hasErrorMessage = false;
        this.hasErrorMessage2 = false;

		this.newPassword.markAsTouched();

		if (this.newPassword.valid && this.newPasswordConfirm.valid) {

            this.loadingService.show(this.translateService.instant('loadings.settings_pswd_changing'));

            this.authService.isChangingPassword = true;

            this.authService.changePassword(this.oldPassword.value, this.newPassword.value).then(() => {

                this.loadingService.hide();
                this.router.navigate(['/']);

                setTimeout(() => {
                    this.authService.isChangingPassword = false;
                }, 1000);


                this.toastService.success(
                    this.translateService.instant('toasts.toast_success_password_changed_desc'),
                    this.translateService.instant('toasts.toast_success_password_changed_title'),
                    AppConstants.TOAST_STD_CONFIG
                );

                this.gaService.gtag('event', 'password_changed', {
                    'event_label': 'Password changed',
                    'event_category': 'settings'
                });

            }).catch(() => {
                this.loadingService.hide();
                this.hasErrorMessage2 = true;
            });

		} else {
			this.hasErrorMessage = true;
		}
    }

    // Billing

    invoiceDataChanged(data : InvoiceData) {
		this.pendingInvoiceData = data;
	}

	bankDataChanged(data : BankData) {
		this.pendingBankData = data;
	}

    async saveBillingData () {

        if (!!this.userData) {

            const invoiceDataChanged = !this.shallowEqual(this.gottenInvoiceData, this.pendingInvoiceData);
            const bankDataChanged = !this.shallowEqual(this.gottenBankData, this.pendingBankData);

            if (invoiceDataChanged || bankDataChanged) {
                this.loadingService.show(this.translateService.instant('loadings.loading_saving'));
            }

            // 1. Invoice data
            if (invoiceDataChanged) {
                this.userData.invoiceData = this.pendingInvoiceData;
                await this.backendService.editBasicUserData(this.userData);

                this.gaService.gtag('event', 'invoice_data_changed', {
                    'event_label': 'Invoice data changed',
                    'event_category': 'settings'
                });

                this.gottenInvoiceData = this.pendingInvoiceData;
            }

            // 2. Bank data
            if (bankDataChanged) {
                await this.backendService.editTenantLandlordBankData(this.pendingBankData);

                this.gaService.gtag('event', 'bank_data_changed', {
                    'event_label': 'Bank data changed',
                    'event_category': 'settings'
                });

                this.gottenBankData = this.pendingBankData;
            }
            
            this.loadingService.hide();

            // Success toast

            this.toastService.success(
                this.translateService.instant('toasts.toast_success_billing_info_update_desc'),
                this.translateService.instant('toasts.toast_success_billing_info_update_title'),
                AppConstants.TOAST_STD_CONFIG
            );
        }
    }

    // Data

    privacyClick () {

        if (LocalizationUtils.getLanguage() === "it") {
            window.open('https://www.iubenda.com/privacy-policy/56311127', '_blank');
        } else if (LocalizationUtils.getLanguage() === "es") {
            window.open('https://www.iubenda.com/privacy-policy/25236237', '_blank');
        } else if (LocalizationUtils.getLanguage() === "fr") {
            window.open('https://www.iubenda.com/privacy-policy/95126914', '_blank');
        } else {
            // Default EN
            window.open('https://www.iubenda.com/privacy-policy/87079389', '_blank');
        }

        this.gaService.gtag('event', 'privacy_policy_opened', {
            'event_label': 'Privacy policy opened',
            'event_category': 'settings'
        });
    }

    termsClick () {

        if (LocalizationUtils.getLanguage() === "it") {
            window.open('https://www.the-roommate.com/assets/data/terms/terms-ita.pdf', '_blank');
        } else if (LocalizationUtils.getLanguage() === "es") {
            window.open('https://www.the-roommate.com/assets/data/terms/terms-esp.pdf', '_blank');
        } else if (LocalizationUtils.getLanguage() === "fr") {
            window.open('https://www.the-roommate.com/assets/data/terms/terms-fra.pdf', '_blank');
        } else {
            // Default EN
            window.open('https://www.the-roommate.com/assets/data/terms/terms-eng.pdf', '_blank');
        }

        this.gaService.gtag('event', 'terms_of_use_opened', {
            'event_label': 'Terms of use opened',
            'event_category': 'settings'
        });
    }

    personalDataClick () {
        this.router.navigate(['/personalData/request'], {relativeTo: this.route});
    }

    deleteAccountClick () {
        this.router.navigate(['/personalData/delete'], {relativeTo: this.route});
    }

    goToPaymentSettings() {
        this.router.navigate(['/payments/settings'], {relativeTo: this.route});
    }

    private shallowEqual(object1 : any, object2 : any) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (let key of keys1) {
            if (object1[key] !== object2[key]) {
                return false;
            }
        }
        return true;
    }
}
