import packageInfo from '../../../package.json';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { startWith, Subject, takeUntil } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { LoadingService } from '../services/loading.service';
import { DeviceStateService } from '../services/device-state.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalizationUtils } from '../utils/localization-utils';
import { MatSelectChange } from '@angular/material/select';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
	@ViewChild('passwordElement') passwordElement: ElementRef | undefined;

    currentLanguage : string = "";
	placesVersion = packageInfo.version;

	hasErrorMessage = false;
	errorMessage = '';

	email = new FormControl('', [Validators.required, Validators.email]);
	password = new FormControl('', [Validators.required]);

	
    // Mobile
    mobile = false;
    
    private ngUnsubscribe = new Subject<void>();
    

	constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly authService: AuthService,
        private readonly loadingService: LoadingService,
        private readonly deviceStateService: DeviceStateService,
        private readonly gaService: GoogleAnalyticsService,
        private readonly translateService: TranslateService
    ) {
		
        this.loadingService.hide();

        this.mobile = this.deviceStateService.isMobileResolution();


        this.currentLanguage = "";
	}
    
    ngOnInit() {

        this.currentLanguage = LocalizationUtils.getLanguage();

        const userEmail = this.route.snapshot.queryParamMap.get('em');
		if (!!userEmail && userEmail !== '') {
			this.email.setValue(userEmail);

			setTimeout(() => {
				this.passwordElement?.nativeElement.focus();
			}, 100);
		}

        if (this.authService.firstAuthCheckMade && this.authService.isLoggedIn()) {
            //this.router.navigate(['/']);
        }

        this.deviceStateService.screenResized
            .pipe(
               startWith(this.mobile),
               takeUntil(this.ngUnsubscribe)
            )
            .subscribe(isMobile => this.mobile = isMobile);

            
        this.deviceStateService.hideSplashScreenOnMobile();
	}

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

	@HostListener('document:click', ['$event'])
	documentClick(event: MouseEvent) {
		this.email.setErrors({ 'submitted-error': false });
		this.password.setErrors({ 'submitted-error': false });

		this.email.updateValueAndValidity();
		this.password.updateValueAndValidity();
	}

	login() {
		this.email.markAsTouched();
		this.password.markAsTouched();

		if (!this.email.hasError('required') && !this.email.hasError('email') && this.password.value !== '') {
			
            this.loadingService.show(this.translateService.instant('loadings.login_loading'));

			this.authService.login(this.email.value, this.password.value)
                .then(() => {
                    this.gaService.gtag('event', 'user_login', {
                        'event_label': 'User login',
                        'event_category': 'login',
                        'method': 'email'
                    });
                })
                .catch((errType: number) => {
                    this.hasErrorMessage = true;

                    this.loadingService.hide();

                    if (errType === 0) {
                        this.errorMessage = this.translateService.instant('login_error_general');

                        this.email.setErrors({ 'submitted-error': true });
                        this.password.setErrors({ 'submitted-error': true });
                    } else if (errType === 1) {
                        this.errorMessage = this.translateService.instant('login_error_user');;

                        this.email.setErrors({ 'submitted-error': true });
                        this.password.setErrors({ 'submitted-error': true });
                    }
                });
		}
	}


    setLanguage (lang : string) {

        localStorage.setItem('locale', lang);
        this.translateService.use(lang);

        this.currentLanguage = LocalizationUtils.getLanguage();
    }

    passwordRecoveryClick () {
        this.router.navigate(['/passwordrecovery'])
    }

    socialLoginPressed (choice: MatSelectChange) {
        
        if (choice.value === 'facebook') {
            this.authService.facebookLogIn();
        } else if (choice.value === 'google') {
            this.authService.googleLogIn();
        } else if (choice.value === 'apple') {
            this.authService.appleLogIn();
        }
    }
}