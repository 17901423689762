import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss']
})
export class TagListComponent implements OnInit, OnChanges {

    @Input() tags: string[] = [];
	@Input() isClickable = false;
	@Input() isSelectable = false;
	@Input() singleSelect = false;
	@Input() isDeletable = false;
	@Input() isEnabled = true;
	@Input() withSmallerMargin = false;

	@Input() allDeletable = false;
	@Input() allNotHighlighted = false;
	@Input() first2Undeletable = false;

	@Input() errorList: number[] = [];
	@Input() selectedList: number[] = [];

	@Input() allowEmptySelection: boolean = false;

	@Output() tagPressed = new EventEmitter<number>();
	@Output() tagSelected = new EventEmitter<{ index: number; isSelected: boolean }>();
	@Output() tagDeleted = new EventEmitter<number>();

	constructor() {}

	ngOnInit() {
		if (this.singleSelect) {
			this.checkSingleSelection();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['tags']) {
			this.checkSingleSelection();

			if (
				this.singleSelect &&
				!this.allDeletable &&
				this.selectedList.length > 0 &&
				this.selectedList[0] > this.tags.length - 1
			) {
				this.tagClick(this.tags.length - 1);
			}
		}
	}

	tagClick(index : number) {
		if (this.isEnabled) {
			if (this.isClickable) {
				this.tagPressed.emit(index);
			}

			if (this.isSelectable && !this.singleSelect) {
				const selectedIndex = this.selectedList.indexOf(index);

				if (selectedIndex > -1) {
					this.selectedList.splice(selectedIndex, 1);
					this.tagSelected.emit({ index, isSelected: false });
				} else {
					this.selectedList.push(index);
					this.tagSelected.emit({ index, isSelected: true });
				}
			}

			if (this.isSelectable && this.singleSelect) {
				this.selectedList = [index];
				this.tagSelected.emit({ index, isSelected: true });
			}
		}
	}

	deleteClick(index: number) {
		if (this.isDeletable || this.allDeletable) {
			if (this.isSelectable) {
				const selectedIndex = this.selectedList.indexOf(index);

				if (selectedIndex > -1) {
					this.selectedList.splice(selectedIndex, 1);
				}

				const errorIndex = this.errorList.indexOf(index);

				if (errorIndex > -1) {
					this.errorList.splice(errorIndex, 1);
				}
			}

			this.tags.splice(index, 1);
			this.tagDeleted.emit(index);

			this.checkSingleSelection();
		}
	}

	checkSingleSelection() {
		if (this.singleSelect && this.selectedList.length === 0 && !this.allDeletable && !this.allowEmptySelection) {
			this.selectedList = [0];
			this.tagSelected.emit({ index: 0, isSelected: true });
		}
	}

	isSelected(index: number) {
		return this.selectedList.indexOf(index) >= 0;
	}

	isError(index: number) {
		return this.errorList.indexOf(index) >= 0;
	}
}
