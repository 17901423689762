<div *ngIf="!isLoading" fxLayout="row" fxLayoutAlign="center flex-start">

    <div fxLayout="column" fxLayoutAlign="flex-start center" class="files-main-content" [ngClass]="{'files-main-content-desk': !mobile, 'files-main-content-mobile': mobile }">

        <div *ngIf="!mobile" fxLayout="row" fxLayoutAlign="flex-start center" class="top-row">
            <img class="img-back" src="../../assets/img/rm_icon_arrow_left.svg" (click)="backClick()" />
            <p class="regular-20 no-margin" [innerHTML]="'screenTitles.files_title' | translate"></p>
        </div>
        <div *ngIf="mobile" fxLayout="row" fxLayoutAlign="flex-start center" class="top-row-mobile">
            <p class="regular-15" [innerHTML]="'screenTitles.files_title_2' | translate"></p>
        </div>

        <div *ngIf="tenantFiles.length > 0 || propertyFiles.length > 0 || leaseFiles.length > 0; else emptyScreen" [ngClass]="{'page-inner-content-desk': !mobile, 'page-inner-content-mobile': mobile}">
            
            <div fxLayout="column" fxLayoutAlign="flex-start flex-start" style="width: 100%; margin-bottom: 30px;">
                <p class="semi-bold-15 no-margin">{{ 'com_tenant' | translate }}</p>
                <div class="sep-row-light"></div>

                <app-file-picker *ngIf="tenantFiles.length > 0; else emptyScreenTenant" [files]="tenantFiles"></app-file-picker>
                <ng-template #emptyScreenTenant>
                    <div class="files-inner-empty">
                        <p class="regular-15 text-center" [innerHTML]="'emptyScreens.empty_files_tenant' | translate:{tenantNameSurname: tenantNameSurname}">
                        </p>
                    </div>
                </ng-template>
            </div>

            <div fxLayout="column" fxLayoutAlign="flex-start flex-start" style="width: 100%; margin-bottom: 30px;">
                <p class="semi-bold-15 no-margin">{{ 'com_property' | translate }}</p>
                <div class="sep-row-light"></div>

                <app-file-picker *ngIf="propertyFiles.length > 0; else emptyScreenProperty" [files]="propertyFiles"></app-file-picker>
                <ng-template #emptyScreenProperty>
                    <div class="files-inner-empty">
                        <p class="regular-15 text-center" [innerHTML]="'emptyScreens.empty_files_property' | translate:{tenantNameSurname: tenantNameSurname}">
                        </p>
                    </div>
                </ng-template>
            </div>

            <div *ngFor="let lease of leaseFiles" fxLayout="column" fxLayoutAlign="flex-start flex-start" style="width: 100%; margin-bottom: 30px;">
                <p class="semi-bold-15 no-margin">{{ 'com_lease' | translate }} <span class="regular-15">{{ lease.from | dfnsFormat: 'dd/MM/yyyy' }} - {{ lease.to | dfnsFormat: 'dd/MM/yyyy' }}</span></p>
                <div class="sep-row-light"></div>

                <app-file-picker *ngIf="lease.files.length > 0; else emptyScreenLease" [files]="lease.files"></app-file-picker>
                <ng-template #emptyScreenLease>
                    <div class="files-inner-empty">
                        <p class="regular-15 text-center" [innerHTML]="'emptyScreens.empty_files_lease' | translate:{tenantNameSurname: tenantNameSurname}">
                        </p>
                    </div>
                </ng-template>
            </div>
            <div *ngIf="leaseFiles.length === 0" fxLayout="column" fxLayoutAlign="flex-start flex-start" style="width: 100%; margin-bottom: 30px;">
                <p class="semi-bold-15 no-margin">{{ 'com_lease' | translate }}</p>
                <div class="sep-row-light"></div>
                <div class="files-inner-empty">
                    <p class="regular-15 text-center" [innerHTML]="'emptyScreens.empty_files_lease' | translate:{tenantNameSurname: tenantNameSurname}">
                    </p>
                </div>
            </div>
        </div>
        <ng-template #emptyScreen>
            <div [ngClass]="{'page-inner-content-desk': !mobile, 'page-inner-content-mobile': mobile}" fxLayout="column" fxLayoutAlign="center center">
                <img src="../../assets/img/RYN-logo.svg" />
                <p class="regular-15 text-center" style="margin-top: 25px;" [innerHTML]="'emptyScreens.empty_files_all' | translate:{tenantNameSurname: tenantNameSurname}">
                </p>
            </div>
        </ng-template>
    </div>    
</div>