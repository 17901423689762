<div fxLayout="row" fxLayoutAlign="center flex-start">

    <div fxLayout="column" fxLayoutAlign="flex-start center" class="msgs-main-content" [ngClass]="{'msgs-main-content-desk': !mobile, 'msgs-main-content-mobile': mobile }">

        <div *ngIf="!mobile" fxLayout="row" fxLayoutAlign="flex-start center" class="top-row">
            <img class="img-back" src="../../assets/img/rm_icon_arrow_left.svg" (click)="backClick()" />
            <p class="regular-20 no-margin" [innerHTML]="'screenTitles.chat_title' | translate"></p>
        </div>

        <div fxLayout="column" fxLayoutAlign="flex-start center" [ngClass]="{'page-inner-content-desk': !mobile, 'page-inner-content-mobile': mobile}" style="height: 100%;">

            <div style="width: 100%;">
                <app-roommate-tabs (clickedOption)="onTabClicked($event)" [options]="tabs"></app-roommate-tabs>
                <div class="sep-row" [ngClass]="{'desk': !mobile}"></div>
            </div>

            <ng-container *ngIf="units.length > 0; else normalChat">
                <div *ngIf="currentPageIndex === 0" fxFlex fxLayout="row" [ngStyle]="{'border-radius': mobile ? '0px' : '12px'}" style="width: 100%;">
                    <app-unit-sidebar *ngIf="!mobile || !thereIsUnit" [units]="units" [selectedId]="unitId" (unitChange)="unitChange($event)"></app-unit-sidebar>
                    <div *ngIf="!mobile || thereIsUnit" fxFlex fxLayout="column">
                        <div *ngIf="thereIsUnit && mobile" class="actions-chat">
                            <div class="return-list-container">
                                <img (click)="returnToConversations()" *ngIf="mobile" src="./assets/img/arrow-left.svg" alt="arrow to right to select chat" style="width: 14px; height: 14px; padding-left: 8px; cursor: pointer;">
                                <span (click)="returnToConversations()" style="cursor: pointer;">{{ 'chat_conversations' | translate }}</span>
                            </div>
                            <div (click)="redirectToRYN()" class="view-unit">
                                <p class="no-margin">{{ 'chat_view_unit' | translate }}</p>
                                <img src="./assets/img/new-window.svg" alt=" select chat" style="width: 20px; height: 20px;">
                            </div>
                        </div>
                        <app-private-chat [thereIsUnits]="true" [unitId]="unitId" (sentUnitId)="sentUnitId($event)"></app-private-chat>
                    </div>
                </div>
            </ng-container>

            <ng-template #normalChat>
                <div *ngIf="currentPageIndex === 0" fxFlex fxLayout="column" [ngStyle]="{'border-radius': mobile ? '0px' : '12px'}" style="width: 100%;">
                    <app-private-chat></app-private-chat>
                </div>
            </ng-template>

            <div *ngIf="currentPageIndex === 1" fxFlex [ngStyle]="{'border-radius': mobile ? '0px' : '12px'}" style="width: 100%;" class="communications-container">
                <app-communications-chat></app-communications-chat>       
            </div>

        </div>
    </div>
</div>