import { Injectable } from '@angular/core';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime} from 'rxjs/operators';
import { SnotifyPosition, SnotifyService, SnotifyToastConfig } from 'ng-snotify';
import { BackendService } from './backend.service';
import { environment } from 'src/environments/environment';

export interface TenantNotificationPayload {
	 data: {
		id: string,
		screen: string,
		spaceId: string
	 },
	 notification: {
		 title: string,
		 message: string
	 }
}

@Injectable({
	providedIn: 'root'
})
export class MessagingService {

	public TOAST_STD_CONFIG: SnotifyToastConfig = {
		position: SnotifyPosition.rightTop,
		timeout: 2000,
		titleMaxLength: 40
	};


	currentMessage = new BehaviorSubject(null);

	private tokenChangeSubject = new Subject<string|null>();

	constructor(
		private toastService: SnotifyService,
		private backendService: BackendService
	) {

        this.tokenChangeSubject.asObservable().pipe(debounceTime(1000)).subscribe(token => {
			if(!!token) {
				this.backendService.setToken(token, 'web');
			}
		})
	}

	updateToken(token: string) {
		this.tokenChangeSubject.next(token);
	}

	requestPermission() {

        const messaging = getMessaging();

        getToken(messaging, {vapidKey: environment.firebaseMessagingPublicKey})
            .then((currentToken) => {

                if (currentToken) {
                // Send the token to your server and update the UI if necessary
                // ...

                console.log("Valid token: " + currentToken);
                this.updateToken(currentToken || "");

                } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
                // ...
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                // ...
            });
	}

	hasNotificationPermissionEnabled(): boolean {
		return Notification.permission === 'granted';
	}

	hasExplicitlyRefusedNotification(): boolean {
		return Notification.permission === 'denied';
	}

	showToaster(notificationData: TenantNotificationPayload) {
		this.playAudio();
		this.toastService
			.success(notificationData.notification.title, notificationData.notification.title, this.TOAST_STD_CONFIG)
			.on('click', () => {
				this.toasterClickedHandler(notificationData);
			});
	}

	toasterClickedHandler(notificationData: any) {
	}

	playAudio() {
		const audio = new Audio();
		audio.src = './assets/sounds/definite.mp3';
		audio.load();
		audio.play();
	}

	initMessages() {
        const messaging = getMessaging();
        onMessage(messaging, (payload) => {
            const tenantPayload = payload as any as TenantNotificationPayload;
			this.showToaster(tenantPayload);
        });
	}
}

