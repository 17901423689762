<div mat-dialog-content>
	<div fxLayout="column" fxLayoutAlign="flex-start center" class="popup-top-section">
		<div class="v-spacing-20"></div>

		<p class="semi-bold-15 color-white no-margin" [innerHTML]="'add_invoice_data_popup_title' | translate">
		</p>

		<img
			src="../../assets/img/close.svg"
			class="clickable"
			(click)="cancelClick()"
			style="width: 15px; height: 15px"
		/>
	</div>

	<div fxLayout="column" fxLayoutAlign="flex-start flex-start" fxLayoutWrap>

        <div class="main-column">
            <p class="regular-15 color-1 no-margin">{{ 'com_invoicing_info' | translate }}</p>
            <p class="regular-12" style="margin: 0 0 10px 0">{{ 'add_invoice_data_popup_desc' | translate }}</p>

            <app-invoice-form
                *ngIf="!!userData"
                [invoiceData]="gottenInvoiceData"
                (invoiceDataChange)="invoiceDataChanged($event)"
            ></app-invoice-form>
        </div>
		
        <div
			class="popup-bottom-section"
			fxLayout="row"
			fxLayoutAlign="space-between center"
			fxLayoutWrap
			style="width: 100%"
		>
			<button
				(click)="cancelClick()"
				mat-button
				class="button-negative-empty button-small"
			>{{ 'com_cancel' | translate }}</button>

            <button
				(click)="saveClick()"
				mat-button
                [ngClass]="{'button-positive': dataChanged, 'button-neutral': !dataChanged }"
				class="button-small"
			>{{ 'com_save' | translate }}</button>
		</div>
	</div>
</div>
