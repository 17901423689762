import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AddressData, InvoiceData } from 'src/app/models/common';

@Component({
	selector: 'app-invoice-form',
	templateUrl: './invoice-form.component.html',
	styleUrls: ['./invoice-form.component.scss']
})
export class InvoiceFormComponent implements OnInit, OnChanges {
	defaultInvoiceData: InvoiceData = {};

	public mainInfoForm = this.formBuilder.group({
		type: new FormControl('', []),
		taxCode: new FormControl('', []),
		uniqueCode: new FormControl('', []),
		businessName: new FormControl('', [])
	});

	@Input() invoiceData: InvoiceData = {
		type: 'natural'
	};

	@Input() showPersonType = true;

	@Output() invoiceDataChange = new EventEmitter<InvoiceData>();

	@Input() showMissing = false;

	constructor(private readonly formBuilder: FormBuilder) {}

	currentInvoiceData: InvoiceData = { ...this.defaultInvoiceData };

	ngOnChanges(changes: SimpleChanges) {
		if (changes['showMissing']) {
			if (this.showMissing) {
				Object.values(this.mainInfoForm.controls).forEach(it => it.setValidators(Validators.required));

				setTimeout(() => {
					this.mainInfoForm.markAllAsTouched();
				}, 0);
			} else {
				Object.values(this.mainInfoForm.controls).forEach(it => it.clearValidators());
				this.mainInfoForm.markAsUntouched();
			}
		}

		if (changes['invoiceData']) {
			if (this.invoiceData) {
				this.currentInvoiceData = this.invoiceData;

				this.mainInfoForm.setValue({
					type: this.invoiceData.type || 'natural',
					taxCode: this.invoiceData.taxCode || '',
					businessName: this.invoiceData.businessName || '',
					uniqueCode: this.invoiceData.uniqueCode || ''
				});

				// This trigger valueChanges and set data in addDialogTenant | Without this data won't be synced if I'm editing other fields
				setTimeout(() => {
					this.mainInfoForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
				}, 0);
			}
		}
	}

	ngOnInit() {
		this.mainInfoForm.valueChanges.subscribe(value => {
			this.currentInvoiceData = { ...this.currentInvoiceData, ...value };
			if (!this.mainInfoForm.pristine) {
				this.invoiceDataChange.next(this.currentInvoiceData);
			}
		});
	}

	onAddressUpdated(event: AddressData) {
		this.currentInvoiceData = { ...this.currentInvoiceData, ...event };
		this.invoiceDataChange.next(this.currentInvoiceData);
	}
}
