//import { Country } from '@angular-material-extensions/select-country';
import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AddressData } from '../../models/common';
import { AppUtils } from '../../utils/app-utils';
import { LocalizationUtils } from '../../utils/localization-utils';
import PlaceResult = google.maps.places.PlaceResult;


@Component({
	selector: 'app-address-form',
	templateUrl: './address-form.component.html',
	styleUrls: ['./address-form.component.scss'],
	animations: [
		trigger('slideInOut', [
			transition(':enter', [
				style({ transform: 'translateY(-0%)', opacity: '0', height: '0px' }),
				animate('200ms ease-in', style({ transform: 'translateY(0%)', opacity: '1', height: '126px' }))
			]),
			transition(':leave', [
				animate('200ms ease-in', style({ transform: 'translateY(-0%)', opacity: '0', height: '0px' }))
			])
		])
	]
})
export class AddressFormComponent implements OnInit, OnChanges {
	defaultAddressData = {
		address: '',
		region: '',
		regionCode: '',
		country: '',
		countryCode: '',
		postalCode: '',
		city: '',
		houseNumber: ''
	};

	addressShown = false;

	// I need this to find the element for the address bar to clear it (bug on the library)
	inputClass = Math.random().toString(36).substring(5);

	addressAutoCompleteForm = new FormControl('', [Validators.required]);

	public addressGroupForm = this.formBuilder.group({
		address: new FormControl('', [Validators.required]),
		houseNumber: new FormControl('', []),
		city: new FormControl('', [Validators.required]),
		postalCode: new FormControl('', [Validators.min(4), Validators.max(10), Validators.pattern('[a-zA-Z0-9-_]*')]),
		region: new FormControl('', []),
		country: new FormControl('', [Validators.required])
	});

	@Input() inputAddressData: AddressData = { ...this.defaultAddressData };

	currentAddressData: AddressData = { ...this.defaultAddressData };

	private subscription: Subscription | undefined;
	private updates = new Subject<AddressData>();
	@Output() addressDataChange = new EventEmitter<AddressData>();

	@Input() required = false;

	@Input() inSettings = false;

    @Input() disabled = false;

	editEnabledField : any = {};

	onClickField(field: string) {
		this.editEnabledField[field] = !!!this.editEnabledField[field];
	}

	onClickEdit(field: string) {
		this.onClickField(field);

		switch (field) {
			case 'city':
				this.addressGroupForm.controls['city'].enable();
				break;
			case 'country':
				this.addressGroupForm.controls['country'].enable();
				break;
			case 'address':
				this.addressGroupForm.controls['address'].enable();
				break;
			case 'postalCode':
				this.addressGroupForm.controls['postalCode'].enable();
				break;
			case 'region':
				this.addressGroupForm.controls['region'].enable();
				break;
			case 'houseNumber':
				this.addressGroupForm.controls['houseNumber'].enable();
				break;
		}
	}

	constructor(private readonly formBuilder: FormBuilder, private el: ElementRef) {}

    language = LocalizationUtils.getLanguage();

	formattedAddressUpToDate = true;

	ngOnChanges(changes: SimpleChanges) {
		if (changes['showMissing']) {
			if (this.required) {
				Object.values(this.addressGroupForm.controls).forEach(it => it.setValidators(Validators.required));
				this.addressAutoCompleteForm.setValidators(Validators.required);

				setTimeout(() => {
					//this.addressGroupForm.markAllAsTouched();
					//this.addressAutoCompleteForm.markAsTouched();
				}, 0);
			} else {
				Object.values(this.addressGroupForm.controls).forEach(it => it.clearValidators());
				this.addressAutoCompleteForm.clearValidators();

				this.addressAutoCompleteForm.markAsUntouched();
				this.addressGroupForm.markAsUntouched();
			}
		}

		if (changes['inputAddressData']) {
			if (this.inputAddressData && this.inputAddressData.address) {
				// This trigger valueChanges and set data in addDialogTenant | Without this data won't be synced if I'm editing other fields
				setTimeout(() => {
					this.addressGroupForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
				}, 0);

				// We copy the current address methods, being sure to include only address-related fields
				this.currentAddressData = AppUtils.deepCopy(this.inputAddressData, [
					'address',
					'city',
					'postalCode',
					'region',
					'country',
					'countryCode',
					'houseNumber',
					'regionCode',
					'extraAddressInfo'
				]);

				if (this.currentAddressData.country && !this.currentAddressData.countryCode) {
					const countryNormalized = this.currentAddressData.country.toUpperCase().substr(0, 2);
					if (countryNormalized === 'IT') {
						this.currentAddressData.countryCode = 'IT';
					}
				}

				setTimeout(() => {
					this.el.nativeElement.querySelector(`.x${this.inputClass}`).value =
						this.inputAddressData?.extraAddressInfo?.formattedAddress || '';
				}, 300);

				this.fillAddressGroupForm();
				this.disableFilledFields();
				this.addressShown = true;
				this.sendAddressEvent();
			}
		}

        if (this.disabled) {
            this.addressAutoCompleteForm.disable();
        } else {
            this.addressAutoCompleteForm.enable();
        }

		this.subscription = this.updates.pipe(debounceTime(300)).subscribe(addressData => {

			if (!this.formattedAddressUpToDate) {
				
                let customFormattedAddress = `${addressData.address} ${addressData.houseNumber || ''}, ${
					addressData.city || ''
				}`;

				if (addressData.postalCode) {
					customFormattedAddress = `${customFormattedAddress} (${addressData.postalCode})`;
				}

				if (addressData.postalCode) {
					customFormattedAddress = `${customFormattedAddress} ${addressData.countryCode}`;
				}

				addressData.extraAddressInfo = {
					formattedAddress: customFormattedAddress
				};

				this.el.nativeElement.querySelector(`.x${this.inputClass}`).value = customFormattedAddress;
			}

			this.addressDataChange.next(addressData);
		});
	}

	ngOnInit() {
		this.addressGroupForm.controls['houseNumber'].valueChanges.subscribe(value => {
			if (!this.addressGroupForm.controls['houseNumber'].pristine && this.currentAddressData.houseNumber !== value) {
				this.currentAddressData.houseNumber = value || '';
				this.sendAddressEvent();
			}
		});

		this.addressGroupForm.controls['address'].valueChanges.subscribe(value => {
			if (!this.addressGroupForm.controls['address'].pristine && this.currentAddressData.address !== value) {
				this.currentAddressData.address = value || '';
				this.formattedAddressUpToDate = false;
				this.sendAddressEvent();
			}
		});

		this.addressGroupForm.controls['city'].valueChanges.subscribe(value => {
			if (!this.addressGroupForm.controls['city'].pristine && this.currentAddressData.city !== value) {
				this.currentAddressData.city = value || '';
				this.formattedAddressUpToDate = false;
				this.sendAddressEvent();
			}
		});

		this.addressGroupForm.controls['region'].valueChanges.subscribe(value => {
			if (!this.addressGroupForm.controls['region'].pristine && this.currentAddressData.region !== value) {
				this.currentAddressData.region = value || '';
				this.sendAddressEvent();
			}
		});

        // TODO: value TYPE is Country
		this.addressGroupForm.controls['country'].valueChanges.subscribe((value: any) => {
            if (
                !!value &&
				!this.addressGroupForm.controls['country'].pristine &&
				this.currentAddressData.countryCode !== value.alpha2Code
			) {
				this.currentAddressData.country = value.name || '';
				this.currentAddressData.countryCode = value.alpha2Code;
				this.formattedAddressUpToDate = false;
				this.sendAddressEvent();
			}
		});

		this.addressGroupForm.controls['postalCode'].valueChanges.subscribe(value => {
			if (!this.addressGroupForm.controls['postalCode'].pristine && this.currentAddressData.postalCode !== value) {
				this.currentAddressData.postalCode = value || '';
				this.formattedAddressUpToDate = false;
				this.sendAddressEvent();
			}
		});
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	sendAddressEvent() {
		this.updates.next(this.currentAddressData);
	}

	onDeleteAddress() {
		this.resetAddressData();
	}

	onAddressAutoCompleteChanged(change : any) {

	}

    // TODO: event TYPE is PlaceResult
	onAutocompleteSelected(event: any) {
		const country = event.address_components.find((it : any) => it.types[0] === 'country').long_name;
		const countryCode = event.address_components.find((it : any) => it.types[0] === 'country').short_name;
		const number = event.address_components.find((it : any) => it.types[0] === 'street_number')?.long_name;
		const route = event.address_components.find((it : any) => it.types[0] === 'route')?.long_name;
		const postalCode = event.address_components.find((it : any) => it.types[0] === 'postal_code')?.long_name;
		const city =
			event.address_components.find((it : any) => it.types[0] === 'locality')?.long_name ||
			event.address_components.find((it : any) => it.types[0] === 'administrative_area_level_1')?.long_name;
		const region = event.address_components.find((it : any) => it.types[0] === 'administrative_area_level_2')?.long_name;
		const regionCode = event.address_components.find((it : any) => it.types[0] === 'administrative_area_level_2')
			?.short_name;

		this.currentAddressData.address = route || '';
		this.currentAddressData.houseNumber = number;
		this.currentAddressData.city = city || '';
		this.currentAddressData.postalCode = postalCode || '';
		this.currentAddressData.region = region || '';
		this.currentAddressData.regionCode = regionCode || '';
		this.currentAddressData.country = country || '';
		this.currentAddressData.countryCode = countryCode || '';

		this.currentAddressData.extraAddressInfo = {
			formattedAddress: event.formatted_address || '',
			coordinates: {
				lat: event?.geometry?.location.lat(),
				lng: event?.geometry?.location.lng()
			}
		};

		this.formattedAddressUpToDate = true;

		this.disableFilledFields();

		this.addressGroupForm.setValue({
			address: route || '',
			houseNumber: number || '',
			city: city || '',
			postalCode: postalCode?.trim() || '',
			region: region || '',
			country: { alpha2Code: countryCode || '' }
		});

		this.addressShown = true;

		this.sendAddressEvent();

		setTimeout(() => {
			if (!number) {
				const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="houseNumber"]');
				invalidControl.focus();
			} else {
				this.addressGroupForm.controls['postalCode'].setErrors(null);
			}
		}, 500);
	}

    // TODO: event TYPE is Country
	onCountrySelected(event: any) {
		
	}

	markAsTouched() {
		this.addressAutoCompleteForm.markAsTouched();
		this.addressGroupForm.markAllAsTouched();
	}

	onLocationSelected(event: any) {
		console.table(event);
	}

	private resetAddressData() {
		this.formattedAddressUpToDate = false;
		this.currentAddressData = { ...this.defaultAddressData };
		this.fillAddressGroupForm();
		this.addressAutoCompleteForm.setValue('');
		this.addressShown = false;
		this.el.nativeElement.querySelector(`.x${this.inputClass}`).value = '';
		this.sendAddressEvent();
	}

	private fillAddressGroupForm() {
		this.addressGroupForm.setValue({
			address: this.currentAddressData.address || '',
			city: this.currentAddressData.city || '',
			postalCode: this.currentAddressData.postalCode || '',
			region: this.currentAddressData.region || '',
			country: { alpha2Code: this.currentAddressData.countryCode || '' },
			houseNumber: this.currentAddressData.houseNumber || ''
		});
	}

	private disableFilledFields() {
		this.editEnabledField = {};

        console.log(this.currentAddressData)

		if (this.currentAddressData.country || this.currentAddressData.countryCode) {
			this.addressGroupForm.controls['country'].disable();
		} else {
			this.addressGroupForm.controls['country'].enable();
		}

		if (this.currentAddressData.address) {
			this.addressGroupForm.controls['address'].disable();
		} else {
			this.addressGroupForm.controls['address'].enable();
		}

		if (this.currentAddressData.houseNumber) {
			this.addressGroupForm.controls['houseNumber'].disable();
		} else {
			this.addressGroupForm.controls['houseNumber'].enable();
		}

		if (this.currentAddressData.city) {
			this.addressGroupForm.controls['city'].disable();
		} else {
			this.addressGroupForm.controls['city'].enable();
		}

		if (this.currentAddressData.postalCode) {
			this.addressGroupForm.controls['postalCode'].disable();
		} else {
			this.addressGroupForm.controls['postalCode'].enable();
		}

		if (this.currentAddressData.region) {
			this.addressGroupForm.controls['region'].disable();
		} else {
			this.addressGroupForm.controls['region'].enable();
		}
	}
}
