import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentMethods } from '../models/payments';

@Component({
  selector: 'app-payment-complete-confirm-dialog',
  templateUrl: './payment-complete-confirm-dialog.component.html',
  styleUrls: ['./payment-complete-confirm-dialog.component.scss']
})
export class PaymentCompleteConfirmDialogComponent implements OnInit {
	
	paymentMethods = PaymentMethods
	paymentMethod: PaymentMethods = undefined as any

	constructor(
		public dialogRef: MatDialogRef<PaymentCompleteConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			paymentStatus: "succeeded" | "failure" | "pending"
			data?: string
		}
	) {}

	ngOnInit() {}

	cancelClick() {
		this.dialogRef.close({ action: 'continue' });
	}

	proceedClick() {
		this.dialogRef.close({ action: 'continue' });
	}
}
