<div [formGroup]="addressGroupForm" fxLayout="column" style="width: 100%">
	<div fxLayout="row wrap" fxLayoutGap="20px" style="width: 100%">
		<mat-form-field class="dash-form-field-add field-full-width" appearance="outline">
			<mat-label>{{ 'search_address' | translate }}</mat-label>
			<input
				[class]="'x' + inputClass"
				type="text"
				matInput
				maxlength="150"
				autocomplete="off"
				matGoogleMapsAutocomplete
				#matGoogleMapsAutocomplete="matGoogleMapsAutocomplete"
				[types]="['address']"
				[formControl]="addressAutoCompleteForm"
				(onAutocompleteSelected)="onAutocompleteSelected($event)"
				(onChange)="onAddressAutoCompleteChanged($event)"
				(onLocationSelected)="onLocationSelected($event)"
			/>
		</mat-form-field>
	</div>
	<div *ngIf="addressShown" [@slideInOut] fxLayout="row wrap" fxLayoutAlign="start center	" fxLayoutGap="20px">
		<mat-form-field
			class="dash-form-field-add"
			appearance="outline"
			fxFlex="200px"
			(click)="onClickField('address')"
		>
			<mat-label>{{ 'com_address' | translate }}</mat-label>
			<input type="text" matInput formControlName="address" maxlength="50" autocomplete="off" />
			<img
				*ngIf="editEnabledField['address'] && !addressGroupForm.controls['address'].enabled"
				class="lapis"
				src="./assets/img/icon_edit_blue.svg"
				(click)="onClickEdit('address'); $event.stopPropagation()"
			/>
		</mat-form-field>

		<mat-form-field
			class="dash-form-field-add"
			appearance="outline"
			fxFlex="100px"
			(click)="onClickField('houseNumber')"
		>
			<mat-label>{{ 'com_house_number' | translate }}</mat-label>
			<input type="text" matInput formControlName="houseNumber" maxlength="10" autocomplete="off" />
			<img
				*ngIf="editEnabledField['houseNumber'] && !addressGroupForm.controls['city'].enabled"
				class="lapis"
				src="./assets/img/icon_edit_blue.svg"
				(click)="onClickEdit('houseNumber'); $event.stopPropagation()"
			/>
		</mat-form-field>

		<mat-form-field class="dash-form-field-add" appearance="outline" fxFlex="200px" (click)="onClickField('city')">
			<mat-label>{{ 'com_city' | translate }}</mat-label>
			<input type="text" matInput formControlName="city" maxlength="30" autocomplete="off" />
			<img
				*ngIf="editEnabledField['city'] && !addressGroupForm.controls['city'].enabled"
				class="lapis"
				src="./assets/img/icon_edit_blue.svg"
				(click)="onClickEdit('city'); $event.stopPropagation()"
			/>
		</mat-form-field>

		<mat-form-field
			class="dash-form-field-add"
			appearance="outline"
			fxFlex="80px"
			(click)="onClickField('postalCode')"
		>
			<mat-label>{{ 'com_postal_code' | translate }}</mat-label>
			<input
				type="text"
				matInput
				formControlName="postalCode"
				minlength="4"
				maxlength="10"
				pattern="[A-Za-z0-9-_]*"
				autocomplete="off"
			/>
			<img
				*ngIf="editEnabledField['postalCode'] && !addressGroupForm.controls['postalCode'].enabled"
				class="lapis"
				src="./assets/img/icon_edit_blue.svg"
				(click)="onClickEdit('postalCode'); $event.stopPropagation()"
			/>
		</mat-form-field>

		<div fxFlex="200px" style="position: relative">
			<mat-select-country
				style="margin-bottom: 20px"
				(click)="onClickField('country')"
				appearance="outline"
				fxFlex="200px"
				[itemsLoadSize]="20"
				[label]="'com_country' | translate"
				formControlName="country"
				onCountrySelected="onCountrySelected($event)"
			>
			</mat-select-country>
			<img
				*ngIf="editEnabledField['country'] && !addressGroupForm.controls['country'].enabled"
				class="lapis-country"
				src="./assets/img/icon_edit_blue.svg"
				(click)="onClickEdit('country'); $event.stopPropagation()"
			/>
		</div>

		<mat-form-field
			class="dash-form-field-add"
			appearance="outline"
			fxFlex="200px"
			(click)="onClickField('region')"
		>
			<mat-label>{{ 'com_region' | translate }}</mat-label>
			<input type="text" matInput formControlName="region" maxlength="40" autocomplete="off" />
			<img
				*ngIf="editEnabledField['region'] && !addressGroupForm.controls['region'].enabled"
				class="lapis"
				src="./assets/img/icon_edit_blue.svg"
				(click)="onClickEdit('region'); $event.stopPropagation()"
			/>
		</mat-form-field>

		<img
			class="invoice-delete-address"
			src="./assets/img/close.svg"
			(click)="onDeleteAddress()"
			[matTooltip]="'com_remove_address' | translate"
		/>
	</div>
</div>
