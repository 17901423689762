import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { SnotifyService } from 'ng-snotify';
import { catchError, map, of, startWith, Subject, switchMap, take, takeUntil } from 'rxjs';
import { MaintenanceRequest } from 'src/app/models/properties';
import { AttachedFile } from 'src/app/models/fileRm';
import { BackendService } from 'src/app/services/backend.service';
import { LoadingService } from 'src/app/services/loading.service';
import { DeviceStateService } from 'src/app/services/device-state.service';
import { AppConstants } from 'src/app/utils/app-constants';
import { AppUtils } from 'src/app/utils/app-utils';
import exifr from 'exifr';
import { NgxPicaService } from '@digitalascetic/ngx-pica';
import { FileUploaderService } from 'src/app/services/fileUploaderService';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-maintenance-add',
  templateUrl: './maintenance-add.component.html',
  styleUrls: ['./maintenance-add.component.scss']
})
export class MaintenanceAddComponent implements OnInit, OnDestroy {

    @ViewChild('file', { static: true }) elUploadFile: ElementRef | undefined;
    

    title = new FormControl('', [Validators.required]);
    category = new FormControl('', [Validators.required]);
    description = new FormControl('', [Validators.required]);

    maintenanceCategorieCodes : string[] = [];
    maintenanceCategories : string[] = [];

    maxPictures : number = 10;
    stagingArea: AttachedFile[] = [];
    uncommittedChanges = false;
    maintenancePhotos : string[] = [];


    tenantId : string = "";
    propertyId : string = "";
    unitId : string = "";


    // Mobile
	mobile : boolean = false;


	private ngUnsubscribe = new Subject<void>();


	constructor(
		private readonly location: Location,
		private readonly backendService: BackendService,
		private readonly deviceStateService: DeviceStateService,
        private readonly loadingService: LoadingService,
        private readonly toastService: SnotifyService,
        private ng2ImgMaxService: NgxPicaService,
        private readonly fileUploaderService: FileUploaderService,
        private readonly gaService: GoogleAnalyticsService,
        private readonly translateService: TranslateService,
	) {
		this.mobile = deviceStateService.isMobileResolution();
	}

	ngOnInit(): void {

		this.deviceStateService.screenName = 'menu_title_add_maintenance';

        const appUtils = new AppUtils(this.translateService);

        this.maintenanceCategorieCodes = Object.keys(appUtils.maintenanceCategories);
        this.maintenanceCategories = Object.values(appUtils.maintenanceCategories);


        this.backendService.loadBasicUserData()
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(() => {});
            

        this.backendService.tenantId$
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(tId => this.tenantId = tId);
            
        this.backendService.loadTenantLandlordData()
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(landlordData => {

                this.propertyId = landlordData.currentProperty?.id ?? "";
                this.unitId = landlordData.currentUnit?.id ?? "";
            });


		this.deviceStateService.screenResized
			.pipe(
				startWith(this.mobile),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(isMobile => this.mobile = isMobile);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	backClick () {
		this.location.back();
	}

    attachPhoto() {
        if (!!this.elUploadFile) {
            this.elUploadFile.nativeElement.click();
        }
    }

    async picturesSelected(event : any) {
		const files = event.target.files;

		const validPictureFiles: File[] = [];

		for (let i = 0; i < files.length; i++) {
			const file = files.item(i);
			if (file.type.match('image.*')) {
				
                // Max 8 MB
				if (file.size <= 8 * 1024 * 1024) {
					if (this.stagingArea.length + validPictureFiles.length < this.maxPictures) {
						validPictureFiles.push(file);
					}
				} else {
					this.toastService.error(
                        this.translateService.instant('toasts.toast_error_file_too_big_desc'),
                        this.translateService.instant('toasts.toast_error_file_too_big_title'),
						AppConstants.TOAST_STD_CONFIG
					);
				}
			}
		}
		if (validPictureFiles.length > 0) {

            if (validPictureFiles.length > 1) {
                this.loadingService.show(this.translateService.instant('loadings.com_loading_files'));
            } else {
                this.loadingService.show(this.translateService.instant('loadings.com_loading_file'));
            }

			this.uncommittedChanges = true;

			const promises = validPictureFiles.map(file => {
				return new Promise<{ url: string; file: File | undefined }>(async (resolve, reject) => {
					// tslint:disable-next-line: no-shadowed-variable
					const data = await new Promise(async (resolve, reject) => {
						const metadata = await exifr.parse(file, {});

						let finalImage = file;

						let process = false;

						if (metadata) {
							const imageWidth = metadata.ImageWidth || 0;
							const imageHeight = metadata.ImageHeight || 0;

							if (imageWidth === 0 || imageHeight === 0) {
								process = true;
								console.warn(
									`Picture ${file.name} has no metadata about size but will be resized anyway`
								);
							} else if (imageWidth > 1500 || imageHeight > 1500) {
								process = true;
								console.warn(`Picture ${file.name} is too large and will be resized`);
							}
						} else {
							process = true; // No idea why this has no metadata
							console.warn(`Picture ${file.name} has no metadata but will be resized anyway`);
						}

						if (process) {
							this.ng2ImgMaxService
								.resizeImage(file, 1500, 1500, {
									exifOptions: { forceExifOrientation: false },
									aspectRatio: { keepAspectRatio: true }
								})
								.pipe(
									take(1),
									switchMap((resizedImage: File) => {
										finalImage = resizedImage;
										return this.ng2ImgMaxService.compressImage(resizedImage, 0.5);
									}),
									map((compressedImage: File) => (finalImage = compressedImage)),
									catchError((err: string) => {
										console.error(err);
										return of(finalImage);
									})
								)
								.subscribe(() => resolve(finalImage));
						} else {
							resolve(file);
						}
					});

					if (!data) {
						resolve({
							url: "",
							file: undefined
						});
					}

					const reader = new FileReader();
					reader.readAsDataURL(data as Blob);
					reader.onload = _event => {
						resolve({
							url: reader.result as string,
							file
						});
					};
				});
			});

			const chosenPics = await Promise.all(promises);

            this.stagingArea.push(
				...chosenPics
					.filter(it => it.url)
					.map(
						picData => (
                            {
                                name: picData.file?.name || "",
                                size: picData.file?.size || 0,
                                publicUrl: picData.url, // This is temporary. It actually holds the pic data
                                creationTime: Date.now()
                            }
                        )
					)
			);

            this.stagingArea = this.stagingArea.concat([]);

            this.loadingService.hide();

		}
	}

    photosChanged (photos: AttachedFile[]) {
        
        this.stagingArea = photos.concat([]);
    }

    async sendRequest () {

        this.title.markAsTouched();
        this.description.markAsTouched();
        this.category.markAsTouched();

        if (!this.title.valid || !this.description.valid || !this.category.valid) {

            this.toastService.error(
                this.translateService.instant('toasts.toast_error_fields_desc'),
                this.translateService.instant('toasts.toast_error_fields_title'),
                AppConstants.TOAST_STD_CONFIG
            );
            return;
        }

        if (this.stagingArea.length === 0) {
            
            this.toastService.error(
                this.translateService.instant('toasts.toast_error_attach_photo_desc'),
                this.translateService.instant('toasts.toast_error_attach_photo_title'),
                {
                    ...AppConstants.TOAST_STD_CONFIG,
                    timeout: 4000
                }
            );
            return;
        }

        
        this.loadingService.show(this.translateService.instant('loadings.adding_maintenance_loading'));


        let categoryCode = "other";
        const indx = this.maintenanceCategories.indexOf(this.category.value);
        if (indx >= 0 && indx < this.maintenanceCategorieCodes.length) {
            categoryCode = this.maintenanceCategorieCodes[indx];
        }

        const m : MaintenanceRequest = {
            id: '',
            title: this.title.value,
            message: this.description.value,
            reportedOn: new Date().getTime(),
            status: 0,
            tenantId: this.tenantId,
            propertyId: this.propertyId,
            unitId: this.unitId,
            priority: 1,
            resolvedOn: 0,
            category: categoryCode,
            photos: []

            //files (UNUSED HERE)
            //migratedOn (UNUSED HERE)
        }

        m.photos = await this.fileUploaderService.syncPhotos('maintenances', this.stagingArea, true);

        this.backendService.addMaintenanceRequest(m).then(_ => {

            this.gaService.gtag('event', 'maintenance_request_sent', {
                'event_label': 'Maintenance request sent',
                'event_category': 'maintenances',
                'category': m.category,
                'num_attachments': m.photos?.length || 0
            });

            this.toastService.success(
                this.translateService.instant('toasts.toast_success_add_maintenance_desc'),
                this.translateService.instant('toasts.toast_success_add_maintenance_title'),
                AppConstants.TOAST_STD_CONFIG
            );
            this.backClick();

        }).catch(err => {

            this.toastService.error(
                this.translateService.instant('toasts.toast_error_add_maintenance_desc'),
                this.translateService.instant('toasts.toast_error_add_maintenance_title'),
                AppConstants.TOAST_STD_CONFIG
            );

        }).finally(() => {

            this.loadingService.hide();
        });
    }
}
