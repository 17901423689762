import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { startWith, Subject, takeUntil } from 'rxjs';
import { AttachedFile } from 'src/app/models/fileRm';
import { MaintenanceRequest } from 'src/app/models/properties';
import { BackendService } from 'src/app/services/backend.service';
import { LoadingService } from 'src/app/services/loading.service';
import { DeviceStateService } from 'src/app/services/device-state.service';
import { AppConstants } from 'src/app/utils/app-constants';
import { AppUtils } from 'src/app/utils/app-utils';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmDialogComponent } from 'src/app/shared/delete-confirm-dialog/delete-confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit, OnDestroy {

    appUtils: AppUtils;

    currentMaintenanceId : string = "";
	tenantId : string = "";

	maintenance : MaintenanceRequest | undefined;
	photosAndFilesTogether : AttachedFile[] = [];	
	
	displayedColumns : string[] = ["status", "title", "category", "enteredBy", "enteredOn"];


    isLoading : boolean = true;


	// Mobile
	mobile : boolean = false;


	private ngUnsubscribe = new Subject<void>();


	constructor(
        private route: ActivatedRoute,
		private readonly location: Location,
		private readonly deviceStateService: DeviceStateService,
        private readonly backendService: BackendService,
        private readonly loadingService: LoadingService,
        private readonly toastService: SnotifyService,
        private readonly gaService: GoogleAnalyticsService,
        private readonly dialog: MatDialog,
        private readonly translateService: TranslateService
	) {
		this.mobile = deviceStateService.isMobileResolution();

        this.appUtils = new AppUtils(translateService);

        this.route.params.subscribe(params => {
            if (!!params['mid']) {
                this.currentMaintenanceId = params['mid'];
            } else {
                this.backClick();
            }
        });
	}

	ngOnInit(): void {

        this.deviceStateService.screenName = 'menu_title_your_maintenance';
        this.loadingService.show(this.translateService.instant('loadings.loading_loading'));

        this.deviceStateService.screenResized
			.pipe(
				startWith(this.mobile),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(isMobile => this.mobile = isMobile);

            
        this.backendService.loadBasicUserData()
        .pipe(
            takeUntil(this.ngUnsubscribe)
        )
        .subscribe(() => {});

        
        this.backendService.tenantId$
        .pipe(
            takeUntil(this.ngUnsubscribe)
        )
        .subscribe(tId => this.tenantId = tId);


        this.backendService.getMaintenance(this.currentMaintenanceId)
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(async maintenance => {

                if (!!maintenance) {
                    this.maintenance = maintenance;
                    this.photosAndFilesTogether = (this.maintenance?.photos || []).map((ph, indx) => new AttachedFile("Photo " + indx, 0, ph, 0));
                    this.photosAndFilesTogether = this.photosAndFilesTogether.concat(await this.backendService.loadFiles("maintenances", maintenance.id));

                    this.isLoading = false;
                    this.loadingService.hide();
                }
            });
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	backClick () {
		this.location.back();
	}

    getCategoryName () {
        if (!!this.maintenance) {
            return this.appUtils.getMaintenanceCategoryName(this.maintenance);
        }
        
        return "";
    }
}
