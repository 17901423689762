<div *ngIf="!isLoading" fxLayout="row" fxLayoutAlign="center flex-start">

    <div fxLayout="column" fxLayoutAlign="flex-start center" class="maintenances-main-content" [ngClass]="{'maintenances-main-content-desk': !mobile, 'maintenances-main-content-mobile': mobile }">
        
        <div *ngIf="!mobile" fxLayout="row" fxLayoutAlign="flex-start center" class="top-row">
            <img class="img-back" src="../../assets/img/rm_icon_arrow_left.svg" (click)="backClick()" />
            <p class="regular-20 no-margin" [innerHTML]="'screenTitles.maintenances_title' | translate"></p>
        </div>
        <div *ngIf="mobile" fxLayout="row" fxLayoutAlign="space-between center" class="top-row-mobile">
            <p class="regular-15 no-margin" [innerHTML]="'screenTitles.maintenances_title_2' | translate"></p>
            <button mat-button class="button-primary button-small" style="margin-right: 10px;" (click)="addMaintenance()">{{ 'action_add_maintenance' | translate }}</button>
        </div>
        
        <div *ngIf="!mobile" class="full-width" fxLayout="column" fxLayoutAlign="flex-start flex-start">
            <div class="full-width" fxLayout="row" fxLayoutAlign="flex-start flex-start">
                <button mat-button class="button-primary button-small" style="margin-right: 10px;" (click)="addMaintenance()">{{ 'action_add_maintenance' | translate }}</button>
                <app-roommate-tabs (clickedOption)="onTabClicked($event)" [options]="tabs"></app-roommate-tabs>
            </div>
            <div class="sep-row"></div>
        </div>
        <div *ngIf="mobile">
            <app-roommate-tabs (clickedOption)="onTabClicked($event)" [options]="tabs"></app-roommate-tabs>
            <div class="sep-row"></div>
        </div>

        
        <mat-card class="no-padding" *ngIf="!mobile">
            <table mat-table [dataSource]="filteredMaintenances" style="z-index: 10">
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>{{ 'com_up_status' | translate }}</th>
                    <td mat-cell *matCellDef="let m" style="white-space: nowrap">
                        <div *ngIf="m.status === 0" class="rm-tag-view tag-primary">
                            <p class="semi-bold-12 color-white">{{ 'maintenance_open_one' | translate }}</p>
                        </div>
                        <div *ngIf="m.status === 1" class="rm-tag-view tag-green">
                            <p class="semi-bold-12 color-white">{{ 'maintenance_closed_one' | translate }}</p>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef>{{ 'com_up_title' | translate }}</th>
                    <td mat-cell *matCellDef="let m" style="white-space: nowrap">
                        <p class="regular-12 no-margin">{{ m.title }}</p>
                    </td>
                </ng-container>
                <ng-container matColumnDef="category">
                    <th mat-header-cell *matHeaderCellDef>{{ 'com_up_category' | translate }}</th>
                    <td mat-cell *matCellDef="let m" style="white-space: nowrap">
                        <p class="regular-12 no-margin">{{ getCategoryName(m) }}</p>
                    </td>
                </ng-container>
                <ng-container matColumnDef="attachments">
                    <th mat-header-cell *matHeaderCellDef>{{ 'com_up_attachments' | translate }}</th>
                    <td mat-cell *matCellDef="let m" style="white-space: nowrap">
                        <p *ngIf="((m.photos || []).length + (m.files || []).length) > 0; else noAttachments" class="regular-12 no-margin">{{ 'com_yes' | translate }}</p>
                        <ng-template #noAttachments>
                            <p class="semi-bold-12 no-margin">-</p>
                        </ng-template>
                    </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let m" style="white-space: nowrap;" class="cell-align-right">
                        <button mat-button class="button-primary button-small" (click)="viewMaintenance(m)">{{ 'com_view' | translate }}</button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"
                ></tr>
            </table>
        </mat-card>

        <ng-container *ngIf="mobile">
            <ng-container *ngFor="let m of filteredMaintenances">
                <app-mobile-maintenance-card [maintenance]="m" (viewMaintenance)="viewMaintenance($event)"></app-mobile-maintenance-card>
            </ng-container>
        </ng-container>

        <div *ngIf="maintenances.length === 0" fxLayout="column" fxLayoutAlign="center center" style="margin-top: 30px;">
            <img src="../../assets/img/RYN-logo.svg" />
            <p class="regular-15 text-center" style="margin-top: 25px; width: 90%;" [innerHTML]="'emptyScreens.empty_maintenances' | translate:{tenantNameSurname: tenantNameSurname}">
            </p>
        </div>
    </div>
</div>
