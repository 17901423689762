import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { startWith, Subject, takeUntil } from 'rxjs';
import { DeviceStateService } from '../services/device-state.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit, OnDestroy {

	mobile : boolean = false;
	
	errorType : string = '';

	private ngUnsubscribe = new Subject<void>();

	constructor(
		private readonly router: Router,
		private readonly deviceStateService: DeviceStateService
	) {
		this.deviceStateService.screenName = 'menu_title_error';
		this.mobile = deviceStateService.isMobileResolution();
	}

	ngOnInit(): void {

		this.deviceStateService.screenResized
			.pipe(
				startWith(this.mobile),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(isMobile => this.mobile = isMobile);

		if (
			!!history.state && !!history.state.errorType && (
				history.state.errorType === 'user' ||
				history.state.errorType === 'tenant'
			)
		) {
			this.errorType = history.state.errorType;
		} else {
			this.router.navigate(['/']);
		}
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
