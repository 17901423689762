<div fxLayout="row" fxLayoutAlign="center flex-start">

    <div fxLayout="column" fxLayoutAlign="flex-start center" class="personaldata-main-content" [ngClass]="{'personaldata-main-content-desk': !mobile, 'personaldata-main-content-mobile': mobile }">

        <div *ngIf="!mobile" fxLayout="row" fxLayoutAlign="flex-start center" class="top-row">
            <img class="img-back" src="../../assets/img/rm_icon_arrow_left.svg" (click)="backClick()" />
            <p *ngIf="!isDelete" class="regular-20 no-margin" [innerHTML]="'screenTitles.request_personal_data_title' | translate">
            </p>
            <p *ngIf="isDelete" class="regular-20 no-margin" [innerHTML]="'screenTitles.delete_account_title' | translate">
            </p>
        </div>
        <div *ngIf="mobile" fxLayout="row" fxLayoutAlign="flex-start center" class="top-row-mobile">
            <p *ngIf="!isDelete" class="regular-15" [innerHTML]="'screenTitles.request_personal_data_title_2' | translate">
            </p>
            <p *ngIf="isDelete" class="regular-15" [innerHTML]="'screenTitles.delete_account_title_2' | translate">
            </p>
        </div>

        <div [ngClass]="{'page-inner-content-desk': !mobile, 'page-inner-content-mobile': mobile}">
            <div fxLayout="column" fxLayoutAlign="flex-start flex-start" style="width: 100%;">
                <div class="sep-row-light"></div>
            </div>
    
            <div fxLayout="row" fxLayoutAlign="flex-start flex-start" style="width: 100%;">
                <p *ngIf="!isDelete" class="regular-15 no-margin" [innerHTML]="'request_personal_data_main_desc' | translate">
                </p>
                <p *ngIf="isDelete" class="regular-15 no-margin" [innerHTML]="'delete_account_main_desc' | translate">
                </p>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%; margin-top: 40px;">
                <button mat-button class="button-negative-empty" [ngClass]="{'button-small': mobile }" (click)="backClick()">{{ 'com_cancel' | translate }}</button>
                <button mat-button class="button-positive" [ngClass]="{'button-small': mobile }" (click)="contactUsClick()">{{ 'com_send' | translate }}</button>
            </div>
        </div>
    </div>
</div>