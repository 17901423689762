import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
	selector: 'app-roommate-tabs',
	templateUrl: './roommate-tabs.component.html',
	styleUrls: ['./roommate-tabs.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class RoommateTabsComponent {
	@Input()
	public selectedIndex?: number = 0;
	@Input()
	public options: string[] = [];

	@Output()
	public clickedOption = new EventEmitter<number>();

	constructor() {}

	onSelectedTabChange(option: MatTabChangeEvent) {

		this.clickedOption.emit(option.index);
	}
}
