import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ChatMetadata } from '../models/message';
//import { ChatMetadata } from 'src/app/models/message.model';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class SocketService {
	private detachSubject = new Subject<void>();

	private landlordId: string = '';
    private tenantId: string = '';

	constructor(private authService: AuthService, private readonly socket: Socket) {
		this.socket.on('connect', () => {
            console.log('Socket: connect');
			if (this.landlordId !== '') {
				this.registerForUpdates(this.landlordId, this.tenantId);
			}
		});

        this.socket.on('disconnect', () => {
			console.log('Socket: disconnect');
		});
	}

	registerForUpdates(landlordId: string, tenantId: string) : Observable<ChatMetadata> {

        this.landlordId = landlordId;
        this.tenantId = tenantId;

		if (this.socket.ioSocket.connected) {
            console.log('Socket: [TRY] register for realtime updates');

            this.authService
                .getTokenHeader()
                .pipe(take(1))
                .subscribe(token => {
                    this.socket.emit('registerTenantUpdates', { landlordId, tenantId, token });
                    console.log('Socket: [DONE] register for realtime updates');
                });
        }

		return this.socket.fromEvent<ChatMetadata>('tenantChatUpdates').pipe(takeUntil(this.detachSubject));
	}

	unregisterForUpdates() {
		this.detachSubject.next();
		this.landlordId = '';
        this.tenantId = '';
		this.socket.emit('unregisterTenantUpdates');
		//this.socket.disconnect();
		//this.socket.connect();
        console.log('Socket: unregister for realtime updates');
	}
}
