import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-payments-setup-reminder-dialog',
  templateUrl: './payments-setup-reminder-dialog.component.html',
  styleUrls: ['./payments-setup-reminder-dialog.component.scss']
})
export class PaymentsSetupReminderDialogComponent implements OnInit {
	
	constructor(
		public dialogRef: MatDialogRef<PaymentsSetupReminderDialogComponent>
	) { }

	ngOnInit() {}

	cancelClick() {
		this.dialogRef.close({ action: 'none' });
	}

	proceedClick() {
		this.dialogRef.close({ action: 'navigate' });
	}
}
