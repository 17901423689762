<div fxLayout="row" fxLayoutAlign="flex-start flex-start" style="width: 100%">
	<div fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="flex-start flex-start">
		<ng-container *ngFor="let t of tags; let index = index">
			<div
				class="div-item"
				[ngClass]="{
					'clickable-item': (isClickable && isEnabled) || isSelectable,
					'unselected-item': (isSelectable && !isSelected(index)) || allNotHighlighted,
					'error-item': isError(index),
					'smaller-margin': withSmallerMargin
				}"
				(click)="tagClick(index)"
			>
				<p class="regular-15 p-item">{{ t }}</p>
				<img
					*ngIf="
						((isDeletable && (!singleSelect || (singleSelect && tags.length > 1))) || allDeletable) &&
						(!first2Undeletable || index > 1)
					"
					(click)="deleteClick(index); $event.stopPropagation()"
					src="./assets/img/close_light_blue.svg"
				/>
			</div>
		</ng-container>
	</div>
</div>
