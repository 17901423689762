import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { BackendService } from "./backend.service";

declare let Intercom: any;

@Injectable({
	providedIn: 'root'
})
export class IntercomService {
  debugService = false;
	log(...args: any) {
		this.debugService && console.log(...args);
	}


	constructor(
		public readonly router: Router,
		private authService: AuthService,
    private backendService: BackendService
	) {}

	startIntercom() {
    this.log(`intercom-service started`);

		this.backendService.loadBasicUserData()
			.pipe(filter((userData) => !!userData))
			.subscribe((userData) => {
				this.log(
					`intercom-service intercom for ${userData.name} ${userData.surname} (${userData.id})`
				);

				if (userData && userData.id && !this.authService.isGodUser()) {
					Intercom('boot', {
						api_base: 'https://api-iam.intercom.io',
						app_id: 'ifs122wo',
						email: userData.email,
						user_id: userData.id,
						name: `${userData.name} ${userData.surname}`,
						custom_attributes: JSON.stringify(userData),
						language_override: userData.language,
						Tenantname: userData.name,
						user_type: "tenant",
            hide_default_launcher: true
					});
				} else {
					Intercom('hide');
				}
			});
	}

	displayHelpCenter() {
		Intercom('show');
	}

	hideHelpCenter() {
		Intercom('hide');
	}
}
