export class AttachedFile {

	constructor(
		public name: string,
		public size: number = 0,
		public publicUrl: string,
		public creationTime: number,
	) {}
}


export class PendingFile {
	constructor(
		public name: string,
		public size: number,
		public isShared: boolean,
		public insertId: number,
		public newFile: boolean,
		public creationTime: number,
		public url?: string,
		public file?: File
	) {}
}