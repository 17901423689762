import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { startWith, Subject, takeUntil } from 'rxjs';
import { MaintenanceRequest } from '../models/properties';
import { BackendService } from '../services/backend.service';
import { LoadingService } from '../services/loading.service';
import { DeviceStateService } from '../services/device-state.service';
import { AppUtils } from '../utils/app-utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-maintenances',
  templateUrl: './maintenances.component.html',
  styleUrls: ['./maintenances.component.scss']
})
export class MaintenancesComponent implements OnInit, OnDestroy {

    appUtils: AppUtils;

	tabs = ['maintenances_all_more_than_one', 'maintenances_open_more_than_one', 'maintenances_closed_more_than_one'];
    currentPageIndex : number = 0;

    tenantNameSurname : string = "";

    maintenances: MaintenanceRequest[] = [];
    filteredMaintenances : MaintenanceRequest[] = [];
    displayedColumns : string[] = ["status", "title", "category", "attachments", "action"];


    isLoading : boolean = true;

	// Mobile
	mobile : boolean = false;


	private ngUnsubscribe = new Subject<void>();


	constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
		private readonly location: Location,
		private readonly deviceStateService: DeviceStateService,
        private readonly loadingService: LoadingService,
        private readonly backendService: BackendService,
        private readonly translateService: TranslateService
	) {
		this.mobile = deviceStateService.isMobileResolution();

        this.appUtils = new AppUtils(translateService);
	}

	ngOnInit(): void {

        this.deviceStateService.screenName = 'menu_title_your_maintenances';
		this.loadingService.show(this.translateService.instant('loadings.loading_loading'));

		this.deviceStateService.screenResized
			.pipe(
				startWith(this.mobile),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(isMobile => this.mobile = isMobile);


        this.backendService.loadBasicUserData()
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(userData => {
                this.tenantNameSurname = userData.name + " " + userData.surname;
            });

        
        this.backendService.resetCounter('maintenances');
        
        
		this.backendService.getMaintenances()
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(maintenances => {

                this.maintenances = maintenances;
                this.filteredMaintenances = this.maintenances;

                this.isLoading = false;
                this.loadingService.hide();
            });
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	backClick () {
		this.location.back();
	}


	onTabClicked (indx : number) {
        this.currentPageIndex = indx;

        if (this.currentPageIndex === 0) {
            
            this.filteredMaintenances = this.maintenances;

        } else if (this.currentPageIndex === 1) {
            
            this.filteredMaintenances = this.maintenances.filter(m => m.status === 0);

        } else if (this.currentPageIndex === 2) {

			this.filteredMaintenances = this.maintenances.filter(m => m.status === 1);
        }
    }

    getCategoryName (m: MaintenanceRequest) {
        return this.appUtils.getMaintenanceCategoryName(m);
    }

	addMaintenance () {
		this.router.navigate(['add'], { relativeTo: this.route });
	}

    viewMaintenance (m: MaintenanceRequest) {
        this.router.navigate([m.id], { relativeTo: this.route });
    }
}
