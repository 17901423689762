<div id="dashboard-container">
	<div id="menu-top-bar" [ngClass]="{'menu-top-bar-desk': !mobile, 'menu-top-bar-mobile': mobile }">
		<div fxLayout="row" fxLayoutAlign="space-between center" class="menu-top-bar-inner">
			<img
				*ngIf="mobile && isHome()"
				id="img-logo"
				class="clickable"
				src="../../assets/img/RYN-logo.svg"
			/>
			<img
				*ngIf="mobile && !isHome()"
				id="img-logo"
				class="clickable"
				src="../../assets/img/rm_icon_back.svg"
				(click)="backClick()"
			/>
            <img
                *ngIf="!mobile"
                id="img-logo-desk"
				class="clickable"
				src="../../assets/img/RYN-logo-negative-orange.svg"
				(click)="goHome()"
			/>

            <p class="color-orange absolute-center-text" style="margin-bottom: 0px;" [ngStyle]="{'margin-top': deviceStateService.iOS ? '25px' : '0px' }" [ngClass]="{'semi-bold-15': !mobile, 'semi-bold-12': mobile }">{{ 'screenNames.' + deviceStateService.screenName | translate }}</p>

			<div class="menu-actions">
				<div
					class="btn-intercom-container"
					[ngStyle]="{'height': mobile ? '22px' : '32px' }"
					(click)="openHelpCenter()"
				>
					<img src="./assets/img/help_center_icon.svg" [ngStyle]="{'width': mobile ? '22px' : '32px', 'height': mobile ? '22px' : '32px' }" />
				</div>

				<img
									*ngIf="mobile"
					id="img-hamburger"
					class="clickable"
					src="../../assets/img/rm_menu_hamburger.svg"
					[matMenuTriggerFor]="menu"
				/>
				<div
						*ngIf="!mobile"
						class="dash-right-actions-container"
				>
						<div class="dash-right-actions-inner" [matMenuTriggerFor]="menu">
								<img class="dash-right-action-img" src="../../assets/img/rm_menu_hamburger_desk.svg" />
								<img class="dash-right-action-img" src="../../assets/img/rm_icon_profile_orange.svg" />
						</div>
				</div>
			</div>

			<mat-menu #menu="matMenu" xPosition="before">
				<!--<button mat-menu-item fxLayout="row" fxLayoutAlign="space-between center" (click)="contactUsClick()">
						<p class="regular-12 no-margin">{{ 'com_support' | translate }}</p>
						<img class="menu-img" src="../../assets/img/rm_icon_message.svg" />
				</button>-->
				<button mat-menu-item fxLayout="row" fxLayoutAlign="space-between center" (click)="logoutClick()">
					<p class="regular-12 no-margin">{{ 'com_logout' | translate }}</p>
					<img class="menu-img" src="../../assets/img/rm_icon_logout.svg" />
				</button>
			</mat-menu>
		</div>
	</div>

	<div fxLayout="column" id="pages-container" [ngClass]="{'pages-container-desk': !mobile, 'pages-container-mobile': mobile, 'grey-back': (isHome() || isCommunications() || (!mobile && (isChat() || isSettings()))) }">
		<div fxFlex fxLayoutAlign="flex-start center" fxLayout="row" style="width: 100%">
			<div fxFlex="100%" [ngClass]="{'main-page-container-desk': !mobile, 'main-page-container-mobile': mobile }" [@routeAnimations]="prepareRoute(outlet)">
				<router-outlet #outlet="outlet"></router-outlet>
			</div>
		</div>
	</div>
</div>
