import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-passwordless-login',
  templateUrl: './passwordless-login.component.html',
  styleUrls: ['./passwordless-login.component.scss']
})
export class PasswordlessLoginComponent implements OnInit {
	
    constructor(
		public authService: AuthService,
		public spinner: NgxSpinnerService,
		private router: Router,
		private route: ActivatedRoute,
		private loadingService: LoadingService,
        private readonly gaService: GoogleAnalyticsService,
        private readonly translateService: TranslateService
	) {
		this.spinner.hide();
	}

	async ngOnInit() {
        this.loadingService.show(this.translateService.instant('loadings.login_loading'));

		const code = this.route.snapshot.queryParamMap.get('token');
        const target = this.route.snapshot.queryParamMap.get('target') || undefined;

		if (!!code && code !== '') {
            console.log("Passwordless login using token: " + code);
			
            await this.authService.passwordlessLogin(code, target).then(() => {
                this.gaService.gtag('event', 'user_passwordless_login', {
                    'event_label': 'User passwordless login',
                    'event_category': 'login'
                });
            });
		} else {
            this.router.navigate(['/login']);
        }
	}
}
