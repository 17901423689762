import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { combineLatest, combineLatestAll, startWith, Subject, takeUntil } from 'rxjs';
import { DeviceStateService } from '../services/device-state.service';
import { NgImageSliderComponent } from 'ng-image-slider';
import { AttachedFile } from '../models/fileRm';
import { BackendService } from '../services/backend.service';
import { LoadingService } from '../services/loading.service';
import { TranslateService } from '@ngx-translate/core';

class Amenities {

    constructor (
         private readonly translateService: TranslateService
    ) {
    }

    public propertyAmenities: { [key: string]: string } = {
        "furnished": this.translateService.instant('propertyAmenities.ctr_prop_amenity_1'),
        "elevator": this.translateService.instant('propertyAmenities.ctr_prop_amenity_2'),
        "air_conditioner": this.translateService.instant('propertyAmenities.ctr_prop_amenity_3'),
        "equipped_kitchen": this.translateService.instant('propertyAmenities.ctr_prop_amenity_4'),
        "balcony_or_terrace": this.translateService.instant('propertyAmenities.ctr_prop_amenity_5'),
        "pool_access": this.translateService.instant('propertyAmenities.ctr_prop_amenity_6'),
        "parking_available": this.translateService.instant('propertyAmenities.ctr_prop_amenity_7'),
        "pets_allowed": this.translateService.instant('propertyAmenities.ctr_prop_amenity_8'),
        "smoking_allowed": this.translateService.instant('propertyAmenities.ctr_prop_amenity_9'),
        "washing_machine": this.translateService.instant('propertyAmenities.ctr_prop_amenity_10'),
        "dryer": this.translateService.instant('propertyAmenities.ctr_prop_amenity_11'),
        "dishwasher": this.translateService.instant('propertyAmenities.ctr_prop_amenity_12'),
        "oven": this.translateService.instant('propertyAmenities.ctr_prop_amenity_13'),
        "tv": this.translateService.instant('propertyAmenities.ctr_prop_amenity_14'),
        "handicap_accessible": this.translateService.instant('propertyAmenities.ctr_prop_amenity_15'),
        "heated_water_system": this.translateService.instant('propertyAmenities.ctr_prop_amenity_16'),
        "heating_system": this.translateService.instant('propertyAmenities.ctr_prop_amenity_17'),
        "private_garden": this.translateService.instant('propertyAmenities.ctr_prop_amenity_18'),
        "wi_fi": this.translateService.instant('propertyAmenities.ctr_prop_amenity_19')
    }

    public unitAmenities: { [key: string]: string } = {
        "furnished": this.translateService.instant('unitAmenities.ctr_units_amenity_1'),
        "air_conditioner": this.translateService.instant('unitAmenities.ctr_units_amenity_2'),
        "heating_system": this.translateService.instant('unitAmenities.ctr_units_amenity_3'),
        "private_bath": this.translateService.instant('unitAmenities.ctr_units_amenity_4'),
        "desk": this.translateService.instant('unitAmenities.ctr_units_amenity_5'),
        "balcony_or_terrace": this.translateService.instant('unitAmenities.ctr_units_amenity_6'),
        "tv": this.translateService.instant('unitAmenities.ctr_units_amenity_7'),
        "chest_drawers": this.translateService.instant('unitAmenities.ctr_units_amenity_8'),
        "storage": this.translateService.instant('unitAmenities.ctr_units_amenity_9'),
        "wardrobe": this.translateService.instant('unitAmenities.ctr_units_amenity_10'),
    }
}



@Component({
  selector: 'app-unit-info',
  templateUrl: './unit-info.component.html',
  styleUrls: ['./unit-info.component.scss']
})
export class UnitInfoComponent implements OnInit, OnDestroy {

	@ViewChild('slideshow') slider: NgImageSliderComponent | undefined;

	currentPageIndex : number = 0;
    tabs = ['com_unit_data', 'com_property', 'com_files'];


    tenantNameSurname : string = "";


    propertyName : string = "";
	unitName : string = "";

    propertyPictures : string[] = [];
    unitPictures : string[] = [];

	pictures : string[] = [];
	picsSliderObj: Array<object> = [];
    
    address : string = "";
    city : string = "";
    country : string = "";

    zip : string = "";
    propertyUnit : string = "";
    stairwell : string = "";
    
    rentValue : number = 0;
	rentCurrency : string = "EUR";

	unitDescription : string = "";
    unitTags: string[] = [];

    propertyDescription : string = "";
    propertyTags: string[] = [];

    files : AttachedFile[] = [];


    isLoading : boolean = true;



	// Mobile
	mobile : boolean = false;


	private ngUnsubscribe = new Subject<void>();

	
	constructor(
		private readonly location: Location,
		private readonly deviceStateService: DeviceStateService,
		private readonly backendService: BackendService,
        private readonly loadingService: LoadingService,
        private readonly translateService: TranslateService
	) {
		this.mobile = deviceStateService.isMobileResolution();
	}

	ngOnInit(): void {

        this.deviceStateService.screenName = 'menu_title_your_unit';
        this.loadingService.show(this.translateService.instant('loadings.loading_loading'));

		
        this.backendService.loadBasicUserData()
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(userData => {
                this.tenantNameSurname = userData.name + " " + userData.surname;
            });

        
        this.backendService.resetCounter('units');
        

		this.backendService.loadTenantLandlordData()
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(async data => {

                if (!!!data.currentLease || !!!data.currentProperty || !!!data.currentUnit) {

                    this.backClick();
                    return;   
                }

                const property = data.currentProperty;
                const unit = data.currentUnit;
                const lease = data.currentLease;

                this.propertyPictures = property.picturesData?.map(it => it.data) || [];
                this.unitPictures = unit.picturesData?.map(it => it.data) || [];

                /*
                const propertyPicUrls = property.picturesData?.map(it => it.data) || [];
                const unitPicUrls = unit.picturesData?.map(it => it.data) || [];

                let propertyPicPromises : Promise<string>[] = [];
                let unitPicPromises : Promise<string>[] = [];

                propertyPicUrls.forEach(it => {
                    propertyPicPromises.push(this.getBase64ImageFromUrl(it));
                });
                unitPicUrls.forEach(async it => {
                    unitPicPromises.push(this.getBase64ImageFromUrl(it));
                });

                await Promise.all(propertyPicPromises).then(pics => {
                    this.propertyPictures = pics;
                });
                await Promise.all(unitPicPromises).then(pics => {
                    this.unitPictures = pics;
                });
                */

                this.propertyName = property.name || property.extraAddressInfo?.formattedAddress || property.address || "Property";
                this.unitName = unit.name;

                this.pictures = this.unitPictures.concat(this.propertyPictures);
                this.picsSliderObj = this.pictures.map(it => ({
                    image: it,
                    thumbImage: it
                })) || [];

                this.address = property.extraAddressInfo?.formattedAddress || property.address || "-";
                this.city = property.city || "-";
                this.country = property.country || "-";

                this.zip = property.zip || "-";
                if (this.zip === "" || this.zip === " " || this.zip === "\t") {
                    this.zip = "-"
                }
                this.propertyUnit = property.unit || "-";
                if (this.propertyUnit === "" || this.propertyUnit === " " || this.propertyUnit === "\t") {
                    this.propertyUnit = "-"
                }
                this.stairwell = property.stairwell || "-";
                if (this.stairwell === "" || this.stairwell === " " || this.stairwell === "\t") {
                    this.stairwell = "-"
                }
                
                this.rentValue = lease.monthlyRent;
                this.rentCurrency = lease.currency;

                const amenities = new Amenities(this.translateService);
                
                this.unitDescription = unit.desc || "-";
                this.unitTags = (unit.amenities || []).map(code => amenities.unitAmenities[code] || code).concat(unit.featureTags || []);

                this.propertyDescription = property.desc || "-";
                this.propertyTags = (property.amenities || []).map(code => amenities.propertyAmenities[code] || code).concat(property.servicesCustom || []);


                this.files = (await this.backendService.loadFiles("properties", property.id)).concat(await this.backendService.loadFiles("units", unit.id));

                this.isLoading = false;
                this.loadingService.hide();
            });

        
        this.deviceStateService.screenResized
			.pipe(
				startWith(this.mobile),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(isMobile => this.mobile = isMobile);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	backClick () {
		this.location.back();
	}

	onTabClicked (indx : number) {
        
        const newIndex = indx;

        if (this.currentPageIndex !== 0 && newIndex === 0) {
            this.pictures = [];
            this.pictures = this.unitPictures.concat(this.propertyPictures);
            this.picsSliderObj = this.pictures.map(it => ({
                image: it,
                thumbImage: it
            })) || [];
        } else if (this.currentPageIndex !== 1 && newIndex === 1) {
            this.pictures = [];
            this.pictures = this.propertyPictures.concat(this.unitPictures);
            this.picsSliderObj = this.pictures.map(it => ({
                image: it,
                thumbImage: it
            })) || [];
        }

        this.currentPageIndex = newIndex;
    }

	showPicsClick () {
		this.slider?.showLightbox();
	}
}
