<div
	*ngIf="!mobile"
	class="container container-desk"
	fxLayout="column"
	fxLayoutAlign="space-between center"
	style="height: 100%"
>
	<div fxFlex fxLayoutAlign="center center" fxLayout="row" fxLayoutWrap>
		<div class="center-card" fxFlex="548px" fxLayoutAlign="flex-start center" fxLayout="column" fxLayoutWrap>
			<img src="./assets/img/logo.svg" id="img-intro-logo" />

			<p
				class="regular-12 no-margin"
				style="text-align: center; line-height: 20px !important"
                [innerHTML]="'password_recovery_desc_1a' | translate"
			>
			</p>

			<div class="v-spacing-20"></div>

			<p class="regular-25 no-margin color-1" [innerHTML]="'password_recovery_desc_2' | translate">
			</p>

			<p class="regular-15 color-grey" style="margin-top: 10px; margin-bottom: 20px" [innerHTML]="'login_desc_3a' | translate">
            </p>

			<div class="v-spacing-20"></div>

			<div fxLayout="column" fxLayoutAlign="start start" style="width: 80%">
				<mat-form-field appearance="outline" class="field-full-width">
                    <mat-label>{{ 'password_recovery_desc_3' | translate }}</mat-label>
                    <input
                        type="email"
                        matInput
                        [placeholder]="'password_recovery_insert_email' | translate"
                        [formControl]="email"
                        #emailElement
                    />
                    <mat-error *ngIf="email.invalid"></mat-error>
                </mat-form-field>

                <div class="v-spacing-20"></div>
                <div class="v-spacing-20"></div>

                <div fxLayout="row" fxLayoutAlign="flex-start flex-start" style="width: 100%">
                    <button
                        mat-button
                        class="button-positive btn-recover"
                        (click)="recoverPassword()"
                    >
                        {{ 'password_recovery_desc_4' | translate }}
                    </button>
                </div>

                <div class="v-spacing-20"></div>
			</div>

			<div fxFlex fxLayoutAlign="flex-start flex-start">
				<p *ngIf="hasErrorMessage" class="error-message" [innerHTML]="'password_recovery_error' | translate">
				</p>

				<p *ngIf="hasSuccessMessage" class="success-message" [innerHTML]="'password_recovery_success' | translate">
				</p>
			</div>
		</div>
	</div>
</div>

<div
	*ngIf="mobile"
	class="container container-mobile"
	fxLayout="column"
	fxLayoutAlign="space-between center"
	style="height: 100%"
>
	<div fxFlex fxLayoutAlign="center flex-start" fxLayout="row" fxFlex="100%" style="margin-top: 20px">
		<div class="center-card" fxFlex="100%" fxLayoutAlign="flex-start center" fxLayout="column" fxLayoutWrap>
			<img src="./assets/img/logo.svg" id="img-intro-logo" />

			<p
				class="regular-12 no-margin"
				style="text-align: center; line-height: 20px !important"
			>
				{{ 'password_recovery_desc_1b' | translate }}
			</p>

			<div class="v-spacing-20"></div>

			<p class="regular-25 no-margin color-1" [innerHTML]="'password_recovery_desc_2' | translate">
			</p>

			<p class="regular-15 color-grey" style="margin-top: 10px; margin-bottom: 20px" [innerHTML]="'login_desc_3a' | translate">
            </p>

			<div class="v-spacing-20"></div>

			<div fxLayout="column" fxLayoutAlign="start start" style="width: 80%">
				<mat-form-field appearance="outline" class="field-full-width">
                    <mat-label>{{ 'password_recovery_desc_3' | translate }}</mat-label>
                    <input
                        type="email"
                        matInput
                        [placeholder]="'password_recovery_insert_email' | translate"
                        [formControl]="email"
                        #emailElement
                    />
                    <mat-error *ngIf="email.invalid"></mat-error>
                </mat-form-field>

                <div class="v-spacing-20"></div>
                <div class="v-spacing-20"></div>

                <div fxLayout="row" fxLayoutAlign="flex-start flex-start" style="width: 100%">
                    <button
                        mat-button
                        class="button-positive btn-recover"
                        (click)="recoverPassword()"
                    >
                        {{ 'password_recovery_desc_4' | translate }}
                    </button>
                </div>

                <div class="v-spacing-20"></div>
			</div>

			<div fxFlex fxLayoutAlign="flex-start flex-start">
				<p *ngIf="hasErrorMessage" class="error-message" [innerHTML]="'password_recovery_error' | translate">
				</p>

				<p *ngIf="hasSuccessMessage" class="success-message" [innerHTML]="'password_recovery_success' | translate">
				</p>
			</div>

			<div class="v-spacing"></div>
		</div>
	</div>
</div>
