import { Injectable } from '@angular/core';
import { AttachedFile } from '../models/fileRm';
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage";
import { firstValueFrom } from 'rxjs';
import { BackendService } from './backend.service';

@Injectable({
	providedIn: 'root'
})
export class FileUploaderService {
	constructor(
        private backendService: BackendService
    ) {}

    
    // Maintenances

	private async uploadPhotoData(folderName: string, picName: string, photoData: string): Promise<string> {

        const landlordId = await firstValueFrom(this.backendService.landlordId$);

        const storage = getStorage();
		const objRef = ref(storage, `/landlords/${landlordId}/${folderName}/pictures/${picName}`);

		const elaboratedData = photoData;

        await uploadString(objRef, elaboratedData, 'data_url');
        const url = await getDownloadURL(objRef);

		return url;
	}

	public async syncPhotos(folder: string, pendingPhotos: AttachedFile[], needToSetUpNames: boolean = false): Promise<string[]> {

        
        const promises = pendingPhotos.map(async file => {

            // Set up (IF NEEDED) name
            const fileName = needToSetUpNames ? (Date.now() + file.name) : file.name;

            return this.uploadPhotoData(folder, fileName, file.publicUrl); // Here URL actually holds the photo data
		});

		return Promise.all(promises);
	}


    // Chat

    private async uploadFileData(folderName: string, fileName: string, fileData: string, needToSetUpName: boolean): Promise<{'name': string, 'url': string}> {

        const landlordId = await firstValueFrom(this.backendService.landlordId$);

        // Set up (IF NEEDED) name
        const actualFileName = needToSetUpName ? (Date.now() + fileName) : fileName;

        const storage = getStorage();
		const objRef = ref(storage, `/landlords/${landlordId}/${folderName}/files/${actualFileName}`);

		const elaboratedData = fileData;
		
        await uploadString(objRef, elaboratedData, 'data_url');
        const url = await getDownloadURL(objRef);

		return {'name': fileName, 'url': url};
	}

    public async syncFiles(folder: string, pendingFiles: AttachedFile[], needToSetUpNames: boolean = false): Promise<{'name': string, 'url': string}[]> {

        const promises = pendingFiles.map(async file => {

            return this.uploadFileData(folder, file.name, file.publicUrl, needToSetUpNames); // Here URL actually holds the photo data
		});

		return Promise.all(promises);
	}

    // Profile picture
    
    public async uploadPropfilePic(photoData: string, extension: string): Promise<string> {

        const userId = await firstValueFrom(this.backendService.userId$);

        let picName = userId + Date.now();

        if (extension !== "") {
            picName += "." + extension;
        }

        const storage = getStorage();
		const objRef = ref(storage, `/profilePictures/${picName}`);

		const elaboratedData = photoData;
		
        await uploadString(objRef, elaboratedData, 'data_url');
        const url = await getDownloadURL(objRef);

		return url;
	}
}
