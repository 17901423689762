<div fxLayout="column" style="max-width: 720px">
	<div [formGroup]="mainInfoForm" fxLayout="column">
		<div fxLayout="row wrap" fxLayoutGap="20px">
			<mat-form-field class="dash-form-field-add" appearance="outline" fxFlex="250px">
				<mat-label>{{ 'bank_details_bank_name' | translate }}</mat-label>
				<input type="text" matInput formControlName="bankName" maxlength="50" autocomplete="off" />
			</mat-form-field>

			<mat-form-field class="dash-form-field-add" appearance="outline" fxFlex="250px">
				<mat-label>{{ 'bank_details_iban' | translate }}</mat-label>
				<input type="text" matInput formControlName="iban" maxlength="50" autocomplete="off" />
			</mat-form-field>
		</div>

		<div fxLayout="row wrap" fxLayoutGap="20px">
			<mat-form-field class="dash-form-field-add" appearance="outline" fxFlex="250px">
				<mat-label>{{ 'bank_details_bic' | translate }}</mat-label>
				<input type="text" matInput formControlName="bic" maxlength="20" autocomplete="off" />
			</mat-form-field>

			<mat-form-field class="dash-form-field-add" appearance="outline" fxFlex="250px">
				<mat-label>{{ 'bank_details_beneficiary' | translate }}</mat-label>
				<input type="text" matInput formControlName="beneficiary" maxlength="50" autocomplete="off" />
			</mat-form-field>
		</div>
	</div>
</div>
