import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SnotifyPosition, SnotifyService } from 'ng-snotify';
import { BehaviorSubject } from 'rxjs';
import { LoadingService } from './services/loading.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewChecked {
    
    title = 'Roommate Places';

	currentLoadingText: BehaviorSubject<string> = new BehaviorSubject<string>('');

    constructor(
		private readonly loadingService: LoadingService,
		private readonly cdr: ChangeDetectorRef,
        private readonly toastService: SnotifyService
	) {

    }

    ngOnInit(): void {
        this.currentLoadingText = this.loadingService.getLoadingTextObservable();

        this.toastService.setDefaults({
			toast: {
				position: SnotifyPosition.rightTop
			}
		});
    }

    ngAfterViewChecked() {
        this.cdr.detectChanges();
    }
}
