<div mat-dialog-content>
	<div fxLayout="column" fxLayoutAlign="flex-start center" class="popup-top-section">
		<div class="v-spacing-20"></div>

		<p class="semi-bold-15 color-orange no-margin" [innerHTML]="'payment_notice_title' | translate">
		</p>

		<img
			src="../../assets/img/close.svg"
			class="clickable"
			(click)="cancelClick()"
			style="width: 15px; height: 15px"
		/>
	</div>

	<div fxLayout="column" fxLayoutAlign="center center" fxLayoutWrap>
		<input *ngIf="mobile" type="text" style="display: none" cdkFocusInitial />
		<p *ngIf="paymentMethod === paymentMethods.Mandate" class="regular-15 text-center" style="padding: 20px;">
			{{ 'mandate_payment_notice_desc' | translate }}
		</p>

		<ng-container *ngIf="paymentMethod === paymentMethods.CreditCard" class="regular-12">
			<div class="payment-recap-details">
				<div *ngIf="mobile">
					<p class="regular-15 title-dialog" [innerHTML]="'credit_card_your_payment_title' | translate"></p>
					<p class="semi-bold-12 color-orange title-details">{{ 'cc_popup_scroll_mobile' | translate }}</p>
					<div class="line-separate"></div>

					<p class="semi-bold-12 title-payment">{{ 'com_up_title' | translate }}</p>
					<p class="regular-12 amount-payment" style="white-space: nowrap">{{ payment.title }}</p>

					<p class="semi-bold-12 title-payment">{{ 'com_amount' | translate }}</p>
					<p class="semi-bold-12 amount-payment"><span class="color-green">+ </span>{{ payment.amount / 100 | currency:payment.currency: 'symbol':'1.2-2' }}</p>

					<ng-container *ngIf="paymentDetails.netFeeAmount > 0">
						<p class="semi-bold-12 title-payment" [innerHTML]="'credit_card_platform_fee_table_title' | translate:{vatRate: paymentDetails.vatRate}"></p>
						<p class="semi-bold-12 amount-payment"><span class="color-green">+ </span>{{ paymentDetails.netFeeAmount / 100 | currency: payment.currency }}</p>
					</ng-container>
					
					<ng-container *ngIf="platformFee > 0">
						<p class="semi-bold-12 title-payment" [innerHTML]="'credit_card_platform_fee_vat_table_title' | translate"></p>
						<p class="semi-bold-12 amount-payment"><span class="color-green">+ </span>{{ platformFee / 100 | currency: payment.currency }}</p>
					</ng-container>

					<ng-container *ngIf="platformFee > 0 && paymentDetails.netFeeAmount > 0">
						<p class="semi-bold-12 title-payment">{{ 'com_total' | translate }}</p>
						<p class="semi-bold-12 amount-payment"><span class="color-green">+ </span>{{ paymentDetails.totalAmount / 100 | currency: payment.currency }}</p>
					</ng-container>
				</div>
				<table *ngIf="!mobile">
					<tr>
						<th style="width: 75%;">{{ 'com_up_title' | translate }}</th>
						<th>{{ 'com_amount' | translate }}</th>
					</tr>
					<tr>
						<td style="white-space: nowrap">
							<p class="regular-12 no-margin">{{ payment.title }}</p>
						</td>
						<td style="white-space: nowrap">
							<p class="semi-bold-12 no-margin"><span class="color-green">+ </span>{{ payment.amount / 100 | currency:payment.currency: 'symbol':'1.2-2' }}</p>
						</td>
					</tr>
					<ng-container *ngIf="paymentDetails.netFeeAmount > 0">
						<tr>
							<td style="white-space: nowrap">
								<div class="info-platform-fee">
									<p class="regular-12 no-margin" [innerHTML]="'credit_card_platform_fee_table_title' | translate:{vatRate: paymentDetails.feeRate}"></p>
									<img src="../../assets/img/rm_icon_unit_info_grey.svg" (click)="click()" [matTooltip]="'credit_card_platform_fee_tooltip' | translate:{platformFee: paymentDetails.feeRate}"/>
								</div>
							</td>
							<td style="white-space: nowrap">
								<p class="semi-bold-12 no-margin"><span class="color-green">+ </span>{{ paymentDetails.netFeeAmount / 100 | currency: payment.currency }}</p>
							</td>
						</tr>
					</ng-container>
					<ng-container *ngIf="platformFee > 0">
						<tr>
							<td style="white-space: nowrap">
								<p class="regular-8 no-margin" [innerHTML]="'credit_card_platform_fee_vat_table_title' | translate"></p>
							</td>
							<td style="white-space: nowrap">
								<p class="semi-bold-8 no-margin"><span class="color-green">+ </span>{{ platformFee / 100 | currency: payment.currency }}</p>
							</td>
						</tr>
					</ng-container>
					<ng-container *ngIf="platformFee > 0 && paymentDetails.netFeeAmount > 0">
						<tr>
							<td style="white-space: nowrap; text-align: right;padding-right: 20px;">
								<p class="semi-bold-12 no-margin">{{ 'com_total' | translate }}</p>
							</td>
							<td style="white-space: nowrap">
								<p class="semi-bold-12 no-margin"><span class="color-green">+ </span>{{ paymentDetails.totalAmount / 100 | currency: payment.currency }}</p>
							</td>
						</tr>
					</ng-container>
				</table>

				<p class="semi-bold-10 no-margin">{{ 'credit_card_notice_title' | translate }}</p>
				<div class="useful-notices">
					<div>
						<img src="../../assets/img/cc_payment_process.svg"/>
						<p class="regular-10 no-margin" [innerHTML]="'credit_card_payment_notice_desc' | translate"></p>
					</div>
					<div *ngIf="paymentDetails.netFeeAmount > 0">
						<img src="../../assets/img/cc_payment_invoice.svg"/>
						<p class="regular-10 no-margin" style="line-height: 22px;" [innerHTML]="'credit_card_invoice_notice_desc' | translate"></p>
					</div>
					<div *ngIf="platformFee > 0">
						<img src="../../assets/img/cc_payment_platform_fee.svg"/>
						<p class="regular-10 no-margin" style="line-height: 22px;" [innerHTML]="'credit_card_platform_fee_notice_desc' | translate"></p>
					</div>
				</div>

				<div *ngIf="recurringStatus === paymentProviderRecurringPaymentStatus.ACTIVE" class="useful-notices" style="margin-top: 20px">
					<div>
						<img src="../../assets/img/exclamation.svg"/>
						<p class="regular-10 no-margin" style="line-height: 22px;" [innerHTML]="'ongoing_recurring_card_info' | translate"></p>
					</div>
				</div>

				<div *ngIf="recurringStatus === paymentProviderRecurringPaymentStatus.AUTH_NEEDED" class="useful-notices" style="margin-top: 20px">
					<div>
						<img src="../../assets/img/exclamation.svg"/>
						<p class="regular-10 no-margin" style="line-height: 22px;" [innerHTML]="'ongoing_recurring_card_info_auth_needed' | translate"></p>
					</div>
				</div>

				<div *ngIf="platformFee > 0"  [ngStyle]="{'padding-top': recurringStatus === paymentProviderRecurringPaymentStatus.AUTH_NEEDED || recurringStatus === paymentProviderRecurringPaymentStatus.ACTIVE ? '20px' : '64px'}" style="padding-left: 5px;width: 100%;">
					<mat-checkbox (change)="isChecked()">
						<mat-label class="regular-10 no-margin">{{"credit_card_acknowladge_notices_desc" | translate }}</mat-label>
					</mat-checkbox>
				</div>
			</div>
		</ng-container>
		<div
			class="popup-bottom-section"
			fxLayout="row"
			fxLayoutAlign="center center"
			fxLayoutWrap
			style="width: 100%"
		>
			<button
				(click)="proceedClick()"
				mat-button
				class="button-positive button-small"
				[disabled]="!isAcknowledged && paymentMethod === paymentMethods.CreditCard"
				[matTooltip]="tooltipbutton"
				style="width: 193px;"
			>{{ 'payment_notice_cta' | translate }}</button>
		</div>
	</div>
</div>
