import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { startWith, Subject, takeUntil } from 'rxjs';
import { AttachedFile } from '../models/fileRm';
import { BackendService } from '../services/backend.service';
import { LoadingService } from '../services/loading.service';
import { DeviceStateService } from '../services/device-state.service';
import { TranslateService } from '@ngx-translate/core';


interface LeaseFilesPacked {
    from: number;
    to: number;
    files: AttachedFile[];
}

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit, OnDestroy {

    tenantNameSurname : string = "";

    tenantFiles : AttachedFile[] = [];
    propertyFiles : AttachedFile[] = [];
    leaseFiles : LeaseFilesPacked[] = [];


    isLoading : boolean = true;

	// Mobile
	mobile : boolean = false;


	private ngUnsubscribe = new Subject<void>();

    private currentTenantId: string = '';


	constructor(
        private readonly location: Location,
		private readonly deviceStateService: DeviceStateService,
        private readonly backendService: BackendService,
        private readonly loadingService: LoadingService,
        private readonly translateService: TranslateService
	) {
		this.mobile = deviceStateService.isMobileResolution();
	}

	ngOnInit(): void {

		this.deviceStateService.screenName = 'menu_title_your_files';
        this.loadingService.show(this.translateService.instant('loadings.loading_loading'));


        this.backendService.loadBasicUserData()
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(userData => {
                this.tenantNameSurname = userData.name + " " + userData.surname;

                const currentLandlord = userData.landlords.find(
                    (ll) => ll.id === userData.currentLandlordId
                );

                this.currentTenantId = currentLandlord?.tenantId || '';
            });


        this.backendService.loadTenantLandlordData()
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(async tenantData => {

                this.tenantFiles = await this.backendService.loadFiles("tenants", this.currentTenantId);

                if (!!tenantData.currentProperty) {
                    this.propertyFiles = await this.backendService.loadFiles("properties", tenantData.currentProperty.id);
                }

                if (!!tenantData.currentUnit) {
                    this.propertyFiles = this.propertyFiles.concat(await this.backendService.loadFiles("units", tenantData.currentUnit.id));
                }

                if (!!tenantData.leases) {

                    for (let i = 0; i < tenantData.leases.length; i++) {

                        const files = await this.backendService.loadFiles("leases", tenantData.leases[i].id);
                        this.leaseFiles.push({
                            from: tenantData.leases[i].startDate,
                            to: tenantData.leases[i].endDate,
                            files: files
                        });
                    }

                    this.leaseFiles.sort((a,b) => b.to - a.to);
                }

                this.isLoading = false;
                this.loadingService.hide();
            });



		this.deviceStateService.screenResized
			.pipe(
				startWith(this.mobile),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(isMobile => this.mobile = isMobile);
    }

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	backClick () {
		this.location.back();
	}
}
