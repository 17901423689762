<div class="parent"
  [ngStyle]="{
    width: mobile ? '100vw' : '280px'
  }"
  [ngClass]="{
    'border-container': !mobile
  }"
  >
  <div class="child" 
    [ngStyle]="{
      height: 'calc(var(--app-height) - ' + (mobile ? (86) : (245)) + 'px)'
    }">
    <div *ngFor="let unit of units" (click)="selectUnit(unit)" class="unit-container" [ngClass]="{'selected-unit': unit.id === selectedId}">
      <img src="{{ unit.photo }}" alt="" class="unit-image">
      <div class="info-arrow-container">
        <div class="unit-information">
          <div class="sidebar-unit-address">{{ unit.address }}, {{ unit.houseNumber }} - {{ unit.country }}</div>
          <div class="unit-name-eye">
            <div class="sidebar-unit-name">{{ unit.name }}</div>
            <!-- <img (click)="redirectToRYN(unit.id)" [matTooltip]="redirectRynTooltip()" src="./assets/img/eye-on.svg" alt=" select chat" style="width: 22px; height: 22px;"> -->
          </div>
        </div>
        <img *ngIf="mobile" src="./assets/img/arrow-right.svg" alt="arrow to right to select chat" style="width: 14px; height: 14px; padding-right: 18px;">
      </div>
    </div>
  </div>
</div>