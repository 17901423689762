import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { startWith, Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { LoadingService } from 'src/app/services/loading.service';
import { DeviceStateService } from 'src/app/services/device-state.service';
import { AppConstants } from 'src/app/utils/app-constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-choose-password',
  templateUrl: './choose-password.component.html',
  styleUrls: ['./choose-password.component.scss']
})
export class ChoosePasswordComponent implements OnInit, OnDestroy {

    email: string = "";
    tenantNameSurname : string = "";
    password = new FormControl('', [Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.\/\\_|=§°]).{8,}$/)]);
    hasError : boolean = false;
    resetError : boolean = false;
    hidePassword : boolean = true;

    // Mobile
	mobile : boolean = false;


	private ngUnsubscribe = new Subject<void>();

    isLoading : boolean  = true;

	constructor(
        private readonly router: Router,
		private readonly deviceStateService: DeviceStateService,
        private readonly backendService: BackendService,
        private readonly loadingService: LoadingService,
        private readonly authService: AuthService,
        private readonly toastService: SnotifyService,
        private readonly gaService: GoogleAnalyticsService,
        private readonly translateService: TranslateService
	) {
		this.mobile = deviceStateService.isMobileResolution();
	}

	ngOnInit(): void {

        this.deviceStateService.screenName = 'dash_name_your_dashboard';
        this.loadingService.show(this.translateService.instant('loadings.loading_loading'));

        this.backendService.loadBasicUserData()
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(data => {

                this.email = this.authService.loginEmail;
                this.tenantNameSurname = data.name + " " + data.surname;

                this.isLoading = false;
                this.loadingService.hide();
            });

		this.deviceStateService.screenResized
			.pipe(
				startWith(this.mobile),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe((isMobile : boolean) => this.mobile = isMobile);
    }

    ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

    

    checkValidity () {
        if (this.resetError) {
            this.hasError = !this.password.valid;
        }
    }

    saveClick () {

        this.password.markAsTouched();

        if (this.password.valid) {
            this.hasError = false;

            this.loadingService.show(this.translateService.instant('loadings.passwordlessLoginSettingPassword'));

            this.authService.chooseNewPassword(this.password.value)
                .then(() => {
                    
                    this.toastService.success(
                        this.translateService.instant('toasts.toast_success_choose_pswd_desc'),
                        this.translateService.instant('toasts.toast_success_choose_pswd_title'),
                        AppConstants.TOAST_STD_CONFIG
                    );

                    this.gaService.gtag('event', 'user_passwordless_password_chosen', {
                        'event_label': 'User passwordless password chosen',
                        'event_category': 'login'
                    });
                })
                .catch((err : number) => {
                    
                    this.toastService.error(
                        this.translateService.instant('toasts.toast_error_choose_pswd_desc'),
                        this.translateService.instant('toasts.toast_error_choose_pswd_title'),
                        AppConstants.TOAST_STD_CONFIG
                    );
                })
                .finally(() => {

                    this.router.navigate(['/']);
                });
        } else {
            this.hasError = true;
            this.resetError = true;
        }
    }
}
