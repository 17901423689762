import { getCurrencySymbol, Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import axios from 'axios';
import creditCardType from 'credit-card-type';
import { endOfMonth, format } from 'date-fns';
import { SnotifyService } from 'ng-snotify';
import { CookieService } from 'ngx-cookie-service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { combineLatest, defer, startWith, Subject, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';
import { AttachedFile } from 'src/app/models/fileRm';
import { CreditCardFeesType, CreditCardTypeCardBrandNiceType, EpaymentPreferences, MangoPayCardType, PaymentDetails, PaymentMethod, PaymentMethods, PaymentOperation, PaymentProviderRecurringPaymentStatus, PropertyPayment, RecurringPayment } from 'src/app/models/payments';
import { BackendService } from 'src/app/services/backend.service';
import { DeviceStateService } from 'src/app/services/device-state.service';
import { LoadingService } from 'src/app/services/loading.service';
import { AppConstants } from 'src/app/utils/app-constants';
import { AppUtils } from 'src/app/utils/app-utils';
//import axios from 'axios';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmPaymentNoticeDialogComponent } from 'src/app/confirm-payment-notice-dialog/confirm-payment-notice-dialog.component';
import { InvoiceDataAddDialogComponent } from 'src/app/invoice-data-add-dialog/invoice-data-add-dialog.component';
import { InvoiceData } from 'src/app/models/common';
import { PaymentCompleteConfirmDialogComponent } from 'src/app/payment-complete-confirm-dialog/payment-complete-confirm-dialog.component';
import { CreditCardValidators } from 'src/app/validators/cc-input-validator';
import { CreditCard } from 'src/app/validators/cc-validator';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit, OnDestroy {

    now = Date.now();

    invoiceData: InvoiceData = {};

    paymentMethods = PaymentMethods

    showCreditCardInfo: boolean = false

    cardNumber = new FormControl('', [CreditCardValidators.validateCCNumber]) //new FormControl('', [Validators.required, Validators.pattern(/^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)]);
    cardHolderName = new FormControl('', [Validators.required, Validators.pattern("")]);
    expirationDate = new FormControl('', [CreditCardValidators.validateExpDate]) //new FormControl('', [Validators.required, Validators.pattern(/^(1[0-2]|0[1-9]|\d)\/(20\d{2}|19\d{2}|0(?!0)\d|[1-9]\d)$/)]);
    ccv = new FormControl("", [Validators.required, Validators.pattern(/^[0-9]{3,4}$/)]);
    saveCCInfo: boolean = true

    recurring: boolean = false

    linkedRecurring : RecurringPayment | undefined = undefined
    activeLinkedRecurring: boolean = false

    paymentProviderRecurringPaymentStatus = PaymentProviderRecurringPaymentStatus

    type$ = defer(() => this.cardNumber.valueChanges)
    .pipe(map((num: string) => CreditCard.cardType(num)));

    appUtils: AppUtils;

    currentPaymentId: string = "";

    oldHistoryLength: number | undefined = 0

    amountLeftToPay: number = 0;

    payment : PropertyPayment = {} as PropertyPayment;
	rentStart : number = 0;
	rentEnd : number = 0;
	attachments: AttachedFile[] = [];
	operations: PaymentOperation[] = [];

    epaymentPreferences: EpaymentPreferences = undefined as any

	displayedColumns : string[] = [];
	displayedColumnsOperations : string[] = ["date", "amount", "status", "info", "invoice"];
    displayedColumnsTableBottom: string[] = ["amount_to_be_paid", "fees", "total_to_be_paid"]

	paymentField = new FormControl('', [Validators.required, Validators.pattern(/^\d+(\.\d+)?$/)]);
	amountKeyPressValidate = AppUtils.amountKeyPressValidate;
	currencySymbol: string = "";

    tenantNameSurname : string = "";

    allowedPaymentMethods: "only_credit_card" | "only_mandate" | "both" | "none" = "none"

    selectedPaymentMethod: PaymentMethods = PaymentMethods.CreditCard

    setupNeeded : boolean = false;
    mandateSetupNeeded: boolean = false

    mandateCreationNeeded : boolean = false;
    landlordEpaymentsConfigured : boolean = false;

    blockedByNonProcessedPaymentIntent: boolean = false;

    creditCardCreationNeeded = false;
    creditCardFeesType: CreditCardFeesType = 'default';

    arrivedFromRedirect = false

    creditCards: {
        cardId: string,
        last4Digits: string,
        associatedRecurringPaymentId?: string
    }[] = []


	// Mobile
	mobile : boolean = false;

    idempotencyKey: string = "";

    isLoading : boolean = true;

	private ngUnsubscribe = new Subject<void>();


	constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
		private readonly location: Location,
		private readonly deviceStateService: DeviceStateService,
        private readonly loadingService: LoadingService,
        private readonly backendService: BackendService,
        private readonly toastService: SnotifyService,
        private readonly cookieService: CookieService,
        private readonly gaService: GoogleAnalyticsService,
        private readonly translateService: TranslateService,
        public dialog: MatDialog
	) {
		this.mobile = deviceStateService.isMobileResolution();

        if (!!this.route.snapshot.params['pid']) {
            this.currentPaymentId = this.route.snapshot.params['pid'];
        } else {
            this.backClick();
        }

        this.appUtils = new AppUtils(translateService);
	}


    onKey(event: KeyboardEvent) {
        let date = this.expirationDate.value;
        if (date.length === 2 && event.key !== "Backspace") {
            this.expirationDate.setValue(date + '/');
        }
    }

	ngOnInit(): void {

        this.idempotencyKey = uuidv4()

        this.now = Date.now();

        const payinId = this.route.snapshot.queryParamMap.get('transactionId');

        if(payinId){
            const oldHistoryLength = this.route.snapshot.queryParamMap.get('historyLength') as any

            this.router.navigate([],{})

            const isnumber = oldHistoryLength && !isNaN(parseInt(oldHistoryLength))

            if(isnumber){
                this.oldHistoryLength = parseInt(oldHistoryLength)
                window.addEventListener("popstate", () => {
                    setTimeout( () => {
                        this.navigateBackHistory(true)
                    },0);
                }, false);
            }

            this.loadingService.show(this.translateService.instant('loadings.payment_processing'));
        }else{
            this.loadingService.show(this.translateService.instant('loadings.loading_loading'));
        }

        this.deviceStateService.screenName = 'menu_title_your_payment';

        combineLatest([
            this.backendService.loadBasicUserData(),
            this.backendService.loadTenantLandlordData(),
            this.backendService.getUserPaymentInfo(),
            this.backendService.getPayment(this.currentPaymentId),
        ])
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(async ([userData, tenantData, userPaymentInfo, payment]) => {
                this.invoiceData = userData.invoiceData;

                this.setPaymentPreferences(tenantData.epaymentPreferences, payment)

                this.setupNeeded = !((userPaymentInfo.userInfo.status === 'created' && !!userPaymentInfo.userInfo.mangopay && !!userPaymentInfo.userInfo.mangopay.walletId && !!userPaymentInfo.userInfo.data));

                this.mandateSetupNeeded = !((!!userPaymentInfo.bankAccountInfo && userPaymentInfo.bankAccountInfo.status === 'created' && !!userPaymentInfo.bankAccountInfo.data))// &&
                                            //(!!userPaymentInfo.kycInfo && (userPaymentInfo.kycInfo.status === 'created' || userPaymentInfo.kycInfo.status === 'active' || userPaymentInfo.kycInfo.status === 'succeeded' || userPaymentInfo.kycInfo.status === 'succeded')))

                this.mandateCreationNeeded = !(
                    !!userPaymentInfo.mandateInfo &&
                    (userPaymentInfo.mandateInfo.status === 'submitted' ||  userPaymentInfo.mandateInfo.status === 'active')
                );

                if(userPaymentInfo.creditCardInfo?.creditCards){
                    this.creditCards = Object.values(userPaymentInfo.creditCardInfo.creditCards).map(creditCard => { return {
                        cardId: creditCard.mangoPay.cardId,
                        last4Digits: creditCard.last4Digits,
                        associatedRecurringPaymentId: payment?.linkedPeriodicPaymentId ? creditCard.associatedRecurringIds?.find(id => id === payment.linkedPeriodicPaymentId) || undefined : undefined
                    }})
                }

                if(this.creditCards.length === 0){
                    this.creditCardCreationNeeded = true
                }

                this.landlordEpaymentsConfigured = tenantData.landlordEpaymentsConfigured;

                this.epaymentPreferences = tenantData.epaymentPreferences;
                console.log(this.epaymentPreferences)

                this.tenantNameSurname = userData.name + " " + userData.surname;

                if (!!payment) {
                    this.payment = payment;

                    this.displayedColumns = this.payment.category === 'rent' ? ["title", "status", "amount", "paid", "dueDate", "rentPeriod"] : ["title", "status", "amount", "paid", "dueDate"];

                    this.rentStart = this.payment.referenceDate;
                    this.rentEnd = endOfMonth(this.rentStart).getTime();
                    if (tenantData.currentLease && tenantData.currentLease.endDate < this.rentEnd) {
                        this.rentEnd = tenantData.currentLease.endDate;
                    }

                    this.currencySymbol = getCurrencySymbol(this.payment.currency, "narrow");

                    if (this.payment) {
                        this.attachments = await this.backendService.loadFiles("payments", this.payment.id);
                    } else {
                        this.attachments = [];
                    }

                    this.amountLeftToPay = (payment.amount - payment.amountPaid) / 100;
                    this.paymentField.setValue(this.amountLeftToPay);

                    this.backendService.getPaymentOperations(this.currentPaymentId)
                        .pipe(
                            takeUntil(this.ngUnsubscribe)
                        )
                        .subscribe(operations => {
                            this.operations = operations;
                            this.isLoading = false;

                            if(operations.find(operation => operation.status === "started")){
                                this.blockedByNonProcessedPaymentIntent = true
                            }

                            if(!payinId){
                                this.loadingService.hide();
                            }
                        });

                    this.backendService.getRecurringPayment(payment.linkedPeriodicPaymentId).subscribe(recurringPayment => {
                        this.linkedRecurring = recurringPayment
                        this.activeLinkedRecurring = recurringPayment?.paymentProviderAssociation?.status === PaymentProviderRecurringPaymentStatus.ACTIVE
                    })

                } else {

                    this.backClick();
                    if(!payinId){
                        this.loadingService.hide();
                    }
                }

                if(payinId){
                    this.showCreditCardInfo = false
                    this.arrivedFromRedirect = true
                    this.handleSecureModeRedirect(payinId)
                }

                this.selectedPaymentMethod = this.showCCButton() ? PaymentMethods.CreditCard : this.showMandateButton() ? PaymentMethods.Mandate : PaymentMethods.Disabled
            });



        this.deviceStateService.screenResized
			.pipe(
				startWith(this.mobile),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(isMobile => this.mobile = isMobile);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	backClick () {

        if(this.showCreditCardInfo){
            this.showCreditCardInfo=false
            return
        }

        if(this.arrivedFromRedirect){
            this.navigateBackHistory(false)
            return
        }

		this.location.back();
	}

	getStatus (p: PropertyPayment | undefined) {
		if (!!p) {
			return PropertyPayment.getStatusFromPayment(p);
		}

        return "close";
    }

    getPaymentTitle (p: PropertyPayment | undefined) {
        if (!!p) {
			return this.appUtils.getPaymentTitle(p);
		}

        return "";
    }

    selectPaymentMethod(method: PaymentMethods) {
        this.selectedPaymentMethod = method;
    }

    proceedCCPayment(){
        this.showCreditCardInfo = true
        this.recurring = this.linkedRecurring ? true : false
        this.deviceStateService.backClickDisabled = true
        this.deviceStateService.fakeBackClick$.subscribe(()=>{
            this.showCreditCardInfo = false
        })
    }

    proceedMandatePayment(){
        if(this.mandateSetupNeeded){
            this.setupEPayments()
        }else{
            this.payPayment(PaymentMethods.Mandate)
        }
    }

    proceedPayment(){
        if(this.selectedPaymentMethod === PaymentMethods.Disabled) {
            return
        }

        if(this.selectedPaymentMethod === PaymentMethods.CreditCard){
            this.proceedCCPayment()
        }else{
            this.proceedMandatePayment()
        }
    }

    isPaymentFieldValid(){
        const result  = +this.paymentField.value <= this.amountLeftToPay && !this.blockedByNonProcessedPaymentIntent && this.paymentField.valid && +this.paymentField.value >= 1

        if(this.selectedPaymentMethod === PaymentMethods.Mandate){
            return result && !this.activeLinkedRecurring
        }

        return result
    }

    showCCButton(){
        if(this.allowedPaymentMethods === "only_mandate" || this.allowedPaymentMethods === "none"){
            return false
        }
        if(this.epaymentPreferences.creditCardPolicy === "only-expenses"){
            return this.payment.category !== "rent"
        }else if(this.epaymentPreferences.creditCardPolicy === "only-rent"){
            return this.payment.category === "rent"
        }else if(this.epaymentPreferences.creditCardPolicy === "all"){
            return true
        }else{
            return false
        }
    }

    showMandateButton(){
        if(this.payment.allowedPaymentMethods?.length > 0 && !this.payment.allowedPaymentMethods.includes(PaymentMethod.MANGO_MANDATE)){
            return false
        }

        if(this.allowedPaymentMethods === "only_credit_card" || this.allowedPaymentMethods === "none"){
            return false
        }
        if(this.epaymentPreferences.bankMandatePolicy === "only-expenses"){
            return this.payment.category !== "rent"
        }else if(this.epaymentPreferences.bankMandatePolicy === "only-rent"){
            return this.payment.category === "rent"
        }else if(this.epaymentPreferences.bankMandatePolicy === "all"){
            return true
        }
        else{
            return false
        }
    }

    async paymentUpdate(fieldAmountInCent: number, payment: PropertyPayment, paymentOperation: PaymentOperation){

        const percPaid = Math.floor((fieldAmountInCent / (this.payment?.amount || 1)) * 100);

        this.gaService.gtag('event', 'payment_paid', {
            'event_label': 'Payment paid',
            'event_category': 'payments',
            'percentage_paid': percPaid,
            'total_amount': this.payment?.amount || 0
        });

        this.payment = payment;
        this.operations = [paymentOperation].concat(this.operations.filter(operation => operation.id !== paymentOperation.id))
        this.amountLeftToPay = (payment.amount - payment.amountPaid) / 100;
        this.paymentField.setValue(this.amountLeftToPay);

        this.idempotencyKey = uuidv4();
    }

    async payInWithMandateCreation(fieldAmountInCent: number){
        this.loadingService.show(this.translateService.instant('loadings.loading_request_for_authorization'));

        try{
            const userPaymentInfo = await this.backendService.postPaymentsMandateCreation(this.currentPaymentId, fieldAmountInCent)

            if (!!userPaymentInfo.mandateInfo && !!userPaymentInfo.mandateInfo.mangopay && userPaymentInfo.mandateInfo.mangopay.redirectUrl !== '') {

                // Set cookie to then allow payin straigh away
                // It will expire anyway in 1 hour from now
                this.cookieService.set('payinAfterMandateCreation', this.currentPaymentId, (new Date).getTime() + (60*60*1000));

                window.location.href = userPaymentInfo.mandateInfo.mangopay.redirectUrl

            } else {

                this.toastService.error(
                    this.translateService.instant('toasts.toast_error_payments_setup_request_desc'),
                    this.translateService.instant('toasts.toast_error_payments_setup_request_title'),
                    {
                        ...AppConstants.TOAST_STD_CONFIG,
                        timeout: 8000
                    }
                );
            }
        }catch(e){
             // TODO: show error-specific message
             this.toastService.error(
                this.translateService.instant('toasts.toast_error_payments_setup_request_desc'),
                this.translateService.instant('toasts.toast_error_payments_setup_request_title'),
                {
                    ...AppConstants.TOAST_STD_CONFIG,
                    timeout: 8000
                }
            );
        }

        this.loadingService.hide();
    }

    async payInWithCreditCardCreation(fieldAmountInCent: number){
        if(this.recurring && !this.saveCCInfo){
            this.toastService.error(
                this.translateService.instant('toasts.toast_error_payments_setup_recurring_desc_2'),
                this.translateService.instant('toasts.toast_error_payments_setup_recurring_title'),
                {
                    ...AppConstants.TOAST_STD_CONFIG,
                    timeout: 8000
                }
            );
            return
        }

        this.loadingService.show(this.translateService.instant('loadings.loading_request_for_authorization'));

        try{
            const cardRegistrationReturnObject = await this.backendService.postPaymentsCreditCardStartProcedure(this.extractCardType(this.cardNumber.value) as string)

            const params = new URLSearchParams();

            params.append("accessKeyRef", cardRegistrationReturnObject.accessKey)
            params.append("data", cardRegistrationReturnObject.preRegistrationData)
            params.append("cardNumber", this.cardNumber.value)
            params.append("cardExpirationDate", this.expirationDate.value.replace("/", ""))
            params.append("cardCvx", this.ccv.value)

            const creditCardToken = await axios.post(cardRegistrationReturnObject.cardRegistrationURL, params,{
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if(creditCardToken.data.includes("errorCode")){
                throw Error(creditCardToken.data)
            }

            const creditCardPayinResult = await this.backendService.postPaymentsCreditCardFinalizeProcedure(this.currentPaymentId, fieldAmountInCent, PaymentMethods.CreditCard, "", creditCardToken.data, cardRegistrationReturnObject.id, this.saveCCInfo, window.history.length, this.idempotencyKey, this.recurring)


            const secureModeRedirectUrl = creditCardPayinResult?.paymentOperation?.metadata?.secureModeRedirectUrl?.replace(":443", "")

            if(secureModeRedirectUrl){
                this.handleSecureModeWithRedirect(secureModeRedirectUrl)
            } else{
                this.paymentUpdate(fieldAmountInCent, creditCardPayinResult.payment, creditCardPayinResult.paymentOperation)

                setTimeout(async ()=> {
                    this.attachments = await this.backendService.loadFiles("payments", creditCardPayinResult.payment.id);
                    this.loadingService.hide()
                    const dialogRef = this.dialog.open(PaymentCompleteConfirmDialogComponent, {
                        width: '700px',
                        backdropClass: 'backdrop-dark-background',
                        panelClass: 'no-padding-modalbox',
                        data: {
                            paymentStatus: creditCardPayinResult.paymentOperation.status,
                            data: creditCardPayinResult.paymentOperation.failureReason
                        }
                    });

                    dialogRef.afterClosed().subscribe(result => {
                        this.showCreditCardInfo = false
                    });
                }, 2000)
            }
        } catch(e: any) {

            if(!e.error?.message?.includes("idempotent_creation_conflict")){
                this.toastService.error(
                    this.translateService.instant('toasts.toast_error_payment_bank_issue_desc'),
                    this.translateService.instant('toasts.toast_error_payment_bank_issue_title'),
                    {
                        ...AppConstants.TOAST_STD_CONFIG,
                        timeout: 8000
                    }
                );
            }

            this.idempotencyKey = uuidv4();
            this.loadingService.hide()
        }
    }

    on3DSLinkPressed(operation: PaymentOperation){
        if(operation.metadata?.secureModeRedirectUrl){
            this.handleSecureModeWithRedirect(operation.metadata?.secureModeRedirectUrl);
        }
    }

    async payIn(fieldAmountInCent: number, paymentMethod: PaymentMethods){
        this.loadingService.show(this.translateService.instant('loadings.loading_sending_payment'));

        try{
            const payInResult = await this.backendService.postPayment(this.currentPaymentId, fieldAmountInCent, paymentMethod, "", this.oldHistoryLength || window.history.length, this.idempotencyKey, this.recurring)

            const secureModeRedirectUrl = payInResult?.paymentOperation?.metadata?.secureModeRedirectUrl?.replace(":443", "")

            if(secureModeRedirectUrl){
                this.handleSecureModeWithRedirect(secureModeRedirectUrl)
            } else{
                this.paymentUpdate(fieldAmountInCent, payInResult.payment, payInResult.paymentOperation)

                setTimeout(async ()=> {
                    this.attachments = await this.backendService.loadFiles("payments", payInResult.payment.id);
                    this.loadingService.hide()
                    const dialogRef = this.dialog.open(PaymentCompleteConfirmDialogComponent, {
                        width: '700px',
                        backdropClass: 'backdrop-dark-background',
                        panelClass: 'no-padding-modalbox',
                        data: {
                            paymentStatus: payInResult.paymentOperation.status,
                            data: payInResult.paymentOperation.failureReason
                        }
                    });

                    dialogRef.afterClosed().subscribe(result => {
                        this.showCreditCardInfo = false
                    });
                }, 2000)
            }
        }catch(e: any){
            if(!e.error?.message?.includes("idempotent_creation_conflict")){
                if (paymentMethod === PaymentMethods.CreditCard) {
                    this.toastService.error(
                        this.translateService.instant('toasts.toast_error_payment_bank_issue_desc'),
                        this.translateService.instant('toasts.toast_error_payment_bank_issue_title'),
                        {
                            ...AppConstants.TOAST_STD_CONFIG,
                            timeout: 8000
                        }
                    );

                } else {
                    this.toastService.error(
                        this.translateService.instant('toasts.toast_error_payments_setup_request_desc'),
                        this.translateService.instant('toasts.toast_error_payments_setup_request_title'),
                        {
                            ...AppConstants.TOAST_STD_CONFIG,
                            timeout: 8000
                        }
                    );
                }
            }

            this.idempotencyKey = uuidv4();

            this.loadingService.hide();
        }
    }

    navigateToPaymentsSettings(){
        this.router.navigateByUrl('/payments/settings');
    }

    isCCInputValid(){
        return this.cardNumber.valid && this.cardHolderName.valid && this.expirationDate.valid && this.ccv.valid
    }

	async payPayment(paymentMethod: PaymentMethods) {

        if (paymentMethod === PaymentMethods.CreditCard && !this.appUtils.isInvoiceDataComplete(this.invoiceData)) {

            const temp = this.dialog.open(InvoiceDataAddDialogComponent, {
                backdropClass: 'backdrop-dark-background',
                panelClass: ['no-padding-dialog']
            });

            temp.afterClosed().subscribe(async (result : any) => {

                if (result && result.userData) {
                    this.invoiceData = result.userData.invoiceData;
                }
            });

            return;
        }

        if(paymentMethod === PaymentMethods.CreditCard && this.creditCardCreationNeeded && !this.isCCInputValid()){
            this.toastService.error(
                this.translateService.instant('toasts.toast_error_cc_input'),
                this.translateService.instant('toasts.toast_error_cc_input_title'),
                {
                    ...AppConstants.TOAST_STD_CONFIG,
                    timeout: 8000
                }
            );
            return
        }

        if(!this.validatePaymentAmount()){
            return;
        }

        const amount = Math.round(+this.paymentField.value * 100);

        if(this.recurring && amount < this.payment.amount){
            this.toastService.error(
                this.translateService.instant('toasts.toast_error_payments_setup_recurring_desc_1'),
                this.translateService.instant('toasts.toast_error_payments_setup_recurring_title'),
                {
                    ...AppConstants.TOAST_STD_CONFIG,
                    timeout: 8000
                }
            );
            return
        }

        let paymentDetails: PaymentDetails = {
            feesAmount: 0,
            netFeeAmount: 0,
            totalAmount: amount,
            vatRate: 0,
            feesType: 'default',
            feeRate: 0
        };

        if(this.creditCardFeesType === 'tenant') {
            paymentDetails = await this.backendService.postPaymentDetails(this.payment.id, amount)
        }

        const dialogRef = this.dialog.open(ConfirmPaymentNoticeDialogComponent, {
            width: '700px',
            backdropClass: 'backdrop-dark-background',
            panelClass: 'no-padding-modalbox',
            data: {
                paymentMethod,
                payment: {
                    title: this.appUtils.getPaymentTitle(this.payment),
                    amount: amount,
                    currency: this.payment.currency
                },
                paymentDetails: paymentDetails,
                mobile: this.mobile,
                recurringStatus: this.linkedRecurring?.paymentProviderAssociation?.status
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.action === 'continue') {
                this.executePaymentProcess(paymentMethod)
            }
        });
	}

    executePaymentProcess(paymentMethod: PaymentMethods){
        if (!this.payment) {
            return
        }

        if(!this.validatePaymentAmount()){
            return;
        }

        if(paymentMethod === PaymentMethods.CreditCard){
            if(!this.extractCardType(this.cardNumber.value) && this.creditCardCreationNeeded){
                this.toastService.error(
                    this.translateService.instant('toasts.toast_error_payments_setup_card_type_not_found_desc'),
                    this.translateService.instant('toasts.toast_error_payments_setup_card_type_not_found_title'),
                    {
                        ...AppConstants.TOAST_STD_CONFIG,
                        timeout: 8000
                    }
                );
                return
            }
        }

        const fieldAmountInCent = +this.paymentField.value * 100;

        if (this.mandateCreationNeeded && paymentMethod === PaymentMethods.Mandate) {

            this.payInWithMandateCreation(fieldAmountInCent)

        } else if(this.creditCardCreationNeeded && paymentMethod === PaymentMethods.CreditCard){

            this.payInWithCreditCardCreation(fieldAmountInCent)

        } else {
            this.payIn(fieldAmountInCent, paymentMethod)
        }
    }

    validatePaymentAmount(){
        if (!this.paymentField.valid) {
            this.toastService.error(
                this.translateService.instant('toasts.toast_error_add_amount_desc'),
                this.translateService.instant('toasts.toast_error_add_amount_title'),
                AppConstants.TOAST_STD_CONFIG
            );
            return false;
        }

        if (+this.paymentField.value <= 1) {
            this.toastService.error(
                this.translateService.instant('toasts.toast_error_low_amount_desc'),
                this.translateService.instant('toasts.toast_error_low_amount_title'),
                AppConstants.TOAST_STD_CONFIG
            );
            return false;
        }

        if (+this.paymentField.value > ((this.payment.amount - this.payment.amountPaid) / 100)) {
            this.toastService.error(
                this.translateService.instant('toasts.toast_error_high_amount_desc'),
                this.translateService.instant('toasts.toast_error_high_amount_title'),
                AppConstants.TOAST_STD_CONFIG
            );
            return false;
        }

        return true;

    }

    async handleSecureModeWithPopup(secureModeRedirectUrl: string, fieldAmountInCent: number){
        const secureModeWindow = window.open(
            secureModeRedirectUrl,
            '_blank',
            'resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no, width=500, height=600, left=100 top=100 '
        );

        //If popup is blocked or fails

        // if(!secureModeWindow || secureModeWindow.closed || typeof secureModeWindow.closed=='undefined'){
        //     return this.handleSecureModeWithRedirect()
        // }

        window.addEventListener('message', e => {
            const data = JSON.parse(e.data)

            secureModeWindow?.close()

            this.paymentUpdate(fieldAmountInCent, data.payment, data.paymentOperation)

            if(data.payment.status === "close"){
                this.toastService.success(
                    this.translateService.instant('toasts.toast_success_payment_sent_desc'),
                    this.translateService.instant('toasts.toast_success_payment_sent_title'),
                    {
                        ...AppConstants.TOAST_STD_CONFIG,
                        timeout: 8000,
                    }
                );
                this.showCreditCardInfo = false
            }else{
                this.toastService.error(
                    this.translateService.instant('toasts.toast_error_payment_bank_issue_desc'),
                    this.translateService.instant('toasts.toast_error_payment_bank_issue_title'),
                    {
                        ...AppConstants.TOAST_STD_CONFIG,
                        timeout: 8000
                    }
                );
            }
            this.loadingService.hide()
            this.showCreditCardInfo = false
        })
    }

    async handleSecureModeWithRedirect(secureModeRedirectUrl: string){

        this.loadingService.show(this.translateService.instant('loadings.loading_redirection'));

        setTimeout(()=> {
            window.location.assign(secureModeRedirectUrl)
        }, 5000)
    }

    async handleSecureModeRedirect(payinId: string){
        const payInResult = await this.backendService.handleSecureModeRedirect(payinId)

        await this.paymentUpdate(payInResult.payment.amount, payInResult.payment, payInResult.paymentOperation)

        setTimeout(async ()=> {
            this.attachments = await this.backendService.loadFiles("payments", payInResult.payment.id);
            this.loadingService.hide()
            const dialogRef = this.dialog.open(PaymentCompleteConfirmDialogComponent, {
                width: '700px',
                backdropClass: 'backdrop-dark-background',
                panelClass: 'no-padding-modalbox',
                data: {
                    paymentStatus: payInResult.paymentOperation.status,
                    data: payInResult.paymentOperation.failureReason
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                this.showCreditCardInfo = false
            });
        }, 2000)
    }

    saveCCInfoCheckBoxEvent(checkboxState: MatCheckboxChange){
        this.saveCCInfo = checkboxState.checked
    }

    makeRecurringCheckBoxEvent(checkboxState: MatCheckboxChange){
        this.recurring = checkboxState.checked
    }

    navigateBackHistory(popstate: boolean){
        const newHistoryIndex = window.history.length

        if(this.oldHistoryLength){
            const backNavigationAmount = popstate? - (newHistoryIndex - this.oldHistoryLength) : - (newHistoryIndex - this.oldHistoryLength) - 1
            this.location.historyGo(backNavigationAmount)
        }else{
            this.router.navigateByUrl(`payments/${this.payment.id}`, {replaceUrl: true})
        }
    }

    setupEPayments () {
        this.router.navigateByUrl('/payments?completeSetup=true');
    }


    getDateStr (millis : number): string {
        return millis === 0 ? this.translateService.instant('com_no_end_date') : format(new Date(millis), 'dd/MM/yyyy');
    }

    setPaymentPreferences(epaymentPreferences: EpaymentPreferences, payment?: PropertyPayment) {

      if(payment?.landlordId === "JUKjSjNDgwNEIzYUHmQN6vPGAlH2"){
          if(payment.allowedPaymentMethods?.includes(PaymentMethod.MANGO_CREDIT_CARD)){
            this.allowedPaymentMethods = "only_credit_card"
          }else{
            if(epaymentPreferences.creditCardPolicy !== "none"){
              this.allowedPaymentMethods = "only_credit_card"
            }else{
              this.allowedPaymentMethods = "none"
            }
          }
        }else{
          if(payment?.allowedPaymentMethods && payment.allowedPaymentMethods.includes(PaymentMethod.MANGO_MANDATE) && payment.allowedPaymentMethods.includes(PaymentMethod.MANGO_CREDIT_CARD)){
              this.allowedPaymentMethods = "both"
          }else if(payment?.allowedPaymentMethods && payment.allowedPaymentMethods.includes(PaymentMethod.MANGO_MANDATE) && !payment.allowedPaymentMethods.includes(PaymentMethod.MANGO_CREDIT_CARD)){
              this.allowedPaymentMethods = "only_mandate"
          }
          else if(payment?.allowedPaymentMethods && payment.allowedPaymentMethods.includes(PaymentMethod.MANGO_CREDIT_CARD) && !payment.allowedPaymentMethods.includes(PaymentMethod.MANGO_MANDATE)){
              this.allowedPaymentMethods = "only_credit_card"
          }else{
              const mandateEnabled = epaymentPreferences.bankMandatePolicy && epaymentPreferences.bankMandatePolicy !== "none"
              const creditCardsEnabled = epaymentPreferences.creditCardPolicy && epaymentPreferences.creditCardPolicy !== "none"

              if(mandateEnabled && !creditCardsEnabled){
                  this.allowedPaymentMethods = "only_mandate"
              }
              else if(creditCardsEnabled && !mandateEnabled){
                  this.allowedPaymentMethods = "only_credit_card"
              }
              else if(creditCardsEnabled && mandateEnabled){
                  this.allowedPaymentMethods = "both"
              }else{
                  this.allowedPaymentMethods = "none"
              }
          }
        }


        if(epaymentPreferences.creditCardFeesType === 'tenant'){
            this.creditCardFeesType = 'tenant';
        }
    }

    extractCardType(cardNumber: string): MangoPayCardType | undefined {

        if(!cardNumber){
            return undefined
        }

        const niceType: CreditCardTypeCardBrandNiceType = creditCardType(cardNumber)[0].niceType as CreditCardTypeCardBrandNiceType

        switch (niceType) {
            case "Visa":
            case "Mastercard":
                return "CB_VISA_MASTERCARD"
            case "Diners Club":
                return "DINERS"
            case "Maestro":
                return "MAESTRO"
            default:
                return undefined
        }
    }

    getPaymentBlockedTooltip(): string {

        if(!this.paymentField.valid){
            return this.translateService.instant('toasts.toast_error_add_amount_desc');
        }

        if(+this.paymentField.value < 1){
            return this.translateService.instant('toasts.toast_error_low_amount_desc');
        }

        if(+this.paymentField.value > this.amountLeftToPay){
            return this.translateService.instant('toasts.toast_error_high_amount_desc');
        }

        if(this.blockedByNonProcessedPaymentIntent){
            return this.translateService.instant('blocked_by_non_processed_payment_intent_tooltip');
        }

        if(this.selectedPaymentMethod === PaymentMethods.Mandate && this.activeLinkedRecurring) {
            return this.translateService.instant('blocked_by_recurring_payment_tooltip');
        }

        return "";
    }

    getPaymentIntentBlockedTooltip(): string {
        return this.translateService.instant('blocked_by_non_processed_payment_intent_tooltip');
    }

    getMethodDisabledTooltip(method: string): string {
        if (method === 'mandate') {
            return this.translateService.instant('payment_method_mandate_disabled_tooltip');
        }
        return this.translateService.instant('payment_method_disabled_tooltip');
    }
}
