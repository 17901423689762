<div style="width: 100%">
    <mat-card class="no-padding" *ngIf="!mobile && files.length > 0">
		<table mat-table [dataSource]="files" style="z-index: 10">
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef>{{ 'com_document_name' | translate }}</th>
				<td mat-cell *matCellDef="let f" style="white-space: nowrap">
					<a *ngIf="f.publicUrl && allowLink; else noLink1" [href]="f.publicUrl" target="_blank" class="light-15">{{ f.name }}</a>
                    <ng-template #noLink1>
                        <a class="light-15">{{ f.name }}</a>
                    </ng-template>
				</td>
			</ng-container>
            <ng-container matColumnDef="creation_time">
				<th mat-header-cell *matHeaderCellDef>{{ 'com_uploaded_on' | translate }}</th>
				<td mat-cell *matCellDef="let f" style="white-space: nowrap">{{ f.creationTime | dfnsFormat: 'dd/MM/yyyy' }}</td>
			</ng-container>
            <ng-container matColumnDef="action_remove">
				<th mat-header-cell *matHeaderCellDef>{{ 'com_action' | translate }}</th>
				<td mat-cell *matCellDef="let f; let indx = index" style="white-space: nowrap">
                    <img class="img-action" src="../../assets/img/rm_icon_delete.svg" (click)="deleteFile(indx)" />
                </td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"
			></tr>
		</table>
	</mat-card>

    <div class="no-padding" *ngIf="mobile && files.length > 0">
        <div *ngFor="let f of files; let indx = index" class="file-container" fxLayout="row" fxLayoutAlign="space-between center" (click)="openFile(f.publicUrl ?? '')">
            <div fxLayout="row" fxLayoutAlign="flex-start center" style="overflow: hidden;">
                <img [src]="getFileImg(f.name)" class="file-img" />
                <p class="regular-12 no-margin one-line-text">{{ f.name }}</p>    
            </div>
            <img *ngIf="allowEdit" class="img-action" src="../../assets/img/rm_icon_delete.svg" (click)="deleteFile(indx)" />
        </div>
    </div>
</div>
